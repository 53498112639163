import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { createEra, getEraByID, editEra } from '../../store/actions/era/era.action';
import CreateEra from '../../components/Eras/CreateEra';

import PageLoader from '../../components/Common/PageLoader';

class CreateErasContainer extends Component {
  state = {
    mode: 'CREATE',
    eraToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    this.setState({
      mode: params && params.id ? 'EDIT' : 'CREATE',
    });
    if (params.id) {
      this.getEra(params.id);
    }
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'eras')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  getEra = async eraID => {
    try {
      const eraToBeEdit = await this.props.getEraByID(eraID);
      this.setState(prevState => {
        return {
          ...prevState,
          eraToBeEdit,
        };
      });
    } catch (error) {
      history.push('/eras');
    }
  };

  render() {
    if (this.state.mode === 'EDIT' && !this.state.eraToBeEdit) {
      return <PageLoader />;
    }

    const { accessDenied, accessMode } = this.state;
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const { createEra, editEra } = this.props;
    return (
      <section>
        <CreateEra
          accessMode={accessMode}
          onCreate={createEra}
          onEdit={editEra}
          initialValues={this.state.eraToBeEdit}
          mode={this.state.mode}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.era.isCreating,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  createEra,
  getEraByID,
  editEra,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateErasContainer);
