import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { addSubscriptionFeature } from '../../store/actions/subscription/subscription.action';

export default class SinglePieceRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      piece: null,
    };
  }

  componentDidMount() {
    this.setState((prev) => ({
      ...prev,
      piece: JSON.parse(JSON.stringify(this.props.piece)),
    }));
  }

  componentDidUpdate(prev) {
    if (JSON.stringify(prev.piece) !== JSON.stringify(this.props.piece)) {
      this.setState((prev) => ({
        ...prev,
        piece: { ...this.props.piece },
      }));
    }
  }

  getPieceByID = () => {
    this.props.methods.getPieceByID(this.state.piece.id).then((piece) => {
      this.setState((prev) => ({
        ...prev,
        piece,
      }));
    });
  };

  setSubscription = (subscription_id, name) => {
    const { addPieceSubscription } = this.props.pieceSubscriptionMethods;
    addPieceSubscription({ subscription_id, piece_id: this.state.piece.id, name }, this.props.piece.subscriptions);
  };

  deleteSubscription = (subscription_id, name) => {
    const { getPieceSubscriptionByID, deletePieceSubscription } = this.props.pieceSubscriptionMethods;
    getPieceSubscriptionByID(this.state.piece.id).then((piece_subscriptions) => {
      let { id } = _.find(piece_subscriptions, { piece_id: this.state.piece.id, subscription_id });
      deletePieceSubscription({ id, subscription_id, name }, this.props.piece.subscriptions);
    });
  };

  render() {
    const { piece, page, ind, subscriptions, mode, pieceSubscriptionMethods } = this.props;
    const { deletePiece, updatePiece } = this.props.methods;
    const desc = piece.description ? piece.description.replace(/<[^>]*>/g, ''):'';

    return (
      this.state.piece && (
        <tr key={this.state.piece.id}>
          <td>{(page.current - 1) * page.size + (ind + 1)}</td>
          <td>{this.state.piece.title}</td>
          <td>{desc.length > 100 ? desc.substr(0, 100) + '...' : desc}</td>
          <td>{this.state.piece.pieceproductid_google}</td>
          <td>{this.state.piece.composer.name}</td>
          <td>{this.state.piece.artist.title}</td>
          <td>
            {subscriptions.map((s) => (
              <div className="subs-check" key={piece.id + s.id}>
                <input
                  type="checkbox"
                  defaultChecked={_.find(piece.subscriptions, { id: s.id }) ? true : false}
                  onChange={(e) => {
                    e.target.checked ? this.setSubscription(s.id, s.name) : this.deleteSubscription(s.id, s.name);
                  }}
                />
                <div>{s.name}</div>
              </div>
            ))}
          </td>
          <td>{this.state.piece.price}</td>
          <td>
            <input
              onChange={(e) => updatePiece(e, { latest: e.target.checked, id: piece.id }, piece)}
              defaultChecked={piece.latest}
              type="checkbox"
            />
          </td>
          <td>
            <input
              onChange={(e) => updatePiece(e, { featured: e.target.checked, id: piece.id }, piece)}
              defaultChecked={piece.featured}
              type="checkbox"
            />
          </td>
          <td style={{ textAlign: 'center' }}>
            {mode == 'edit' ? (
              <React.Fragment>
                <Link className="cus-btn-primary" to={`/piece/edit/${piece.id}`}>
                  Edit
                </Link>
                <button className="cus-btn-danger mr-15" onClick={() => deletePiece(piece)}>
                  Delete
                </button>
              </React.Fragment>
            ) : (
              <Link className="cus-btn-primary" to={`/piece/view/${piece.id}`}>
                View
              </Link>
            )}
          </td>
        </tr>
      )
    );
  }
}
