import {
  GET_COUPONS_START,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_ERROR,
  CREATE_COUPON_START,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_ERROR,
  DELETE_COUPON_START,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_ERROR,
  EDIT_COUPON_START,
  EDIT_COUPON_SUCCESS,
  EDIT_COUPON_ERROR,
} from '../actions/coupon/coupon.actiontype';

export default function(state = { list: [] }, action) {
  switch (action.type) {
    case GET_COUPONS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_COUPONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_COUPONS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_COUPON_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_COUPON_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: [...state.list, action.payload],
      };
    case CREATE_COUPON_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case EDIT_COUPON_START:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_COUPON_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: state.list.map(coupon => {
          if (coupon.id === action.payload.id) {
            return action.payload;
          }
          return coupon;
        }),
      };
    case EDIT_COUPON_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_COUPON_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_COUPON_SUCCESS:
      const newList = state.list.filter(coupon => coupon.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
        list: newList,
      };
    case DELETE_COUPON_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
}
