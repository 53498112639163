import React from 'react';
import DatePicker from 'react-date-picker';

export default function DateRangeFilter(props) {
  const { startDate, setStartDate } = props.data;
  return (
    <div className="date-range-filter">
      <label>Start Date :</label>&nbsp;
      <DatePicker value={startDate} onChange={setStartDate} />
    </div>
  );
}
