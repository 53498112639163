import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { required } from '../../utils/validation.util';
import { renderInput, renderBSelect } from '../../utils/input.util';

const couponTypes = [
  {
    displayName: 'Percent',
    value: 'percentage',
  },
];

const validType = [
  {
    displayName: 'Valid',
    value: true,
  },
  {
    displayName: 'Invalid',
    value: false,
  },
];

class CreateCoupon extends Component {
  state = {};

  componentDidMount() {
    if (this.props.mode === 'EDIT') {
      this.setState({
        ...this.props.initialValues,
      });
    }
  }

  onChangeSetToState = stateKey => e => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = formValues => {
    formValues.is_valid = Boolean(formValues.is_valid);
    if (this.props.mode === 'EDIT') {
      this.props.onEdit(formValues);
      return;
    }

    this.props.onCreate(formValues);
  };

  render() {
    const { mode, isCreating, isLoading, accessMode } = this.props;
    return (
      <section className="well create-piece">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-md-3">
              <label>Code</label>
            </div>
            <div className="col-md-9">
              <Field
                name="code"
                placeholder="Code"
                component={renderInput}
                type="text"
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
              />
            </div>
            <div className="col-md-3">
              <label>Type</label>
            </div>
            <div className="col-md-9">
              <Field
                name="type"
                placeholder="Type"
                component={renderBSelect}
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
                options={couponTypes}
              />
            </div>
            <div className="col-md-3">
              <label>Amount</label>
            </div>
            <div className="col-md-9">
              <Field
                name="amount"
                placeholder="Amount"
                component={renderInput}
                type="text"
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
              />
            </div>
            <div className="col-md-3">
              <label>Active</label>
            </div>
            <div className="col-md-9">
              <Field
                name="is_valid"
                placeholder="Active"
                component={renderBSelect}
                type="text"
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
                options={validType}
              />
            </div>

            {accessMode == 'edit' && (
              <div className="create-piece__btn-container">
                <button className="cus-btn-primary btn-block btn-lg" type="submit" disabled={isCreating}>
                  {isCreating ? <span className="spinner" /> : <span>{mode === 'EDIT' ? 'Edit' : 'Create'}</span>}
                </button>
              </div>
            )}
          </div>
        </form>
      </section>
    );
  }
}

export default reduxForm({ form: 'createCoupon' })(CreateCoupon);
