import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_MEMBERS_START,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_ERROR,
  CREATE_MEMBER_START,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_ERROR,
  DELETE_MEMBER_START,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_ERROR,
  EDIT_MEMBER_START,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_ERROR,
} from './member.actiontype';

import { getError } from '../../../utils/common.util';

export const getMembers = () => async (dispatch) => {
  try {
    dispatch({ type: GET_MEMBERS_START });
    const members = await axiosService.get('/artist-members?per_page=1000');
    dispatch({ type: GET_MEMBERS_SUCCESS, payload: members });
    // return Promise.resolve(members);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_MEMBERS_ERROR });
  }
};

export const createMember = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_MEMBER_START });
    const member = await axiosService.post('/artist-members', fields);
    toast.success('Successfully created.');
    dispatch({ type: CREATE_MEMBER_SUCCESS, payload: member });
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_MEMBER_ERROR });
  }
};

export const deleteMember = (member) => async (dispatch) => {
  if (!window.confirm('Are you sure to delete this member ?')) return;
  try {
    dispatch({ type: DELETE_MEMBER_START });
    const deletedMember = await axiosService.delete(`/artist-members/${member.id}`);
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_MEMBER_SUCCESS, payload: deletedMember });
  } catch (error) {
    if (error.code == 500) toast.error('Member already attached to an artist. Deletion restricted.');
    dispatch({ type: DELETE_MEMBER_ERROR });
  }
};

export const editMember = (member) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_MEMBER_START });
    const memberInfo = await axiosService.put(`/artist-members/${member.id}`, member);
    toast.success('Successfully updated.');
    dispatch({ type: EDIT_MEMBER_SUCCESS, payload: memberInfo });
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_MEMBER_ERROR });
  }
};

export const getMemberByID = (memberId) => async (dispatch) => {
  try {
    const member = await axiosService.get(`/artist-members/${memberId}`);
    return Promise.resolve(member);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};
