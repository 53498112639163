import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Subscriptions from './Subscriptions';

const Subscription = (props) => {
  const { deleteSubscription, subscription } = props.subscriptionInfo;
  const { mode } = props;
  return (
    <tr>
      <td>{subscription.name}</td>
      <td>{subscription.price}</td>
      <td>{subscription.months}</td>
      <td>
        <img src={subscription.icon} style={{ width: '50px', height: '50px' }} alt="no icon" />
      </td>
      <td>
        {subscription.includes.map((include, i) => (
          <div key={i} style={{ display: 'block' }}>
            {i + 1}.{include}
          </div>
        ))}
      </td>
      <td>
        {subscription.features &&
          subscription.features.map(({ feature }, i) => (
            <div key={i} style={{ display: 'block' }}>
              {i + 1}.{feature.name}
            </div>
          ))}
      </td>

      <td>{moment(subscription.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        {mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteSubscription(subscription)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/subscription/edit/${subscription.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/subscription/view/${subscription.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Subscription;
