import { GoogleSpreadsheet } from 'google-spreadsheet';
import sheets_creds from '../../assets/data/sheets-credentials.json';

const dataToInsert = (data) => {
  // Timestamp	Username	Email	Action	Category	Sub Category	Link	Details
  const reg = new RegExp('\n', 'g');
  const rows = ['Timestamp', 'Username', 'Email', 'Action', 'Category', 'Sub Category', 'Link', 'Details'];
  const payload = rows.map((r) => `<td>${data[r].replace(reg, '<br>')}</td>`).join('');
  return `<table><tr>${payload}</tr></table>`;
};

const doc = new GoogleSpreadsheet(process.env.REACT_APP_GSHEET_ID);

const content = {
  authenticateGUser: async () => {
    try{
      await doc.useServiceAccountAuth(sheets_creds);
    }catch(e){
      console.log(e);
    }
  },
  pieces: {
    Timestamp: new Date().toUTCString(),
    Username: '',
    Email: '',
    Action: '',
    Category: 'Pieces',
    'Sub Category': '',
    Details: '',
    Link: '',
  },

  text: {
    prev: (t) => `<div style="color: #38761d;">${t}</div>`,
    new: (t) => `<div style="color: #cc0000;">${t}</div>`,
  },

  insert: (data) => {
    try {
      return doc._makeBatchUpdateRequest([
        {
          insertRange: {
            range: {
              sheetId: 0,
              startRowIndex: 1,
              endRowIndex: 2,
            },
            shiftDimension: 'ROWS',
          },
        },
        {
          pasteData: {
            html: true,
            data: dataToInsert(data),
            coordinate: {
              sheetId: 0,
              rowIndex: 1,
            },
          },
        },
      ]);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  },
};

export default content;
