import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  deletePieceInstrument,
  getPiecesInstrument,
} from '../../store/actions/piece-instrument/piece-instrument.action';
import PiecesInstrumentList from '../../components/PiecesInstrument/PiecesInstrument';
import PageLoader from '../../components/Common/PageLoader';

class PiecesInstrumentContainer extends Component {
  componentDidMount() {
    this.props.getPiecesInstrument();
  }

  render() {
    const { deletePieceInstrument, piecesInstrument, isFetching } = this.props;
    if (isFetching) {
      return <PageLoader />;
    }
    const pieceInstrumentInfo = { deletePieceInstrument, piecesInstrument };
    return (
      <section>
        <PiecesInstrumentList pieceInstrumentInfo={pieceInstrumentInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    piecesInstrument: state.pieceInstrument.list,
    isFetching: state.pieceInstrument.isFetching,
  };
};
const mapDispatchToProps = {
  deletePieceInstrument,
  getPiecesInstrument,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PiecesInstrumentContainer);
