import {
  GET_SUBSCRIPTIONS_START,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_ERROR,
  CREATE_SUBSCRIPTION_START,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_ERROR,
  DELETE_SUBSCRIPTION_START,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_ERROR,
  EDIT_SUBSCRIPTION_START,
  EDIT_SUBSCRIPTION_SUCCESS,
  EDIT_SUBSCRIPTION_ERROR,
  SET_CURRENT_SUBSCRIPTION,
  DESTROY_CURRENT_SUBSCRIPTION,
} from '../actions/subscription/subscription.actiontype';

export default function(state = { list: [], currentSubscription: null }, action) {
  switch (action.type) {
    case GET_SUBSCRIPTIONS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_SUBSCRIPTION_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: [...state.list, action.payload],
      };
    case CREATE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_SUBSCRIPTION_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_SUBSCRIPTION_SUCCESS:
      const newList = state.list.filter(subscription => subscription.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
        list: newList,
      };
    case DELETE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    case EDIT_SUBSCRIPTION_START:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: state.list.map(subscription => {
          if (subscription.id === action.payload.id) {
            return action.payload;
          }
          return subscription;
        }),
      };
    case EDIT_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case SET_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload,
      };
    case DESTROY_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        currentSubscription: action.payload,
      };
    default:
      return state;
  }
}
