import React from 'react';
import { Link } from 'react-router-dom';

import Difficulty from './Difficulty';

const ListDifficulties = props => {
  const { difficulties, deleteDifficulty } = props.difficultyInfo;

  const getDifficulty = () => {
    return difficulties.map(difficulty => {
      const difficultyInfo = { deleteDifficulty, difficulty };
      return <Difficulty mode={props.mode} difficultyInfo={difficultyInfo} key={difficulty.id} />;
    });
  };

  const { mode } = props;

  return (
    <div className="box">
      <div className="box-header with-border">
        <h3 className="box-title">Difficulties</h3>
        {mode == 'edit' && (
          <Link className="cus-btn-primary pull-right" to="/difficulty/create">
            Add Difficulty
          </Link>
        )}
      </div>
      <div className="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{getDifficulty()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListDifficulties;
