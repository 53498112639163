import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { email, required } from '../../utils/validation.util';
import { renderInput } from '../../utils/input.util';
import logo from '../../assets/images/logo.png';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };
  }

  componentDidMount() {
    const contentWrapper = document.getElementById('contentWrapper');
    contentWrapper.style['margin-left'] = 0;
  }

  componentWillUnmount() {
    const contentWrapper = document.getElementById('contentWrapper');
    contentWrapper.style['margin-left'] = '230px';
  }

  onChangeSetToState = stateKey => e => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = () => {
    this.props.onSubmit(this.state);
  };

  render() {
    return (
      <div className="login-inner">
        <img src={logo} alt="partplay" />
        <div className="login-box-body">
          <p>Sign in to start your session</p>
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <Field
              name="email"
              placeholder="Email"
              component={renderInput}
              type="email"
              value={this.state.email}
              onChange={this.onChangeSetToState('email')}
              validate={[required, email]}
              className="form-control"
              parentClass="form-group"
            />
            <Field
              name="password"
              placeholder="Password"
              component={renderInput}
              type="password"
              value={this.state.password}
              onChange={this.onChangeSetToState('password')}
              validate={required}
              className="form-control"
              parentClass="form-group"
            />
            <div>
              <button className="btn btn-primary btn-block btn-flat" type="submit" disabled={this.props.isLoading}>
                {this.props.isLoading ? <span className="spinner" /> : <span>Login</span>}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: 'loginForm' })(Login);
