export const GET_MEMBERS_START = 'GET_MEMBERS_START';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_ERROR = 'GET_MEMBERS_ERROR';

export const CREATE_MEMBER_START = 'CREATE_MEMBER_START';
export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_ERROR = 'CREATE_MEMBER_ERROR';

export const DELETE_MEMBER_START = 'DELETE_MEMBER_START';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_ERROR = 'DELETE_MEMBER_ERROR';

export const EDIT_MEMBER_START = 'EDIT_MEMBER_START';
export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS';
export const EDIT_MEMBER_ERROR = 'EDIT_MEMBER_ERROR';
