import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

import { renderInput } from '../../utils/input.util';

class CreateInstrument extends Component {
  state = {
    instrument_id: '',
    audio_file: '',
    audio: '',
    order: '',
    file: {},
    awsKeys: {},
    printedMusicAwsKeys: {},
    disableUpload: false,
    instrument: null,
    audioUploaded: false,
    isUploading: false,
    EditInstrument: true,
    printedUpload: false,
    printedInputOn: false,
    mediaInputOn: false,
    printedInputEditOn: false,
    mediaInputEditOn: false,
    InstrumentToEdit: null,
  };

  onChangeSetToState = (stateKey) => (e) => {
    let updates = {
      [stateKey]: e.currentTarget.value.trim(),
    };
    if (['audio_file', 'printed'].includes(stateKey)) {
      updates.disableUpload = true;
    }
    this.setState((prev) => ({ ...prev, ...updates }));
  };

  onSubmit = async () => {
    const { audio_file, title, instrument, printed, order } = this.state;
    const piece_id = this.props.pieceInstrumentInfo.piece.id;
    const instrument_id = instrument[0] && instrument[0].id;

    const instrumentName = instrument[0] && instrument[0].name;

    await this.props.onCreate({
      piece_id,
      instrument_id,
      instrumentName,
      audio_file,
      title,
      printed,
      order: Number(order),
    });
    this.props.pieceInstrumentInfo.getCurrentPiece('edit', piece_id);
    this.setState((prevState) => ({
      ...prevState,
      instrument_id: '',
      audio_file: '',
      audio: '',
      file: {},
      awsKeys: {},
      printedMusicAwsKeys: {},
      disableUpload: false,
      instrument: null,
      audioUploaded: false,
      isUploading: false,
      title: '',
      order: '',
      printed: '',
      printedInputOn: false,
      mediaInputOn: false,
      printedInputEditOn: false,
      mediaInputEditOn: false,
      InstrumentToEdit: null,
    }));
    this.props.change('title', '');
    try {
      document.getElementById('uploadPieceInstrument').value = null;
    } catch (e) {}
    try {
      document.getElementById('uploadPieceInstrumentPrinted').value = null;
    } catch (e) {}
    this.typeahead.getInstance().clear();
  };

  filterObjectName = (collection, id, field) => {
    return collection.filter((data) => data.id == id && data)[0][field];
  };

  editInstrument = async () => {
    const { audio_file, title, instrument, instrument_id, id, order, printed } = this.state.InstrumentToEdit;
    const piece_id = this.props.pieceInstrumentInfo.piece.id;

    const instrumentName = this.filterObjectName(this.props.pieceInstrumentInfo.instruments, instrument_id, 'name'),
      instrumentNamePrev = this.filterObjectName(
        this.props.pieceInstrumentInfo.instruments,
        this.props.pieceInstrumentInfo.piece.instruments[this.state.showFollowing].instrument_id,
        'name'
      );

    await this.props.onEdit(
      {
        audio_file,
        title,
        instrument,
        instrument_id,
        id,
        order: Number(order),
        printed,
        instrumentName,
        instrumentNamePrev,
      },
      this.props.pieceInstrumentInfo.piece.instruments[this.state.showFollowing]
    );

    this.props.pieceInstrumentInfo.getCurrentPiece('edit', piece_id);
    this.setState((prevState) => ({
      ...prevState,
      instrument_id: '',
      audio_file: '',
      audio: '',
      file: {},
      awsKeys: {},
      printedMusicAwsKeys: {},
      disableUpload: false,
      instrument: null,
      audioUploaded: false,
      isUploading: false,
      title: '',
      order: '',
      showFollowing: false,
      printed: '',
      printedInputOn: false,
      mediaInputOn: false,
      printedInputEditOn: false,
      mediaInputEditOn: false,
      InstrumentToEdit: null,
    }));
    this.props.change('title', '');
    this.typeahead.getInstance().clear();
  };
  onFileChange = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      audioUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.pieceInstrumentInfo.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `pieces/instruments/audios/${fileName}` });
    this.setState((prevState) => ({
      ...prevState,
      file,
      awsKeys,
      disableUpload: true,
    }));
    if (this.state.InstrumentToEdit) {
      var AudioFile = this.state.InstrumentToEdit;
      AudioFile['audio_file'] = `${awsKeys.action}${awsKeys.key}`;
      this.setState({
        InstrumentToEdit: AudioFile,
      });
    } else {
      this.setState((prev) => ({ ...prev, audio_file: `${awsKeys.action}${awsKeys.key}` }));
    }
  };
  onFileChangePrinted = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      printedUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.pieceInstrumentInfo.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const printedMusicAwsKeys = await getAwsKey({ key: `pieces/instruments/printed/${fileName}` });
    this.setState((prevState) => ({
      ...prevState,
      file,
      printedMusicAwsKeys,
      disableUpload: true,
    }));
    if (this.state.InstrumentToEdit) {
      var PrintedFile = this.state.InstrumentToEdit;
      PrintedFile['printed'] = `${printedMusicAwsKeys.action}${printedMusicAwsKeys.key}`;
      this.setState({
        InstrumentToEdit: PrintedFile,
      });
    } else {
      this.setState((prev) => ({ ...prev, printed: `${printedMusicAwsKeys.action}${printedMusicAwsKeys.key}` }));
    }
  };

  handleEditStateChange = (key, value) => {
    let { InstrumentToEdit } = this.state;
    var instrumentX = InstrumentToEdit;
    instrumentX[key] = value;
    this.setState({
      InstrumentToEdit: instrumentX,
    });
  };

  uploadAudio = (label) => async (eve) => {
    eve.preventDefault();
    if (
      label !== 'editInstrument' &&
      ((this.state.printedInputOn && !this.state.printedMusicAwsKeys.action) ||
        (this.state.mediaInputOn && !this.state.awsKeys.action))
    ) {
      toast.error('please try again.');
    }
    if (label === 'editInstrument') {
      if (Object.keys(this.state.awsKeys).length) {
        var form = document.getElementById('uploadAudioEdit');
        var data = new FormData(form);
      }

      if (Object.keys(this.state.printedMusicAwsKeys).length) {
        var form2 = document.getElementById('uploadFileEdit');
        var data2 = new FormData(form2);
      }
    } else {
      if (Object.keys(this.state.printedMusicAwsKeys).length) {
        var form2 = document.getElementById('uploadFile');
        var data2 = new FormData(form2);
      }

      if (Object.keys(this.state.awsKeys).length) {
        var form = document.getElementById('uploadAudio');
        var data = new FormData(form);
      }
    }
    try {
      console.log('check aud');
      let checkAud = false, checkPrintedAud = false;
      this.setState((prevState) => ({
        ...prevState,
        isUploading: true,
      }));
      if (Object.keys(this.state.awsKeys).length && this.state.awsKeys.action) {
        await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
        checkAud = true;
      }
      if (Object.keys(this.state.printedMusicAwsKeys).length && this.state.printedMusicAwsKeys.action) {
        await fetch(this.state.printedMusicAwsKeys.action, { method: 'POST', body: data2 });
        checkPrintedAud = true;
      }

      if (checkAud) {
        toast.success('Audio uploaded successfully.');
      }

      if (checkPrintedAud) {
        toast.success('Printed audio uploaded successfully.');
      }

      this.setState((prevState) => ({
        ...prevState,
        audioUploaded: true,
        fileUpload: true,
      }));
      if (label === 'editInstrument') {
        this.editInstrument();
      } else {
        this.onSubmit();
      }
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  onDelete = async (instrument) => {
    await this.props.onDelete(instrument);
    const piece_id = this.props.pieceInstrumentInfo.piece.id;
    this.props.pieceInstrumentInfo.getCurrentPiece('edit', piece_id);
  };
  Show = (i) => {
    const InstrumentToEditArray = this.props.pieceInstrumentInfo.piece.instruments.filter((instrument, id) => {
      if (id == i) {
        return instrument;
      }
    });
    const InstrumentToEditObject = InstrumentToEditArray[0];
    this.setState({
      showFollowing: i,
      InstrumentToEdit: JSON.parse(JSON.stringify(InstrumentToEditObject)),
    });
  };
  getPieceInstrument = () => {
    const { isCreating, pieceInstrumentInfo, accessMode } = this.props;
    const { awsKeys, disableUpload, printedMusicAwsKeys, mediaInputEditOn, printedInputEditOn } = this.state;
    return (
      this.props.pieceInstrumentInfo.piece &&
      this.props.pieceInstrumentInfo.piece.instruments &&
      this.props.pieceInstrumentInfo.piece.instruments.map((instrument, i) => {
        return (
          <div className="instrument-list" key={instrument.id}>
            {this.props.accessMode == 'edit' && (
              <div className="row">
                <span
                  style={{ display: 'inline-block', float: 'right', color: 'red' }}
                  className="glyphicon glyphicon-remove-circle"
                  aria-hidden="true"
                  onClick={() => this.onDelete(instrument)}
                />
                <span
                  style={{ display: 'inline-block', float: 'right', marginRight: '10px', color: 'green' }}
                  className="glyphicon glyphicon-edit"
                  aria-hidden="true"
                  onClick={() => this.Show(i)}
                />
              </div>
            )}
            {this.state.showFollowing !== i && (
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Title</label>
                  </div>
                  <div className="col-md-9">{instrument.title}</div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Order</label>
                  </div>
                  <div className="col-md-9">{instrument.order}</div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Audio File</label>
                  </div>
                  <div className="col-md-9">{instrument.audio_file}</div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Printed Music</label>
                  </div>
                  <div className="col-md-9">{instrument.printed}</div>
                </div>
              </div>
            )}
            {this.state.showFollowing === i && (
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Instrument</label>
                  </div>
                  <div className="col-md-9">
                    <Typeahead
                      onChange={(selected) => {
                        if (selected.length !== 0) {
                          var instrumentProperty = this.state.InstrumentToEdit;
                          instrumentProperty.instrument = selected[0];
                          instrumentProperty.instrument_id = selected[0].id;
                          this.setState({ InstrumentToEdit: instrumentProperty });
                        }
                      }}
                      labelKey="name"
                      options={pieceInstrumentInfo.instruments}
                      selected={[this.state.InstrumentToEdit.instrument]}
                      className="form-group"
                      placeholder="Instruments"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Title</label>
                  </div>
                  <div className="col-md-9 form-group">
                    <input
                      placeholder="Instrument Title"
                      type="text"
                      value={this.state.InstrumentToEdit.title}
                      onChange={(e) => {
                        var instrumentX = this.state.InstrumentToEdit;
                        instrumentX['title'] = e.target.value;
                        this.setState({
                          InstrumentToEdit: instrumentX,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Order</label>
                  </div>
                  <div className="col-md-9 form-group">
                    <input
                      placeholder="Instrument Title"
                      type="number"
                      value={this.state.InstrumentToEdit.order}
                      onChange={(e) => {
                        var instrumentOrder = this.state.InstrumentToEdit;
                        instrumentOrder['order'] = e.target.value;
                        this.setState({
                          InstrumentToEdit: instrumentOrder,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row" style={{ height: '10px' }} />
                <div className="row">
                  <div className="col-md-3">
                    <label>Audio File</label>
                  </div>
                  <div className="col-md-9 radio-part">
                    <div className="currentFile">{this.state.InstrumentToEdit.audio_file}</div>
                    <div className="radio-selection row">
                      <div className="col-md-4">
                        <input
                          type="radio"
                          id="linkTypeMediaEdit"
                          name="mediaTypeEdit"
                          checked={!mediaInputEditOn}
                          onChange={this.handleStateChange('mediaInputEditOn', false)}
                        />
                        &nbsp;
                        <label htmlFor="linkTypeMediaEdit">Link</label>
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          id="uploadTypeMediaEdit"
                          name="mediaTypeEdit"
                          checked={mediaInputEditOn}
                          onChange={this.handleStateChange('mediaInputEditOn', true)}
                        />
                        &nbsp;
                        <label htmlFor="uploadTypeMediaEdit">Upload</label>
                      </div>
                    </div>

                    {mediaInputEditOn ? (
                      <form id="uploadAudioEdit" method="post" encType="multipart/form-data">
                        <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                        <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                        <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                        <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                        <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                        <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                        <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                        <input id="uploadPieceInstrumentEdit" type="file" name="file" onChange={this.onFileChange} />
                      </form>
                    ) : (
                      <input
                        name="printed"
                        placeholder="Printed"
                        value={this.state.InstrumentToEdit.audio_file}
                        onChange={(e) => this.handleEditStateChange('audio_file', e.target.value)}
                        className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                        parentClass="form-group"
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3">
                    <label>Printed Music</label>
                  </div>
                  <div className="col-md-9  radio-part">
                    <div className="currentFile">{this.state.InstrumentToEdit.printed}</div>
                    <div className="radio-selection row">
                      <div className="col-md-4">
                        <input
                          type="radio"
                          id="linkTypePrintedEdit"
                          name="printedTypeEdit"
                          checked={!printedInputEditOn}
                          onChange={this.handleStateChange('printedInputEditOn', false)}
                        />
                        &nbsp;
                        <label htmlFor="linkTypePrintedEdit">Link</label>
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          id="uploadTypePrintedEdit"
                          name="printedTypeEdit"
                          checked={printedInputEditOn}
                          onChange={this.handleStateChange('printedInputEditOn', true)}
                        />
                        &nbsp;
                        <label htmlFor="uploadTypePrintedEdit">Upload</label>
                      </div>
                    </div>

                    {printedInputEditOn ? (
                      <form id="uploadFileEdit" method="post" encType="multipart/form-data">
                        <input type="hidden" name="key" value={printedMusicAwsKeys.key || 'NA'} />
                        <input type="hidden" name="acl" value={printedMusicAwsKeys.acl || 'NA'} />
                        <input
                          type="hidden"
                          name="X-Amz-Credential"
                          value={printedMusicAwsKeys['x-amz-credential'] || 'NA'}
                        />
                        <input
                          type="hidden"
                          name="X-Amz-Algorithm"
                          value={printedMusicAwsKeys['x-amz-algorithm'] || 'NA'}
                        />
                        <input type="hidden" name="X-Amz-Date" value={printedMusicAwsKeys['x-amz-date'] || 'NA'} />
                        <input type="hidden" name="Policy" value={printedMusicAwsKeys.policy || 'NA'} />
                        <input
                          type="hidden"
                          name="X-Amz-Signature"
                          value={printedMusicAwsKeys['x-amz-signature'] || 'NA'}
                        />
                        <input
                          id="uploadPieceInstrumentPrinted"
                          type="file"
                          name="file"
                          onChange={this.onFileChangePrinted}
                        />
                      </form>
                    ) : (
                      <input
                        name="printed"
                        placeholder="Printed"
                        value={this.state.InstrumentToEdit.printed}
                        onChange={(e) => this.handleEditStateChange('printed', e.target.value)}
                        className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                        parentClass="form-group"
                      />
                    )}
                  </div>
                </div>

                <div className="editButtons">
                  <button
                    type="submit"
                    name="submit"
                    className="cus-btn-primary btn-sm submitButton"
                    style={{ margin: '10px 10px 10px 10px' }}
                    onClick={this.uploadAudio('editInstrument')}
                  >
                    {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Save</span>}
                  </button>
                  <button
                    type="button"
                    name="button"
                    className="cus-btn-danger btn-sm submitButton"
                    style={{ margin: '10px 10px 10px 10px' }}
                    onClick={() => {
                      this.setState((prev) => ({
                        ...prev,
                        showFollowing: false,
                        InstrumentToEdit: null,
                      }));
                    }}
                  >
                    {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Cancel</span>}
                  </button>
                </div>
              </div>
            )}
            <hr />
          </div>
        );
      })
    );
  };

  handleStateChange = (key, val) => (e) => {
    e.stopPropagation();
    let updates = { [key]: val };
    if (['printedInputEditOn', 'printedInputOn'].includes(key)) {
      updates['printed'] = '';
      updates['printedMusicAwsKeys'] = {};
    } else if (['mediaInputEditOn', 'mediaInputOn'].includes(key)) {
      updates['audio_file'] = '';
      updates['awsKeys'] = {};
    }

    this.setState((prev) => ({ ...prev, ...updates }));
  };

  render() {
    const { isCreating, pieceInstrumentInfo, accessMode } = this.props;
    const { awsKeys, disableUpload, printedMusicAwsKeys, mediaInputOn, printedInputOn } = this.state;
    return (
      <section className="well create-instrument">
        {this.getPieceInstrument()}

        {accessMode == 'edit' && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <label>Instrument</label>
              </div>
              <div className="col-md-9">
                <Typeahead
                  onChange={(selected) => {
                    this.setState({
                      instrument: selected,
                    });
                  }}
                  ref={(typeahead) => (this.typeahead = typeahead)}
                  labelKey="name"
                  options={pieceInstrumentInfo.instruments}
                  selected={this.state.instrument}
                  className="form-group"
                  placeholder="Instruments"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Title</label>
              </div>
              <div className="col-md-9">
                <Field
                  name="title"
                  placeholder="Title"
                  component={renderInput}
                  type="text"
                  value={this.state.title}
                  onChange={this.onChangeSetToState('title')}
                  className="form-control"
                  parentClass="form-group"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Order</label>
              </div>
              <div className="col-md-9">
                <input
                  name="order"
                  placeholder="Order"
                  type="number"
                  value={this.state.order}
                  onChange={this.onChangeSetToState('order')}
                  className="form-control"
                  parentClass="form-group"
                />
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-3">
                <label>Audio</label>
              </div>

              <div className="col-md-9 radio-part">
                <div className="radio-selection row">
                  <div className="col-md-4">
                    <input
                      type="radio"
                      id="linkTypeMedia"
                      name="mediaType"
                      checked={!mediaInputOn}
                      onChange={this.handleStateChange('mediaInputOn', false)}
                    />
                    &nbsp;
                    <label htmlFor="linkTypeMedia">Link</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="radio"
                      id="uploadTypeMedia"
                      name="mediaType"
                      checked={mediaInputOn}
                      onChange={this.handleStateChange('mediaInputOn', true)}
                    />
                    &nbsp;
                    <label htmlFor="uploadTypeMedia">Upload</label>
                  </div>
                </div>

                {mediaInputOn ? (
                  <form id="uploadAudio" method="post" encType="multipart/form-data">
                    <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                    <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                    <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                    <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                    <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                    <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                    <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                    <input id="uploadPieceInstrument" type="file" name="file" onChange={this.onFileChange} />
                  </form>
                ) : (
                  <input
                    name="printed"
                    placeholder="Audio"
                    value={this.state.audio_file}
                    onChange={this.onChangeSetToState('audio_file')}
                    className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                    parentClass="form-group"
                  />
                )}
              </div>
            </div>
            <div className="row" style={{ height: '10px' }} />
            <div className="row">
              <div className="col-md-3">
                <label>Printed</label>
              </div>
              <div className="col-md-9 radio-part">
                <div className="radio-selection row">
                  <div className="col-md-4">
                    <input
                      type="radio"
                      id="linkTypePrinted"
                      name="printedType"
                      checked={!printedInputOn}
                      onChange={this.handleStateChange('printedInputOn', false)}
                    />
                    &nbsp;
                    <label htmlFor="linkTypePrinted">Link</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="radio"
                      id="uploadTypePrinted"
                      name="printedType"
                      checked={printedInputOn}
                      onChange={this.handleStateChange('printedInputOn', true)}
                    />
                    &nbsp;
                    <label htmlFor="uploadTypePrinted">Upload</label>
                  </div>
                </div>

                {printedInputOn ? (
                  <form id="uploadFile" method="post" encType="multipart/form-data">
                    <input type="hidden" name="key" value={printedMusicAwsKeys.key || 'NA'} />
                    <input type="hidden" name="acl" value={printedMusicAwsKeys.acl || 'NA'} />
                    <input
                      type="hidden"
                      name="X-Amz-Credential"
                      value={printedMusicAwsKeys['x-amz-credential'] || 'NA'}
                    />
                    <input
                      type="hidden"
                      name="X-Amz-Algorithm"
                      value={printedMusicAwsKeys['x-amz-algorithm'] || 'NA'}
                    />
                    <input type="hidden" name="X-Amz-Date" value={printedMusicAwsKeys['x-amz-date'] || 'NA'} />
                    <input type="hidden" name="Policy" value={printedMusicAwsKeys.policy || 'NA'} />
                    <input
                      type="hidden"
                      name="X-Amz-Signature"
                      value={printedMusicAwsKeys['x-amz-signature'] || 'NA'}
                    />
                    <input
                      id="uploadPieceInstrumentPrinted"
                      type="file"
                      name="file"
                      onChange={this.onFileChangePrinted}
                    />
                  </form>
                ) : (
                  <input
                    name="printed"
                    placeholder="Printed"
                    value={this.state.printed}
                    onChange={this.onChangeSetToState('printed')}
                    className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                    parentClass="form-group"
                  />
                )}
              </div>
            </div>

            <button
              onClick={this.uploadAudio('newInstrument')}
              type="submit"
              name="submit"
              className="cus-btn-primary btn-block btn-lg submitButton"
              disabled={
                isCreating || !(disableUpload && pieceInstrumentInfo.piece && this.state.instrument && this.state.title)
              }
            >
              {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Add Instrument</span>}
            </button>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createInstrument' })(CreateInstrument);
