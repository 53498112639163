import {
  USERS_LIST_START,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  SINGLE_USER_START,
  SINGLE_USER_SUCCESS,
  SINGLE_USER_ERROR,
  SINGLE_USER_CREATE_END,
  SINGLE_USER_CREATE_START,
} from '../actions/users/users.actiontype';

export default function (state = { list: [], singleUser: {}, pagination: { page: 1 }, isCreating: false }, action) {
  switch (action.type) {
    case USERS_LIST_START:
      return {
        ...state,
        isFetching: true,
        singleUser: {},
      };
    case USERS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload.list,
        pagination: action.payload.pagination,
      };
    case USERS_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        list: [],
      };
    case SINGLE_USER_START:
      return {
        ...state,
        isFetching: true,
      };
    case SINGLE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        singleUser: action.payload,
      };
    case SINGLE_USER_ERROR:
      return {
        ...state,
        isFetching: false,
        singleUser: {},
      };

    case SINGLE_USER_CREATE_END:
      return {
        ...state,
        isCreating: false,
      };

    case SINGLE_USER_CREATE_START:
      return {
        ...state,
        isCreating: true,
      };

    default:
      return state;
  }
}
