import React from 'react';
import { Link } from 'react-router-dom';

export default function SingleDetailRow(props) {
  const { piece, ind, page, mode } = props;
  const { deletePiece, updatePiece } = props.methods;

  return (
    piece && piece.interviews && piece.closeups ?
      <tr key={piece.id} className="singleDetailRow">
        <td>{(page.current - 1) * page.size + (ind + 1)}</td>
        <td>{piece.title}</td>

        {/* instruments */}
        <td>
          {
            piece.instruments.map(data => (
              <div key={data.id}>
                <div><label>Name : </label>{data.title}</div>
                <div><label>Audio : </label><a target="_blank" href={data.audio_file}>{data.audio_file}</a></div>
                <div><label>Audio : </label><a target="_blank" href={data.printed}>{data.printed}</a></div>
                <div><label>Order : </label>{data.order}</div>
              </div>
            ))
          }
        </td>

        {/* videos */}
        <td>
          {
            piece.videos.map(data => (
              <div key={data.id}>
                <div><label>Video : </label><a target="_blank" href={data.video_file}>{data.video_file}</a></div>
                <div><label>Quality Level : </label>{data.quality_level}</div>
              </div>
            ))
          }
        </td>

        {/* interviews */}
        <td>
          {
            piece.interviews.map(data => (
              <div key={data.id}>
                <div><label>Title : </label>{data.title}</div>
                <div><label>Artist : </label>{data.artist}</div>
                <div><label>Video : </label><a target="_blank" href={data.audio}>{data.audio}</a></div>
                <div><label>Order : </label>{data.order}</div>
              </div>
            ))
          }
        </td>

        {/* closeups */}
        <td>
          {
            piece.closeups.map(data => (
              <div key={data.id}>
                <div><label>Artist : </label>{data.artist}</div>
                <div><label>Instrument : </label>{data.instrument}</div>
                <div><label>Video : </label><a target="_blank" href={data.audio}>{data.video}</a></div>
                <div><label>Order : </label>{data.order}</div>
              </div>
            ))
          }
        </td>
        <td style={{ textAlign: 'center' }}>
          {mode == 'edit' ? (
            <React.Fragment>
              <Link className="cus-btn-primary" to={`/piece/edit/${piece.id}`}>
                Edit
                </Link>
              <button className="cus-btn-danger mr-15" onClick={() => deletePiece(piece)}>
                Delete
                </button>
            </React.Fragment>
          ) : (
              <Link className="cus-btn-primary" to={`/piece/view/${piece.id}`}>
                View
              </Link>
            )}
        </td>
      </tr>
      : <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
  )
}
