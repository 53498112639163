import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

class CreateMember extends Component {
  state = {
    name: '',
    biography: '',
    artist_id: '',
    instrument_id: '',
    photo: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    piece: null,
    artist: [],
    instrument: null,
    photoUpload: false,
    isUploading: false,
  };

  componentDidMount() {
    if (this.props.memberInfo.mode === 'EDIT') {
      let artist = [];
      let instrument = [];
      this.props.memberInfo.instruments.forEach(instrumentInfo => {
        if (instrumentInfo.id === this.props.memberInfo.initialValues.instrument_id) {
          instrument[0] = instrumentInfo;
        }
      });
      this.props.memberInfo.artists.forEach(artistInfo => {
        if (artistInfo.id === this.props.memberInfo.initialValues.artist_id) {
          artist[0] = artistInfo;
        }
      });
      this.setState(prevState => ({
        ...prevState,
        ...this.props.memberInfo.initialValues,
        photoUploaded: true,
        artist,
        instrument,
        photoUpload: true,
      }));
    }
  }

  onChangeSetToState = stateKey => e => {
    this.setState({ [stateKey]: e.currentTarget.value });
  };

  onSubmit = () => {
    const { artist, photo, name, instrument, biography } = this.state;
    const artist_id = artist[0] ? artist[0].id : null;
    const instrument_id = instrument[0] && instrument[0].id;
    this.props.memberInfo.createMember({ artist_id, instrument_id, photo, biography, name });
  };

  onFileChange = async eve => {
    this.setState(prevState => ({
      ...prevState,
      photoUpload: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.memberInfo.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `members/images/${fileName}` });
    this.setState(prevState => ({
      ...prevState,
      file,
      photo: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
  };

  uploadAudio = async eve => {
    eve.preventDefault();
    if (!this.state.awsKeys.action) {
      toast.error('please try again.');
    }
    var form = document.querySelector('form');
    var data = new FormData(form);
    try {
      this.setState(prevState => ({
        ...prevState,
        isUploading: true,
      }));
      await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
      toast.success('Audio uploaded successfully.');
      this.setState(prevState => ({
        ...prevState,
        photoUpload: true,
        isUploading: false,
      }));
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  render() {
    const { isCreating, artists, instruments } = this.props.memberInfo;
    const { accessMode } = this.props;
    const { awsKeys, disableUpload, name, biography, artist, instrument } = this.state;
    return (
      <section className="well create-piece">
        <div className="row">
          <div className="col-md-3">
            <label>Name</label>
          </div>
          <div className="col-md-9">
            <input
              name="name"
              placeholder="Name"
              type="text"
              value={name}
              onChange={this.onChangeSetToState('name')}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label>Biography</label>
          </div>
          <div className="col-md-9">
            <input
              name="biography"
              placeholder="Biography"
              type="text"
              value={biography}
              onChange={this.onChangeSetToState('biography')}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Artist</label>
          </div>
          <div className="col-md-9">
            <Typeahead
              onChange={selected => {
                this.setState({
                  artist: selected,
                });
              }}
              value={artist}
              labelKey="title"
              options={artists}
              selected={artist}
              placeholder="Artist"
              className={`${accessMode == 'view' ? 'disabled' : ''}`}
            />
          </div>

          <div className="col-md-3">
            <label>Instrument</label>
          </div>
          <div className="col-md-9">
            <Typeahead
              onChange={selected => {
                this.setState({
                  instrument: selected,
                });
              }}
              labelKey="name"
              options={instruments}
              selected={instrument}
              placeholder="Instrument"
              className={`${accessMode == 'view' ? 'disabled' : ''}`}
            />
          </div>

          {accessMode == 'edit' && (
            <React.Fragment>
              <div className="col-md-3">
                <label>Photo</label>
              </div>
              <div className="col-md-9">
                <form method="post" encType="multipart/form-data" onSubmit={this.uploadAudio}>
                  <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                  <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                  <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                  <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                  <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                  <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                  <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                  <input type="file" name="file" onChange={this.onFileChange} /> <br />
                  <input
                    type="submit"
                    name="submit"
                    value="Upload to Amazon S3"
                    className="btn btn-default"
                    disabled={!disableUpload}
                  />
                </form>
              </div>
              <div className="create-piece__btn-container">
                <button
                  className="btn btn-primary btn-block btn-lg"
                  type="submit"
                  onClick={this.onSubmit}
                  disabled={
                    isCreating ||
                    !(
                      name &&
                      biography &&
                      instrument &&
                      instrument.length > 0 &&
                      // artist &&
                      // artist.length > 0 &&
                      this.state.photoUpload
                    )
                  }
                >
                  {isCreating || this.state.isUploading ? (
                    <span className="spinner" />
                  ) : (
                    <span>{accessMode == 'edit' ? 'Update' : 'Create'}</span>
                  )}
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
      </section>
    );
  }
}

export default reduxForm({ form: 'createPiece' })(CreateMember);
