import React, { Component } from 'react';
import axiosService from '../../inits/axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import FaqList from '../../components/Faqs/Faqs';
import PageLoader from '../../components/Common/PageLoader';
import { Field, reduxForm } from 'redux-form';

import { required } from '../../utils/validation.util';
import { renderInput } from '../../utils/input.util';

import '../../styles/piece.css';

class FaqsContainer extends Component {
  _isMounted = false;
  state = {
    isFetching: true,
    category: null,
    faqs: null,
    categories: null,
    mode: '',
    accessDenied: false,
  };
  componentDidMount() {
    this._isMounted = true;
    this.getFaqs();
    this.getCategories();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'faqs')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }
  getCategories = async () => {
    try {
      const categories = await axiosService.get(`/categories`);
      if (this._isMounted) {
        this.setState((prevState) => {
          return {
            ...prevState,
            categories,
            isFetching: false,
          };
        });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState((prevState) => {
          return {
            ...prevState,
            isFetching: false,
          };
        });
      }
    }
  };

  getFaqs = async () => {
    try {
      const faqs = await axiosService.get(`/faqs`);

      if (this._isMounted) {
        this.setState((prevState) => {
          return {
            ...prevState,
            faqs,
            isFetching: false,
          };
        });
      }
    } catch (error) {
      if (this._isMounted) {
        this.setState((prevState) => {
          return {
            ...prevState,
            isFetching: false,
          };
        });
      }
    }
  };
  deleteFaq = async (faq) => {
    await axiosService.delete('/faqs/' + faq.id);
  };

  removeCategory = async () => {
    await axiosService.delete('/categories/' + this.state.category);
  };
  addCategory = async () => {
    if (this.state.category === '') {
      toast.error("Can't add empty Category");
    } else {
      const category = await axiosService.post('/categories', { name: this.state.category.toLowerCase() });

      if (category.name === this.state.category) {
        toast.success('New category ' + category.name + ' added');
        this.setState({
          category: '',
        });
      }
      window.location.reload();
    }
  };

  render() {
    const { faqs, isFetching, categories, accessDenied, mode } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    return (
      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">Faqs</h3>
          {mode == 'edit' && (
            <Link className="cus-btn-primary pull-right" to="/faq/create">
              Add Faq
            </Link>
          )}
        </div>
        <div className="box-body">
          {mode == 'edit' && (
            <div className="row container" style={{ marginTop: '20px' }}>
              <div className="col-md-1">
                <label>Add New Category</label>
              </div>
              <div className="col-md-4">
                <Field
                  name="name"
                  placeholder="name"
                  component={renderInput}
                  type="text"
                  value={this.state.category}
                  onChange={(e) => {
                    this.setState({ category: e.target.value });
                  }}
                  validate={[required]}
                  className={`form-control ${mode == 'view' ? 'disabled' : ''}`}
                  parentClass="form-group"
                />
              </div>
              <button className="cus-btn-primary " onClick={this.addCategory.bind(this)}>
                Add
              </button>
            </div>
          )}
          {mode == 'edit' && categories ? (
            <div className="row container">
              <div className="col-md-1">
                <label>Categories</label>
              </div>
              <div className="col-md-4">
                <select className="form-control" onChange={(e) => this.setState({ category: e.target.value })}>
                  <option value="">View all Categories</option>
                  {categories.map((faqCategory, i) => (
                    <option value={faqCategory.id} key={i}>
                      {faqCategory.name}
                    </option>
                  ))}
                </select>
              </div>
              {this.state.category ? (
                <button className="btn btn-danger " onClick={this.removeCategory.bind(this)}>
                  Remove Selected
                </button>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {faqs ? <FaqList mode={mode} faqs={faqs} deleteFaq={this.deleteFaq} /> : ''}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'createCategory' })(FaqsContainer));
