import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  deletePiece,
  getPieces,
  editPiece,
  getHomePieceId,
  setHomePieceId,
  getPieceByID,
  getPieceSubscriptionByID,
  addPieceSubscription,
  deletePieceSubscription,
  getPiecesAddOns
} from '../../store/actions/piece/piece.action';
import { getSubscriptions } from '../../store/actions/subscription/subscription.action';
import PiecesList from '../../components/Pieces/Pieces';
import PageLoader from '../../components/Common/PageLoader';

class PiecesContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };
  componentDidMount() {
    this.props.getPieces();
    this.setAccess();
    this.props.getSubscriptions();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'pieces')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const {
      deletePiece,
      pieces,
      isFetching,
      editPiece,
      value,
      getHomePieceId,
      setHomePieceId,
      homePieceId,
      page,
      subscriptions,
      getPieceSubscriptionByID,
      addPieceSubscription,
      deletePieceSubscription,
      getPieceByID,
      getPiecesAddOns,
      pieces_categories
    } = this.props;
    const { accessDenied } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }
    const pieceInfo = { deletePiece, pieces, getPieceByID };
    return (
      <PiecesList
        homePieceMethods={{ getHomePieceId, setHomePieceId, homePieceId }}
        pieceInfo={pieceInfo}
        editPiece={editPiece}
        value={value}
        mode={this.state.mode}
        piece_page={page}
        subscriptions={subscriptions}
        pieceSubscriptionMethods={{ getPieceSubscriptionByID, addPieceSubscription, deletePieceSubscription }}
        getPiecesAddOns={getPiecesAddOns}
        pieces_categories={pieces_categories}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pieces: state.piece.list,
    page: state.piece.page,
    isFetching: state.piece.isFetching,
    isCreating: state.piece.isCreating,
    categories: state.auth.categories,
    homePieceId: state.piece.homePieceId,
    subscriptions: state.subscription.list,
    pieces_categories:state.piece.pieces_categories
  };
};
const mapDispatchToProps = {
  deletePiece,
  getPieces,
  editPiece,
  getHomePieceId,
  setHomePieceId,
  getSubscriptions,
  getPieceSubscriptionByID,
  addPieceSubscription,
  deletePieceSubscription,
  getPieceByID,
  getPiecesAddOns
};

export default connect(mapStateToProps, mapDispatchToProps)(PiecesContainer);
