import React from 'react';
import moment from 'moment';

const PieceVideo = props => {
  const { deletePieceVideo, pieceVideo } = props.pieceVideoInfo;
  return (
    <tr>
      <td>{pieceVideo.piece_id}</td>
      <td>{pieceVideo.quality_level}</td>
      <td>{pieceVideo.video_file}</td>
      <td>{moment(pieceVideo.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        <button className="btn btn-danger mr-15" onClick={() => deletePieceVideo(pieceVideo)}>
          Delete
        </button>
      </td>
    </tr>
  );
};

export default PieceVideo;
