import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import _ from 'lodash';
require('bootstrap/less/bootstrap.less');

const months=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      startYear=2017,
      sorting={
        nameAsc: null,
        emailAsc: null,
        created_atAsc: null,
        subscriptionAsc: null,
        sourceAsc: null,
        subsExpiryAsc:null
      };

class ListUsers extends Component {
  state = {
    pagination: {
      page: 1,
      total_entries_size: 0,
    },
    searchString: '',
    expiry:{
      month:-1,
      year:-1
    },
    usersInfo: [],
    tabsList: [],
    sorting:Object.assign({},sorting),
    currentTab: '',
  };

  componentDidUpdate(prevprop,prevState){
    if(prevState.searchString!==this.state.searchString || JSON.stringify(prevState.expiry)!==JSON.stringify(this.state.expiry)) {
      this.handleFilters();
    }
  }

  updateState = (k,v) =>{
    this.setState(p=>({...p,[k]:v}));
  }

  handleSorting = (type, value) => (e) => {
    e.stopPropagation();
    let { sorting } = this.state;
    for (let x in sorting) sorting[x] = null;
    sorting[type + 'Asc'] = value;
    this.setState(
      (prev) => ({ ...prev, sorting }),
      () => {
        let { usersInfo } = this.state;
        if (type == 'subscription') {
          const list = _.sortBy(usersInfo, (x) => x.Subscription.subscription.name);
          if (!value) list.reverse();
          this.setState((prev) => ({
            ...prev,
            usersInfo: list,
          }));
        } else if(type=='subsExpiry'){
          const list = _.sortBy(usersInfo, (x) => (x.Subscription.end_time));
          if (!value) list.reverse();
          this.setState((prev) => ({
            ...prev,
            usersInfo: list,
          }));
        }else {
          const list = _.sortBy(usersInfo, (x) => x[type]);
          if (!value) list.reverse();
          this.setState((prev) => ({
            ...prev,
            usersInfo: list,
          }));
        }
      }
    );
  };

  componentDidMount() {
    try {
      let { pagination } = this.state;
      pagination.total_entries_size = this.props.usersInfo.length;
      this.setState((prev) => ({
        ...prev,
        pagination,
        usersInfo: this.props.usersInfo,
        tabsList: [{ name: 'All Users', id: '' }, ...this.props.subscriptions_list].map(({ name, id }) => ({
          name,
          id,
        })),
      }));
    } catch (e) {}
  }

  renderUsers = (users) => {
    let pageNo = this.state.pagination.page;
    return users.slice((pageNo - 1) * 50, pageNo * 50).map((user, ind) => {
      return (
        <tr className="user-row" key={user.id}>
          <td>{(pageNo - 1) * 50 + ind + 1}</td>
          <td>
            {user.name}
            {user.role == 'admin' && <div className="super">[Super Admin]</div>}
            {user.role == 'subadmin' && <div className="sub">[Sub Admin]</div>}
          </td>
          <td>{user.email}</td>
          <td>{new Date(user.created_at).toUTCString()}</td>
          <td>{user.Subscription.subscription.name}</td>
          <td>{new Date(user.Subscription.end_time).toUTCString()}</td>
          <td>{user.source.toUpperCase()}</td>
          <td>
            <Link className="cus-btn-primary" to={`/users/${user.id}`}>
              View
            </Link>
          </td>
        </tr>
      );
    });
  };

  handlePageChange = (number) => {
    let { pagination } = this.state;
    pagination.page = number;
    this.setState((prev) => ({ ...prev, pagination }));
  };

  intervalFunc = null;

  handleFilters = () => {
    
    clearInterval(this.intervalFunc);
    this.intervalFunc = setTimeout(() => {
      let { pagination, currentTab, searchString, expiry } = this.state;
      pagination.page = 1;
      const reg = new RegExp(searchString);
      let usersInfo = this.props.usersInfo.filter(
        ({ name, email, Subscription }) =>{
          const date = new Date(Subscription.end_time),
            year=date.getFullYear(),
            month=date. getMonth();
          return (
            (currentTab == '' ? true : Subscription.subscription.id == currentTab) &&
            ((expiry.year>-1 ? expiry.year==year:true) && (expiry.month>-1 ? expiry.month==month:true)) &&
            (name.match(reg) || email.match(reg))
          )
        }
      );
      pagination.total_entries_size = usersInfo.length;

      for (let type in this.state.sorting) {
        if (typeof this.state.sorting[type] == 'object') continue;
        let value = this.state.sorting[type];
        type = type.split('Asc')[0];
        if (type == 'subscription') {
          usersInfo = _.sortBy(usersInfo, (x) => x.Subscription.subscription.name);
          if (!value) usersInfo.reverse();
        } else {
          usersInfo = _.sortBy(usersInfo, (x) => x[type]);
          if (!value) usersInfo.reverse();
        }
      }

      this.setState((prev) => ({
        ...prev,
        usersInfo,
        pagination,
        sorting:Object.assign({},sorting)
      }));
    }, 200);
  };

  handleTabChange = (sid) => {
    let { searchString, expiry } = this.state;
    const reg = new RegExp(searchString);
    let usersInfo = this.props.usersInfo.filter(
        ({ Subscription, name,email }) => {
          const date = new Date(Subscription.end_time),
            year=date.getFullYear(),
            month=date. getMonth();
            return (!sid.length || sid == Subscription.subscription_id) && (
              ((expiry.year>-1 ? expiry.year==year:true) && (expiry.month>-1 ? expiry.month==month:true)) &&
              (name.match(reg) || email.match(reg))
            )
        }),
      { pagination } = this.state;

    pagination.page = 1;
    pagination.total_entries_size = usersInfo.length;
    this.setState((prev) => ({
      ...prev,
      usersInfo,
      searchString: '',
      sorting:Object.assign({},sorting),
      currentTab: sid,
    }));
  };

  render() {
    const { pagination, usersInfo, searchString, sorting } = this.state;

    return (
      <div className="box list">
        <div className="box-header with-border">
          <h3 className="box-title">Users</h3>
        </div>

        <div className="user-buttons">
          <div className="search-user">
            <label>Search User :</label>&nbsp;
            <input value={searchString} onChange={e=>this.updateState('searchString',e.target.value)} />
          </div>
          <Link to="/user/create" className="cus-btn-primary">
            + Create User
          </Link>
        </div>
        <div className="user-buttons">
          <div>
            <label>Subscription Expiry :</label>&nbsp;
            <select 
              value={this.state.expiry.month} 
              onChange={e=>{
                const {expiry} = JSON.parse(JSON.stringify(this.state));
                expiry.month=Number(e.target.value);
                this.updateState('expiry',expiry);
              }}
            >
              <option key={-1} value={-1}>---MONTH---</option>
            { months.map((m,i)=><option key={m} value={i}>{m}</option>) }
            </select>
            <select 
              value={this.state.expiry.year}
              onChange={e=>{
                const {expiry} = JSON.parse(JSON.stringify(this.state));
                expiry.year=Number(e.target.value);
                this.updateState('expiry',expiry);
              }}
            >
              <option key={-1} value={-1}>---YEAR---</option>
            {
              [...Array((new Date()).getFullYear() - startYear+2).keys()]
              .map(idx=>(<option key={idx} value={startYear+idx}>{(startYear+idx)}</option>))
            }
            </select>
          </div>
          <button 
            className="cus-btn-primary" 
            onClick={()=>{
            const {expiry} = JSON.parse(JSON.stringify(this.state));
            expiry.year=expiry.month=-1;
            this.updateState('expiry',expiry);
          }}>Clear</button>
        </div>
        <div>
          <Pagination
            activePage={pagination.page}
            itemsCountPerPage={50}
            totalItemsCount={pagination.total_entries_size}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange}
          />
        </div>
        <Tabs defaultActiveKey={''} id="controlled-table" animation={false} onSelect={this.handleTabChange}>
          {this.state.tabsList.map((tab, ind) => (
            <Tab eventKey={tab.id} title={tab.name} key={ind}>
              <div className="box-body">
                <table className="table table-bordered table-responsive table-hover list-u">
                  <thead>
                    <tr>
                      <th className="t-10">
                        <span> S. No</span>
                        <span></span>
                      </th>
                      <th className="t-10" onClick={this.handleSorting('name', !sorting.nameAsc)}>
                        <span>Name</span>
                        <i
                          className={`fas ${
                            typeof sorting.nameAsc === 'boolean'
                              ? sorting.nameAsc
                                ? 'fa-caret-down'
                                : 'fa-caret-up'
                              : 'fa-caret-down disabled'
                          }`}
                        ></i>
                      </th>
                      <th className="t-10" onClick={this.handleSorting('email', !sorting.emailAsc)}>
                        <span>Email</span>
                        <i
                          className={`fas ${
                            typeof sorting.emailAsc === 'boolean'
                              ? sorting.emailAsc
                                ? 'fa-caret-down'
                                : 'fa-caret-up'
                              : 'fa-caret-down disabled'
                          }`}
                        ></i>
                      </th>
                      <th className="t-10" onClick={this.handleSorting('created_at', !sorting.created_atAsc)}>
                        <span>Date Created</span>
                        <i
                          className={`fas ${
                            typeof sorting.created_atAsc === 'boolean'
                              ? sorting.created_atAsc
                                ? 'fa-caret-down'
                                : 'fa-caret-up'
                              : 'fa-caret-down disabled'
                          }`}
                        ></i>
                      </th>
                      <th className="t-10" onClick={this.handleSorting('subscription', !sorting.subscriptionAsc)}>
                        <span>Subscription</span>
                        <i
                          className={`fas ${
                            typeof sorting.subscriptionAsc === 'boolean'
                              ? sorting.subscriptionAsc
                                ? 'fa-caret-down'
                                : 'fa-caret-up'
                              : 'fa-caret-down disabled'
                          }`}
                        ></i>
                      </th>
                      <th className="t-10" onClick={this.handleSorting('subsExpiry', !sorting.subsExpiryAsc)}>
                        <span>Subscription Expiry Time</span>
                        <i
                          className={`fas ${
                            typeof sorting.subsExpiryAsc === 'boolean'
                              ? sorting.subsExpiryAsc
                                ? 'fa-caret-down'
                                : 'fa-caret-up'
                              : 'fa-caret-down disabled'
                          }`}
                        ></i>
                      </th>
                      <th className="t-10" onClick={this.handleSorting('source', !sorting.sourceAsc)}>
                        <span>Source</span>
                        <i
                          className={`fas ${
                            typeof sorting.sourceAsc === 'boolean'
                              ? sorting.sourceAsc
                                ? 'fa-caret-down'
                                : 'fa-caret-up'
                              : 'fa-caret-down disabled'
                          }`}
                        ></i>
                      </th>
                      <th className="t-10">
                        <span>Details</span>
                        <span></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{this.renderUsers(usersInfo)}</tbody>
                </table>
              </div>
            </Tab>
          ))}
        </Tabs>

        <div>
          <Pagination
            activePage={pagination.page}
            itemsCountPerPage={50}
            totalItemsCount={pagination.total_entries_size}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange}
          />
        </div>
      </div>
    );
  }
}

export default ListUsers;
