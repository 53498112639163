import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteMember, getMembers } from '../../store/actions/member/member.action';
import MemberList from '../../components/Member/Members';
import PageLoader from '../../components/Common/PageLoader';

class PiecesInstrumentContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    this.props.getMembers();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'members')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { deleteMember, members, isFetching } = this.props;
    const { mode } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }
    const memberInfo = { deleteMember, members };
    return (
      <section>
        <MemberList mode={mode} memberInfo={memberInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    members: state.member.list,
    isFetching: state.member.isFetching,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  deleteMember,
  getMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(PiecesInstrumentContainer);
