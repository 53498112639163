import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import {
  createSubscription,
  editSubscription,
  getSubscriptionByID,
  deleteSubscriptionFeature,
  addSubscriptionFeature,
} from '../../store/actions/subscription/subscription.action';

import { getFeatures } from '../../store/actions/feature/feature.action';
import { getAwsKey } from '../../store/actions/piece-video/piece-video.action';
import { getInstruments } from '../../store/actions/instrument/instrument.action';
import { createMember, deleteMember } from '../../store/actions/member/member.action';
import CreateSubscription from '../../components/Subscription/CreateSubscription';
import PageLoader from '../../components/Common/PageLoader';

import '../../styles/artist.css';

class CreateSubscriptionContainer extends Component {
  state = {
    mode: 'CREATE',
    startRender: false,
    subscriptionToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    const mode = params && params.id ? 'EDIT' : 'CREATE';
    this.processCreateOREdit(mode, params.id);
    this.setAccess();
    this.props.getFeatures();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'subscriptions')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  processCreateOREdit = async (mode, subscriptionId) => {
    const { getInstruments } = this.props;
    Promise.all([getInstruments()])
      .then(async (data) => {
        if (mode === 'EDIT') {
          return this.getSubscriptionByID(mode, subscriptionId);
        }
        this.setState((prevState) => {
          return {
            ...prevState,
            mode,
            startRender: true,
          };
        });
      })
      .catch((error) => {
        history.push('/members');
      });
  };

  getSubscriptionByID = async (mode, subscriptionId) => {
    let subscriptionToBeEdit;
    try {
      subscriptionToBeEdit = await this.props.getSubscriptionByID(subscriptionId);
      this.setState((prevState) => {
        return {
          ...prevState,
          mode,
          startRender: true,
          subscriptionToBeEdit,
        };
      });
    } catch (error) {
      history.push('/subscriptions');
    }
  };

  render() {
    if (!this.state.startRender) {
      return <PageLoader />;
    }
    const {
      createSubscription,
      isCreating,
      getAwsKey,
      editSubscription,
      instruments,
      createMember,
      deleteMember,
      subscriptions,
      currentSubscription,
    } = this.props;

    const { subscriptionToBeEdit, mode, accessDenied, accessMode } = this.state;
    const { features_list, addSubscriptionFeature, deleteSubscriptionFeature } = this.props;

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const subscriptionInfo = {
      isCreating,
      createSubscription,
      getAwsKey,
      editSubscription,
      initialValues: subscriptionToBeEdit,
      mode: mode,
      deleteSubscriptionFeature,
      addSubscriptionFeature,
    };
    const memberInfo = {
      instruments,
      getAwsKey,
      createMember,
      deleteMember,
      subscriptions,
      currentSubscription,
      getSubscriptionByID,
    };

    return (
      <div className="row artist-create-container">
        <CreateSubscription
          mode={mode}
          accessMode={accessMode}
          subscriptionInfo={subscriptionInfo}
          features_list={features_list}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCreating: state.subscription.isCreating,
    instruments: state.instrument.list,
    subscriptions: state.subscription.list,
    currentSubscription: state.subscription.currentSubscription,
    categories: state.auth.categories,
    features_list: state.feature.list,
  };
};
const mapDispatchToProps = {
  createSubscription,
  getAwsKey,
  editSubscription,
  getSubscriptionByID,
  getInstruments,
  createMember,
  deleteMember,
  getFeatures,
  addSubscriptionFeature,
  deleteSubscriptionFeature,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubscriptionContainer);
