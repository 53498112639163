import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';

function PieceSubscriptionsChart(props) {
  const [state, setState] = useState({ labels: [], data: [] });
  const { subscriptions, pieces } = props;
  useEffect(() => {
    let mapSubsPieceCount = {},
      labels = _.map(
        _.sortBy(subscriptions, (s) => -Number(s.price)),
        ({ name, id }, ind) => {
          mapSubsPieceCount[id] = ind;
          return name;
        }
      );

    let data = [...Array(labels.length).keys()].map(() => 0);

    pieces.forEach(({ subscriptions }) => {
      subscriptions.forEach(({ id }) => {
        data[mapSubsPieceCount[id]]++;
      });
    });
    setState({ labels, data });
  }, []);

  const options = {
    title: {
      display: true,
      text: 'Number of pieces under subscription',
      fontSize: 20,
    },
    scales: {
      xAxes: [
        {
          // Change here
          barPercentage: 0.2,
        },
      ],
    },
  };

  return (
    <div className="piece-subscription-chart chart">
      <Bar
        width={600}
        data={{
          labels: state.labels,

          datasets: [
            {
              label: 'Pieces',
              backgroundColor: '#5568ab',
              borderColor: '#5568ab',
              borderWidth: 1,
              data: state.data,
            },
          ],
        }}
        options={options}
      />
    </div>
  );
}

export default PieceSubscriptionsChart;
