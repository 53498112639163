import React from 'react';
import { Link } from 'react-router-dom';
import Feature from './Feature';

const ListFeatures = (props) => {
  const { features } = props.featureInfo;

  const changeOrder = async (id, prevO, nextO) => {
    await props.changeOrder({ id, prevO, nextO, list: features });
    props.getFeatures();
  };

  const deleteFeature = async (feature) => {
    await props.featureInfo.deleteFeature({ feature, features });
    props.getFeatures();
  };

  const getFeature = () => {
    return features.map((feature, index) => {
      const featureInfo = { deleteFeature, feature: { ...feature, index: index + 1 } };
      return (
        <Feature
          mode={props.mode}
          onEdit={props.editFeature}
          featureInfo={featureInfo}
          key={feature.id}
          changeOrder={changeOrder}
          total={features.length}
        />
      );
    });
  };

  const { mode } = props;
  return (
    <div className="box">
      <div className="box-header with-border">
        <h3 className="box-title">Features</h3>
        {mode == 'edit' && (
          <Link className="cus-btn-primary pull-right" to="/feature/create">
            Add Feature
          </Link>
        )}
      </div>
      <div className="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Name</th>
              <th>Description</th>
              <th>Sequence</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{getFeature()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListFeatures;
