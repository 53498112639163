import React from 'react';
import { Link } from 'react-router-dom';

import PieceVideo from './PieceVideo';

const ListPiecesVideo = props => {
  const { piecesVideo, deletePieceVideo } = props.pieceVideoInfo;

  const getPiecesVideo = () => {
    return piecesVideo.map(pieceVideo => {
      const pieceVideoInfo = { deletePieceVideo, pieceVideo };
      return <PieceVideo pieceVideoInfo={pieceVideoInfo} key={pieceVideo.id} />;
    });
  };
  return (
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title"> Piece Videos</h3>
        <Link className="btn btn-primary pull-right" to="/piece-video/create">
          Add Piece Video
        </Link>
      </div>
      <div class="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th>Piece</th>
              <th>Quality level</th>
              <th>Video file</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{getPiecesVideo()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListPiecesVideo;
