import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { axiosInterceptor } from './inits/axios';
import history from './inits/history';
import store from './store';
import logs from './components/Common/HistoryLogs';

import { getCurrentUser } from './store/actions/auth/auth.action';
import { getLocalStorage } from './utils/web-storage';
import { PrivateRoute, PublicRoute } from './utils/route';

// screens
import LoginContainer from './containers/Login/LoginContainer';
import DifficultiesContainer from './containers/Difficulties/DifficultiesContainer';
import CreateDifficultyContainer from './containers/Difficulties/CreateDifficultyContainer';
import ComposersContainer from './containers/Composers/ComposersContainer';
import CreateComposerContainer from './containers/Composers/CreateComposerContainer';
import ErasContainer from './containers/Eras/ErasContainer';
import CreateEraContainer from './containers/Eras/CreateEraContainer';
import InstrumentsContainer from './containers/Instruments/InstrumentsContainer';
import CreateInstrumentContainer from './containers/Instruments/CreateInstrumentContainer';
import PiecesContainer from './containers/Pieces/PiecesContainer';
import CreatePieceContainer from './containers/Pieces/CreatePieceContainer';

import PiecesVideoContainer from './containers/PiecesVideo/PiecesVideoContainer';
import CreatePieceVideoContainer from './containers/PiecesVideo/CreatePieceVideoContainer';
import PiecesInstrumentContainer from './containers/PiecesInstrument/PiecesInstrumentContainer';
import CreatePieceInstrumentContainer from './containers/PiecesInstrument/CreatePieceInstrumentContainer';

import ArtistListContainer from './containers/Artist/ArtistListContainer';
import CreateArtistContainer from './containers/Artist/CreateArtistContainer';

import MemberListContainer from './containers/Member/MemberListContainer';
import CreateMemberContainer from './containers/Member/CreateMemberContainer';
import CreateFaqsContainer from './containers/Faqs/CreateFaqsContainer';
import FaqsContainer from './containers/Faqs/FaqsContainer';

import SubscriptionListContainer from './containers/Subscriptions/subscriptionsContainer';
import CreateSubscriptionContainer from './containers/Subscriptions/CreateSubscriptionContainer';
import CreateCouponsContainer from './containers/Coupons/CreateCouponsContainer';
import CouponsContainer from './containers/Coupons/CouponsContainer';

import OrdersContainer from './containers/Orders/OrdersContainer';

import AdminsContainer from './containers/Admins/AdminsContainer';
import CreateAdminsContainer from './containers/Admins/CreateAdminsContainer';
import NewCategoryContainer from './containers/New Category/NewCategoryContainer';

import UsersListContainer from './containers/Users/UsersListContainer';
import SingleUserContainer from './containers/Users/SingleUserContainer';
import CreateUserContainer from './containers/Users/CreateUserContainer';
import DataAnalyticsContainer from './containers/Analytics/DataAnalyticsContainer';

// component
import PageLoader from './components/Common/PageLoader';
import Header from './containers/Common/Header';
import Sidebar from './containers/Common/Sidebar';

import FeaturesContainer from './containers/Features/FeaturesContainer';
import CreateFeatureContainer from './containers/Features/CreateFeatureContainer';

axiosInterceptor(store);

class App extends Component {
  state = {
    loading: true,
    sidebarOpen: true,
    loadSidebar: false,
  };

  componentWillMount() {
    this.authenticateUser();
  }

  componentDidMount() {
    history.listen((loc) => {
      this.loginPageClass(loc);
    });
  }

  loginPageClass = (loc) => {
    if (loc.pathname == '/') {
      document.querySelector('.fixedElements').classList.add('loginScreen');
      this.setState({ ...this.state, loadSidebar: false });
    } else {
      if (!this.state.loadSidebar) this.setState({ ...this.state, loadSidebar: true });
      document.querySelector('.fixedElements').classList.remove('loginScreen');
    }
  };

  authenticateUser = async () => {

    await logs.authenticateGUser();
    const accessToken = getLocalStorage('accessToken');
    if (accessToken) {
      try {
        await store.dispatch(getCurrentUser(accessToken));
      } finally {
        this.setState({
          loading: false,
        });
      }
    } else {
      this.setState({
        loading: false,
      });
    }

    
  };

  toggleSidebar = () => {
    this.setState((prev) => ({
      ...prev,
      sidebarOpen: !prev.sidebarOpen,
    }));
  };

  render() {
    const { sidebarOpen, loadSidebar } = this.state;

    if (this.state.loading) {
      return <PageLoader />;
    }

    const user = store.getState().auth.user;
    return (
      <Provider store={store}>
        <Router history={history}>
          <div className="top-container">
            <ToastContainer autoClose={3000} />
            {loadSidebar && <Sidebar sidebarOpen={sidebarOpen} />}

            <div className="fixedElements" onLoad={() => this.loginPageClass(window.location)}>
              {user && Object.keys(user).length ? (
                <Header sidebarOpen={sidebarOpen} toggleSidebar={this.toggleSidebar} handleChange={this.handleChange} />
              ) : (
                ''
              )}

              <div id="contentWrapper" className="content-wrapper">
                <Switch>
                  <PublicRoute exact={true} path="/" component={LoginContainer} />
                  <PrivateRoute exact={true} path="/difficulties" component={DifficultiesContainer} />
                  <PrivateRoute exact={true} path="/difficulty/create" component={CreateDifficultyContainer} />
                  <PrivateRoute exact={true} path="/difficulty/:mode/:id" component={CreateDifficultyContainer} />
                  <PrivateRoute exact={true} path="/composers" component={ComposersContainer} />
                  <PrivateRoute exact={true} path="/composer/create" component={CreateComposerContainer} />
                  <PrivateRoute exact={true} path="/composer/:mode/:id" component={CreateComposerContainer} />
                  <PrivateRoute exact={true} path="/eras" component={ErasContainer} />
                  <PrivateRoute exact={true} path="/era/create" component={CreateEraContainer} />
                  <PrivateRoute exact={true} path="/era/:mode/:id" component={CreateEraContainer} />
                  <PrivateRoute exact={true} path="/instruments" component={InstrumentsContainer} />
                  <PrivateRoute exact={true} path="/instrument/create" component={CreateInstrumentContainer} />
                  <PrivateRoute exact={true} path="/instrument/:mode/:id" component={CreateInstrumentContainer} />

                  <PrivateRoute exact={true} path="/pieces" component={PiecesContainer} />
                  <PrivateRoute exact={true} path="/piece/create" component={CreatePieceContainer} />
                  <PrivateRoute exact={true} path="/piece/:mode/:id" component={CreatePieceContainer} />
                  <PrivateRoute exact={true} path="/pieces-video" component={PiecesVideoContainer} />
                  <PrivateRoute exact={true} path="/piece-video/create" component={CreatePieceVideoContainer} />
                  <PrivateRoute exact={true} path="/pieces-instrument" component={PiecesInstrumentContainer} />
                  <PrivateRoute
                    exact={true}
                    path="/piece-instrument/create"
                    component={CreatePieceInstrumentContainer}
                  />
                  <PrivateRoute exact={true} path="/artists" component={ArtistListContainer} />
                  <PrivateRoute exact={true} path="/artist/create" component={CreateArtistContainer} />
                  <PrivateRoute exact={true} path="/artist/:mode/:id" component={CreateArtistContainer} />
                  <PrivateRoute exact={true} path="/members" component={MemberListContainer} />
                  <PrivateRoute exact={true} path="/member/create" component={CreateMemberContainer} />
                  <PrivateRoute exact={true} path="/member/:mode/:id" component={CreateMemberContainer} />
                  <PrivateRoute exact={true} path="/faqs" component={FaqsContainer} />
                  <PrivateRoute exact={true} path="/faq/create" component={CreateFaqsContainer} />
                  <PrivateRoute exact={true} path="/faq/:mode/:id" component={CreateFaqsContainer} />
                  <PrivateRoute exact={true} path="/subscriptions" component={SubscriptionListContainer} />
                  <PrivateRoute exact={true} path="/subscription/:mode/:id" component={CreateSubscriptionContainer} />
                  <PrivateRoute exact={true} path="/subscription/create" component={CreateSubscriptionContainer} />
                  <PrivateRoute exact={true} path="/features" component={FeaturesContainer} />
                  <PrivateRoute exact={true} path="/feature/create" component={CreateFeatureContainer} />
                  <PrivateRoute exact={true} path="/feature/:mode/:id" component={CreateFeatureContainer} />
                  <PrivateRoute exact={true} path="/coupons" component={CouponsContainer} />
                  <PrivateRoute exact={true} path="/coupon/create" component={CreateCouponsContainer} />
                  <PrivateRoute exact={true} path="/coupon/:mode/:id" component={CreateCouponsContainer} />
                  <PrivateRoute exact={true} path="/orders" component={OrdersContainer} />

                  <PrivateRoute exact={true} path="/admins" component={AdminsContainer} />
                  <PrivateRoute exact={true} path="/admins/create" component={CreateAdminsContainer} />
                  <PrivateRoute exact={true} path="/admins/edit/:id" component={CreateAdminsContainer} />

                  <PrivateRoute exact={true} path="/users" component={UsersListContainer} />
                  <PrivateRoute exact={true} path="/users/:id" component={SingleUserContainer} />
                  <PrivateRoute exact={true} path="/user/create" component={CreateUserContainer} />

                  <PrivateRoute exact={true} path="/new-category" component={NewCategoryContainer} />

                  <PrivateRoute exact={true} path="/analytics" component={DataAnalyticsContainer} />

                  <Route render={() => <p>Not Found</p>} />
                </Switch>
              </div>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
