import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Difficulty = props => {
  const { difficulty, deleteDifficulty } = props.difficultyInfo;
  const { mode } = props;
  return (
    <tr>
      <td>{difficulty.id}</td>
      <td>{difficulty.name}</td>
      <td>{difficulty.description}</td>
      <td>{moment(difficulty.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        {mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteDifficulty(difficulty)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/difficulty/edit/${difficulty.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/difficulty/view/${difficulty.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Difficulty;
