import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import axiosService from '../../inits/axios';
import { required } from '../../utils/validation.util';
import { renderInput, renderTextAtrea } from '../../utils/input.util';
import PageLoader from '../../components/Common/PageLoader';
import RichTextEditor from 'react-rte';
const faqCategories = ['general', 'tech', 'account', 'privacy', 'pricing'];

class CreateFaq extends Component {
  state = { answer: RichTextEditor.createEmptyValue() };
  componentWillMount() {
    if (this.props.mode === 'EDIT') {
      const { question, answer, category, id } = this.props.initialValues;
      this.setState({
        ...this.state,
        question,
        answer: RichTextEditor.createValueFromString(answer, 'html'),
        answerPost: RichTextEditor.createValueFromString(answer, 'html'),
        category,
        id,
        isFetching: true,
      });
    }
    this.getCategories();
  }

  getCategories = async () => {
    try {
      const categories = await axiosService.get(`/categories`);
      this.setState((prevState) => {
        return {
          ...prevState,
          categories,
          isFetching: false,
        };
      });
    } catch (error) {
      this.setState((prevState) => {
        return {
          ...prevState,
          isFetching: false,
        };
      });
    }
  };

  onChange = (e) => {
    this.setState({ answer: e, answerPost: e.toString('html'), answerError: false });
  };
  onChangeSetToState = (stateKey) => (e) => {
    if (stateKey === 'category') {
      this.setState({
        categoryError: false,
      });
    }
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = () => {
    let { answer, question, id, answerPost, category } = this.state;
    if (answerPost === undefined || answerPost === '<p><br></p>') {
      this.setState({
        answerError: true,
      });
      return;
    }
    if (!category) {
      this.setState({
        categoryError: true,
      });
      return;
    }
    if (this.props.mode === 'EDIT') {
      if (typeof answerPost != 'string') answerPost = answerPost['_cache'].html;
      this.props.onEdit({ answer: answerPost, question, id, category });
      return;
    }

    this.props.onCreate({ answer: answerPost, question, category });
  };

  render() {
    const { mode, isCreating, accessMode } = this.props;

    const { categories, isFetching } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }
    return (
      <div className="well create-piece">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-md-3">
              <label>Question</label>
            </div>
            <div className="col-md-9">
              <Field
                name="question"
                titleName="Question"
                placeholder="Question"
                component={renderInput}
                type="text"
                value={this.state.question}
                onChange={this.onChangeSetToState('question')}
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
              />
            </div>
            <div className="col-md-3">
              <label>Answer</label>
            </div>
            <div className="col-md-9">
              <RichTextEditor readOnly={accessMode == 'view'} value={this.state.answer} onChange={this.onChange} />
              {this.state.answerError === true && <div style={{ color: 'red' }}>Required Field</div>}
            </div>
            {categories ? (
              <div className="">
                <div className="col-md-3">
                  <label>Category</label>
                </div>
                <div className="col-md-9">
                  <select
                    className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                    value={this.state.category}
                    onChange={this.onChangeSetToState('category')}
                  >
                    <option value="">Select a Category</option>
                    {categories.map((faqCategory, i) => (
                      <option key={i} value={faqCategory.name}>
                        {faqCategory.name}
                      </option>
                    ))}
                  </select>
                  {this.state.categoryError === true && <div style={{ color: 'red' }}>Required Field</div>}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          {accessMode == 'edit' && (
            <div className="create-piece__btn-container">
              <button className="cus-btn-primary btn-block btn-lg" type="submit" disabled={isCreating}>
                {isCreating ? <span className="spinner" /> : <span>{mode === 'EDIT' ? 'Update' : 'Create'}</span>}
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default reduxForm({ form: 'createFaq' })(CreateFaq);
