import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Feature = (props) => {
  const { deleteFeature, feature } = props.featureInfo;
  const { mode, total, changeOrder } = props;
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState('');

  const featureEdit = async () => {
    await props.onEdit({ id: feature.id, sequence: Number(text) });
    setEdit(false);
  };

  useEffect(() => {
    setText(feature.sequence);
  }, []);

  return (
    <tr>
      <td>{feature.index}</td>
      <td>{feature.name}</td>
      <td>{feature.description}</td>
      <td>
        <React.Fragment>
          <select
            value={feature.sequence}
            onChange={(e) => changeOrder(feature.id, feature.sequence, Number(e.target.value))}
          >
            {[...Array(total).keys()].map((d) => (
              <option key={d} value={d + 1}>
                {d + 1}
              </option>
            ))}
          </select>
        </React.Fragment>
      </td>
      <td>
        {mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteFeature(feature)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/feature/edit/${feature.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/feature/view/${feature.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Feature;
