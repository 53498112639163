import { toast } from 'react-toastify';

import axiosService from '../../../inits/axios';
import { GET_ORDERS_START, GET_ORDERS_SUCCESS, GET_ORDERS_ERROR } from './order.actiontype';

export const getOrders = () => async dispatch => {
  try {
    dispatch({ type: GET_ORDERS_START });
    const orders = await axiosService.get('/orders?per_page=1000');
    dispatch({ type: GET_ORDERS_SUCCESS, payload: orders });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_ORDERS_ERROR });
  }
};
