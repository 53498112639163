import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOrders } from '../../store/actions/order/order.action';
import OrdersList from '../../components/Orders/Orders';
import PageLoader from '../../components/Common/PageLoader';

class OrdersContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    this.props.getOrders();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'orders')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { orders, isFetching } = this.props;
    const { mode, accessDenied } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const orderInfo = { orders };
    return (
      <section>
        <OrdersList mode={mode} orderInfo={orderInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.order.list,
    isFetching: state.order.isFetching,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  getOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);
