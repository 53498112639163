import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_ERAS_START,
  GET_ERAS_SUCCESS,
  GET_ERAS_ERROR,
  CREATE_ERA_START,
  CREATE_ERA_SUCCESS,
  CREATE_ERA_ERROR,
  DELETE_ERA_START,
  DELETE_ERA_SUCCESS,
  DELETE_ERA_ERROR,
  EDIT_ERA_START,
  EDIT_ERA_SUCCESS,
  EDIT_ERA_ERROR,
} from './era.actiontype';

import { getError } from '../../../utils/common.util';

export const getEras = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ERAS_START });
    const eras = await axiosService.get('/pieces/eras');
    dispatch({ type: GET_ERAS_SUCCESS, payload: eras });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_ERAS_ERROR });
  }
};

export const getEraByID = (eraId) => async (dispatch) => {
  try {
    const era = await axiosService.get(`/pieces/eras/${eraId}`);
    return Promise.resolve(era);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const createEra = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_ERA_START });
    const era = await axiosService.post('/pieces/eras', fields);
    toast.success('Successfully created.');
    dispatch({ type: CREATE_ERA_SUCCESS, payload: era });
    history.push('/eras');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_ERA_ERROR });
  }
};

export const deleteEra = (era) => async (dispatch) => {
  if (!window.confirm('Are you sure to delete this era ?')) return;
  try {
    dispatch({ type: DELETE_ERA_START });
    const deletedEra = await axiosService.delete(`/pieces/eras/${era.id}`);
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_ERA_SUCCESS, payload: deletedEra });
  } catch (error) {
    if (error.code == 500) toast.error('This era is already attached to piece. Deletion restricted.');
    dispatch({ type: DELETE_ERA_ERROR });
  }
};

export const editEra = (era) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_ERA_START });
    const newEra = await axiosService.put(`/pieces/eras/${era.id}`, era);
    toast.success('Successfully saved.');
    dispatch({ type: EDIT_ERA_SUCCESS, payload: newEra });
    history.push('/eras');
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_ERA_ERROR });
  }
};
