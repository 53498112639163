import * as React from 'react';

export const renderInput = props => {
  const { input, meta, placeholder, type, className, parentClass } = props;
  return (
    <div className={parentClass}>
      <input {...input} placeholder={placeholder} type={type} className={className} />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export const renderTextAtrea = props => {
  const { input, meta, placeholder, type, className, parentClass } = props;
  return (
    <div className={parentClass}>
      <textarea {...input} placeholder={placeholder} type={type} className={className} />
      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
    </div>
  );
};

export const renderBSelect = props => {
  const { input, meta, className, label, options, placeholder } = props;
  return (
    <div className="form-group">
      <label htmlFor="input" className="input-label">
        {label}
      </label>
      <select {...input} className={`form-control ${className}`}>
        <option value="">{placeholder}</option>
        {options.map(opt => (
          <option value={opt.value} key={opt.value}>
            {opt.displayName}
          </option>
        ))}
      </select>
      {meta.touched && meta.error && <small className="form-text text-muted error">{meta.error}</small>}
    </div>
  );
};
