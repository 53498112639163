import React from 'react';
import { Link } from 'react-router-dom';
import Composer from './Composer';

const ListComposers = props => {
  const { composers, deleteComposer } = props.composerInfo;

  const getComposer = () => {
    return composers.map(composer => {
      const composerInfo = { deleteComposer, composer };
      return <Composer mode={props.mode} composerInfo={composerInfo} key={composer.id} />;
    });
  };

  const { mode } = props;
  return (
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Composers</h3>
        {mode == 'edit' && (
          <Link className="cus-btn-primary pull-right" to="/composer/create">
            Add Composer
          </Link>
        )}
      </div>
      <div class="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{getComposer()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListComposers;
