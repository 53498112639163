import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

import { renderInput } from '../../utils/input.util';

class CreateCloseups extends Component {
  state = {
    video_file: '',
    video: '',
    order: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    videoUploaded: false,
    isUploading: false,
    closeupInputEditOn: false,
    closeupInputOn: false,
  };

  onChangeSetToState = (stateKey) => (e) => {
    let updates = {
      [stateKey]: e.currentTarget.value.trim(),
    };
    if (['video_file'].includes(stateKey)) {
      updates.disableUpload = true;
    }
    this.setState((prev) => ({ ...prev, ...updates }));
  };

  onSubmit = async () => {
    const { video_file, title, member, instrument, artist, order } = this.state;
    const piece_id = this.props.currentPiece.id;
    const instrumentName = instrument[0] && instrument[0].name;
    await this.props.onCreate({
      piece_id,
      artist: artist[0].name,
      video: video_file,
      title,
      order,
      sequence: order,
      instrument: instrumentName,
    });
    this.setState((prevState) => ({
      ...prevState,
      video_file: '',
      video: '',
      order: '',
      artist: [],
      file: {},
      awsKeys: {},
      disableUpload: false,
      videoUploaded: false,
      isUploading: false,
      title: '',
      members: null,
      instrument: null,
      closeupInputEditOn: false,
      closeupInputOn: false,
      CloseupToEdit: null,
    }));
    this.props.change('title', '');
    try {
      document.getElementById('uploadPieceCloseup').value = null;
    } catch (e) {}
    this.typeaheadIns.getInstance().clear();
  };
  editCloseup = async () => {
    const { piece_id, artist, video, instrument, id, order } = this.state.CloseupToEdit;

    const instrumentName = instrument[0].name;

    await this.props.onEdit(
      { piece_id, artist: artist[0].name, order, video, id, instrument: instrumentName },
      this.props.pieceCloseups[this.state.showFollowing]
    );
    this.setState((prevState) => ({
      video_file: '',
      video: '',
      order: '',
      file: {},
      awsKeys: {},
      disableUpload: false,
      videoUploaded: false,
      isUploading: false,
      title: '',
      members: null,
      instrument: null,
      showFollowing: false,
      closeupInputEditOn: false,
      closeupInputOn: false,
      CloseupToEdit: null,
    }));
    this.props.change('title', '');
    this.typeaheadIns.getInstance().clear();
    this.props.triggerGetPiece();
  };
  onFileChange = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      videoUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `pieces/closeups/videos/${fileName}` });
    this.setState((prevState) => ({
      ...prevState,
      file,
      video_file: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
    if (this.state.CloseupToEdit) {
      let VideoFile = this.state.CloseupToEdit;
      VideoFile['video'] = `${awsKeys.action}${awsKeys.key}`;
      this.setState({
        CloseupToEdit: VideoFile,
      });
    }
  };

  uploadVideo = (label) => async (eve) => {
    eve.preventDefault();
    // if (label !== 'editInterview' && !this.state.awsKeys.action) {
    //   toast.error('please try again.');
    // }
    if (label === 'editCloseup') {
      if (Object.keys(this.state.awsKeys).length) {
        var form = document.getElementById('uploadCloseupsVideoEdit');
        var data = new FormData(form);
      }
    } else {
      if (Object.keys(this.state.awsKeys).length) {
        var form = document.getElementById('uploadCloseupsVideo');
        var data = new FormData(form);
      }
    }
    try {
      this.setState((prevState) => ({
        ...prevState,
        isUploading: true,
      }));

      if (this.state.awsKeys.action) {
        await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
        toast.success('Video uploaded successfully.');
      }
      this.setState((prevState) => ({
        ...prevState,
        videoUploaded: true,
      }));
      if (label === 'editCloseup') {
        this.editCloseup();
      } else {
        this.onSubmit();
      }
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  onDelete = async (closeup) => {
    await this.props.onDelete(closeup);
  };
  Show = (i) => {
    const { piecesInfo } = this.props;

    const CloseupToEditArray = this.props.pieceCloseups.filter((video, id) => {
      if (id == i) {
        return video;
      }
    });
    const CloseupObject = { ...CloseupToEditArray[0] };

    CloseupObject.artist = piecesInfo.members.filter(({ name }) => name == CloseupObject.artist);
    CloseupObject.instrument = piecesInfo.instruments.filter(({ name }) => name == CloseupObject.instrument);

    this.setState({
      showFollowing: i,
      CloseupToEdit: CloseupObject,
    });
  };

  handleStateChange = (key, val) => (e) => {
    e.stopPropagation();
    let updates = { [key]: val };
    if (['closeupInputEditOn', 'closeupInputOn'].includes(key)) {
      updates['video_file'] = '';
      updates['awsKeys'] = {};
    }
    this.setState((prev) => ({ ...prev, ...updates }));
  };

  handleEditStateChange = (key, value) => {
    let ArtistCloseup = this.state.CloseupToEdit;
    ArtistCloseup[key] = value;
    this.setState((prev) => ({
      ...prev,
      CloseupToEdit: ArtistCloseup,
    }));
  };

  getPieceCloseups = () => {
    const { isCreating, artistInfo, instruments, accessMode, piecesInfo } = this.props;
    const { awsKeys, disableUpload, closeupInputEditOn } = this.state;
    return this.props.pieceCloseups.map((closeup, i) => {
      return (
        <div className="instrument-list" key={closeup.id}>
          {accessMode == 'edit' && (
            <div className="row">
              <span
                style={{ display: 'inline-block', float: 'right', color: 'red' }}
                className="glyphicon glyphicon-remove-circle"
                aria-hidden="true"
                onClick={() => this.onDelete(closeup)}
              />
              <span
                style={{ display: 'inline-block', float: 'right', marginRight: '10px', color: 'green' }}
                className="glyphicon glyphicon-edit"
                aria-hidden="true"
                onClick={() => this.Show(i)}
              />
            </div>
          )}
          {this.state.showFollowing === i && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Artist</label>
                </div>
                <div className="col-md-9">
                  <Typeahead
                    onChange={(selected) => {
                      let ArtistCloseup = this.state.CloseupToEdit;
                      ArtistCloseup['artist'] = selected;
                      this.setState({
                        ...this.state,
                        CloseupToEdit: ArtistCloseup,
                      });
                    }}
                    labelKey="name"
                    options={piecesInfo.members}
                    selected={this.state.CloseupToEdit.artist}
                    className={`form-group ${accessMode == 'view' ? 'disabled' : ''}`}
                    placeholder="Artist"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <label>Instrument</label>
                </div>
                <div className="col-md-9">
                  <Typeahead
                    onChange={(selected) => {
                      let CloseupInstrument = this.state.CloseupToEdit;
                      CloseupInstrument['instrument'] = selected;
                      this.setState({
                        CloseupToEdit: CloseupInstrument,
                      });
                    }}
                    ref={(typeahead) => (this.typeaheadIns = typeahead)}
                    labelKey="name"
                    options={instruments}
                    selected={this.state.CloseupToEdit.instrument}
                    className="form-group"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <label>Order</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="order"
                    placeholder="Order"
                    type="number"
                    value={this.state.CloseupToEdit.order}
                    onChange={(e) => {
                      let ArtistCloseup = this.state.CloseupToEdit;
                      ArtistCloseup['order'] = Number(e.target.value);
                      this.setState({
                        CloseupToEdit: ArtistCloseup,
                      });
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Video File</label>
                </div>
                <div className="col-md-9 radio-part">
                  <div className="currentFile">{this.state.CloseupToEdit.video}</div>

                  <div className="radio-selection row">
                    <div className="col-md-4">
                      <input
                        type="radio"
                        id="linkTypeVideoEdit"
                        name="videoTypeEdit"
                        checked={!closeupInputEditOn}
                        onChange={this.handleStateChange('closeupInputEditOn', false)}
                      />
                      &nbsp;
                      <label htmlFor="linkTypeVideoEdit">Link</label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="radio"
                        id="uploadTypeVideoEdit"
                        name="videoTypeEdit"
                        checked={closeupInputEditOn}
                        onChange={this.handleStateChange('closeupInputEditOn', true)}
                      />
                      &nbsp;
                      <label htmlFor="uploadTypeVideoEdit">Upload</label>
                    </div>
                  </div>

                  {closeupInputEditOn ? (
                    <form id="uploadCloseupsVideoEdit" method="post" encType="multipart/form-data">
                      <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                      <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                      <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                      <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                      <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                      <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                      <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                      <input id="uploadPieceCloseup" type="file" name="file" onChange={this.onFileChange} />
                    </form>
                  ) : (
                    <input
                      name="video"
                      placeholder="Video File"
                      value={this.state.CloseupToEdit.video}
                      onChange={(e) => this.handleEditStateChange('video', e.target.value)}
                      className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                      parentClass="form-group"
                    />
                  )}
                </div>
              </div>
              <div className="editButtons">
                <button
                  type="submit"
                  name="submit"
                  className="cus-btn-primary btn-sm submitButton"
                  style={{ margin: '10px 10px 10px 10px' }}
                  onClick={this.uploadVideo('editCloseup')}
                >
                  {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Save</span>}
                </button>
                <button
                  type="button"
                  name="button"
                  className="cus-btn-danger btn-sm submitButton"
                  style={{ margin: '10px 10px 10px 10px' }}
                  onClick={() => {
                    this.setState({
                      showFollowing: false,
                      CloseupToEdit: null,
                    });
                  }}
                >
                  {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Cancel</span>}
                </button>
              </div>
              <hr />
            </div>
          )}
          {this.state.showFollowing !== i && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Artist</label>
                </div>
                <div className="col-md-9">{closeup.artist}</div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Instrument</label>
                </div>
                <div className="col-md-9">{closeup.instrument}</div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Order</label>
                </div>
                <div className="col-md-9">{closeup.order}</div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Video File</label>
                </div>
                <div className="col-md-9">{closeup.video}</div>
              </div>
              <hr />
            </div>
          )}
        </div>
      );
    });
  };

  render() {
    const { isCreating, artistInfo, instruments, accessMode, piecesInfo } = this.props;
    const { awsKeys, disableUpload, closeupInputOn } = this.state;
    return (
      <section className="well create-instrument">
        {this.getPieceCloseups()}
        {accessMode == 'edit' && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <label>Artist</label>
              </div>
              <div className="col-md-9">
                <Typeahead
                  onChange={(selected) => {
                    this.setState({
                      ...this.state,
                      artist: selected,
                    });
                  }}
                  labelKey="name"
                  options={piecesInfo.members}
                  selected={this.state.artist}
                  className={`form-group ${accessMode == 'view' ? 'disabled' : ''}`}
                  placeholder="Artist"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <label>Instrument</label>
              </div>
              <div className="col-md-9">
                <Typeahead
                  onChange={(selected) => {
                    this.setState({
                      instrument: selected,
                    });
                  }}
                  ref={(typeahead) => (this.typeaheadIns = typeahead)}
                  labelKey="name"
                  options={instruments}
                  selected={this.state.instrument}
                  className="form-group"
                  placeholder="Instrument"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Order</label>
              </div>
              <div className="col-md-9">
                <input
                  name="order"
                  placeholder="Order"
                  type="number"
                  value={this.state.order}
                  onChange={(e) => {
                    this.setState({ order: Number(e.target.value) });
                  }}
                  className="form-control"
                  parentClass="form-group"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Video File</label>
              </div>

              <div className="col-md-9 radio-part">
                <div className="radio-selection row">
                  <div className="col-md-4">
                    <input
                      type="radio"
                      id="linkTypeVideo"
                      name="videoType"
                      checked={!closeupInputOn}
                      onChange={this.handleStateChange('closeupInputOn', false)}
                    />
                    &nbsp;
                    <label htmlFor="linkTypeVideo">Link</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="radio"
                      id="uploadTypeVideo"
                      name="videoType"
                      checked={closeupInputOn}
                      onChange={this.handleStateChange('closeupInputOn', true)}
                    />
                    &nbsp;
                    <label htmlFor="uploadTypeVideo">Upload</label>
                  </div>
                </div>

                {closeupInputOn ? (
                  <form id="uploadCloseupsVideo" method="post" encType="multipart/form-data">
                    <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                    <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                    <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                    <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                    <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                    <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                    <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                    <input id="uploadPieceCloseup" type="file" name="file" onChange={this.onFileChange} />
                  </form>
                ) : (
                  <input
                    name="video"
                    placeholder="Video File"
                    value={this.state.video_file}
                    onChange={this.onChangeSetToState('video_file')}
                    className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                    parentClass="form-group"
                  />
                )}
              </div>
            </div>
            <button
              type="submit"
              name="submit"
              className="cus-btn-primary btn-block btn-lg submitButton"
              onClick={this.uploadVideo('newCloseup')}
              disabled={isCreating || !(disableUpload && this.state.artist && this.state.instrument)}
            >
              {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Add Closeups</span>}
            </button>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createCloseups' })(CreateCloseups);
