import React from 'react';
import { Link } from 'react-router-dom';

import Artist from './Artist';

const Artists = props => {
  const { artists, deleteArtist } = props.artistsInfo;
  const { mode } = props;

  const getArtists = () => {
    return artists.map(artist => {
      const artistInfo = { deleteArtist, artist };
      return <Artist mode={mode} artistInfo={artistInfo} key={artist.id} />;
    });
  };

  return (
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Artists</h3>
        {mode == 'edit' && (
          <Link className="cus-btn-primary pull-right" to="/artist/create">
            Add Artist
          </Link>
        )}
      </div>
      <div class="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th class="t-10">Title</th>
              <th class="t-25">Description</th>
              <th class="t-15">Excerpt</th>
              <th class="t-15">Quote</th>
              <th class="t-10">Quotee</th>
              <th class="t-10">Created At</th>
              <th class="t-15">Action</th>
            </tr>
          </thead>
          <tbody>{getArtists()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Artists;
