import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { createFeature, getFeatureByID, editFeature } from '../../store/actions/feature/feature.action';
import CreateFeature from '../../components/Features/CreateFeature';

import PageLoader from '../../components/Common/PageLoader';

class CreateFeaturesContainer extends Component {
  state = {
    mode: 'CREATE',
    featureToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    this.setState(
      (prev) => ({
        ...prev,
        mode: params && params.id ? 'EDIT' : 'CREATE',
      }),
      () => {
        if (params.id) {
          this.getFeature(params.id);
        }
        this.setAccess();
      }
    );
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'features')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  getFeature = async (featureID) => {
    try {
      const featureToBeEdit = await this.props.getFeatureByID(featureID);
      this.setState((prevState) => {
        return {
          ...prevState,
          featureToBeEdit,
        };
      });
    } catch (error) {
      history.push('/features');
    }
  };

  render() {
    if (this.state.mode === 'EDIT' && !this.state.featureToBeEdit) {
      return <PageLoader />;
    }

    const { accessDenied, accessMode } = this.state;
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const { createFeature, editFeature } = this.props;

    return (
      <section>
        <CreateFeature
          accessMode={accessMode}
          onCreate={createFeature}
          onEdit={editFeature}
          initialValues={this.state.featureToBeEdit}
          mode={this.state.mode}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCreating: state.feature.isCreating,
    featureToBeEdit: state.feature.featureToBeEdit,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  createFeature,
  getFeatureByID,
  editFeature,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFeaturesContainer);
