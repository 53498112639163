import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  SET_CATEGORIES,
} from '../actions/auth/auth.actiontype';

export default function (state = {
  user: {}
}, action) {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        isProcessing: true,
      };
    case LOGIN_SUCCESS: {
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.accessToken,
        isProcessing: false,
      };
    }
    case LOGIN_ERROR:
      return {
        ...state,
        isProcessing: false,
          error: action.payload,
          user: null,
          accessToken: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
          accessToken: null,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        accessToken: null,
      };

    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      };
    }

    default:
      return state;
  }
}
