import React from 'react';

export default function SubscriptionCard(props) {
  const defFields = {
    name: 'Full Version Subscription',
    price: '0.00',
    months: '12',
    includes: ['Get music notes and artist interviews', 'Unleash your potential with part play.'],
    meta: {
      amount_color: '',
      card_bg_top_color: '',
      card_bg_bottom_color: '',
      card_title_color: '',
      left_button_bg_color: '',
      left_button_title_color: '',
      right_button_bg_color: '',
      right_button_title_color: '',
      bg_circle_top_left_color: '',
      bg_circle_bottom_right_color: '',
      summary_color: '',
    },
  };
  const { name, price, months, includes } = props;

  let meta = props.meta;
  if (meta == {}) {
    meta = { ...defFields.meta };
  }

  return (
    <div
      className="card"
      style={{
        background: `-webkit-linear-gradient(to bottom, ${meta.card_bg_top_color}, ${meta.card_bg_bottom_color})`,
        background: ` -moz-linear-gradient(to bottom, ${meta.card_bg_top_color}, ${meta.card_bg_bottom_color})`,
        background: `linear-gradient(to bottom, ${meta.card_bg_top_color}, ${meta.card_bg_bottom_color})`,
      }}
    >
      <div
        className="back-circle"
        style={{
          background: `-webkit-linear-gradient(to bottom right, ${meta.bg_circle_top_left_color}, ${meta.bg_circle_bottom_right_color})`,
          background: ` -moz-linear-gradient(to bottom right, ${meta.bg_circle_top_left_color}, ${meta.bg_circle_bottom_right_color})`,
          background: `linear-gradient(to bottom right, ${meta.bg_circle_top_left_color}, ${meta.bg_circle_bottom_right_color})`,
        }}
      ></div>
      <div>
        <div className="t1" style={{ color: meta.card_title_color }}>
          {name ? name : defFields.name}
        </div>
        <div className="cost" style={{ color: meta.amount_color }}>
          <span className="price">£ {price ? price : defFields.price}</span>
          <span className="dur">/{months ? months : defFields.months} months</span>
        </div>
        <ul className="t2" style={{ color: meta.summary_color }}>
          {includes
            ? includes.map((inc, ind) => {
                return <li key={ind}>{inc}</li>;
              })
            : defFields.includes.map((inc, ind) => {
                return <li key={ind}>{inc}</li>;
              })}
        </ul>
      </div>
      <div className="t3">
        <button
          href="#"
          className="button"
          style={{
            backgroundColor: meta.left_button_bg_color,
            color: meta.left_button_title_color,
          }}
        >
          Upgrade Now
        </button>
        <a href="#" className="compare" style={{ color: meta.right_button_title_color }}>
          Compare Plan
        </a>
      </div>
    </div>
  );
}
