import {
  GET_ARTISTS_START,
  GET_ARTISTS_SUCCESS,
  GET_ARTISTS_ERROR,
  CREATE_ARTIST_START,
  CREATE_ARTIST_SUCCESS,
  CREATE_ARTIST_ERROR,
  DELETE_ARTIST_START,
  DELETE_ARTIST_SUCCESS,
  DELETE_ARTIST_ERROR,
  EDIT_ARTIST_START,
  EDIT_ARTIST_SUCCESS,
  EDIT_ARTIST_ERROR,
  SET_CURRENT_ARTIST,
  DESTROY_CURRENT_ARTIST,
} from '../actions/artist/artist.actiontype';

export default function(state = { list: [], currentArtist: null }, action) {
  switch (action.type) {
    case GET_ARTISTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ARTISTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_ARTISTS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_ARTIST_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_ARTIST_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: [...state.list, action.payload],
      };
    case CREATE_ARTIST_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_ARTIST_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_ARTIST_SUCCESS:
      const newList = state.list.filter(artist => artist.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
        list: newList,
      };
    case DELETE_ARTIST_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    case EDIT_ARTIST_START:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_ARTIST_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: state.list.map(artist => {
          if (artist.id === action.payload.id) {
            return action.payload;
          }
          return artist;
        }),
      };
    case EDIT_ARTIST_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case SET_CURRENT_ARTIST:
      return {
        ...state,
        currentArtist: action.payload,
      };
    case DESTROY_CURRENT_ARTIST:
      return {
        ...state,
        currentArtist: action.payload,
      };
    default:
      return state;
  }
}
