import React, { Component } from 'react';
import { connect } from 'react-redux';

import ListUsers from '../../components/Users/ListUsers';
import { getUsers } from '../../store/actions/users/users.action';
import PageLoader from '../../components/Common/PageLoader';
import { getSubscriptions } from '../../store/actions/subscription/subscription.action';

import '../../styles/users.css';

class UsersListContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    this.props.getUsers(1, 10e9);
    this.setAccess();
    this.props.getSubscriptions();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'users')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { getUsers, users, isFetching, pagination, subscriptions_list } = this.props;
    const { accessDenied, mode } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    return (
      <section id="users-list">
        <ListUsers mode={mode} usersInfo={users} subscriptions_list={subscriptions_list} />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users.list,
    isFetching: state.users.isFetching,
    categories: state.auth.categories,
    pagination: state.users.pagination,
    subscriptions_list: state.subscription.list,
  };
};
const mapDispatchToProps = {
  getUsers,
  getSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersListContainer);
