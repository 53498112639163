import React, {Component} from 'react';
import '../../styles/common.css';

export default class Tabs extends Component{
    
  constructor(props){
      super(props);
      this.state={
        selected:0
      }
    }

    componentDidMount(){
      this.handleSelection(0);
    }
    
    handleSelection=(selected)=>e=>{
        e.stopPropagation();
        this.setState(p=>({
            ...p,
            selected
        }))
        if(this.props.setCurrentTab) this.props.setCurrentTab(selected);
    }

    render(){    
      const { titles,components } = this.props;

      return (
        <div id="tabs-component">
            <div className="titles">
            {
                titles.map((name,ind)=>(
                    <div className={this.state.selected===ind ? 'selected':''} key={ind} onClick={this.handleSelection(ind)}>{name}</div>
                ))
            }
            </div>
            <div className="components">
                <div>{components[this.state.selected]}</div>
            </div>
        </div>
    );
    }
    
}
