import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_INSTRUMENTS_START,
  GET_INSTRUMENTS_SUCCESS,
  GET_INSTRUMENTS_ERROR,
  CREATE_INSTRUMENT_START,
  CREATE_INSTRUMENT_SUCCESS,
  CREATE_INSTRUMENT_ERROR,
  DELETE_INSTRUMENT_START,
  DELETE_INSTRUMENT_SUCCESS,
  DELETE_INSTRUMENT_ERROR,
  EDIT_INSTRUMENT_START,
  EDIT_INSTRUMENT_SUCCESS,
  EDIT_INSTRUMENT_ERROR,
} from './instrument.actiontype';

import { getError } from '../../../utils/common.util';

export const getInstruments = () => async (dispatch) => {
  try {
    dispatch({ type: GET_INSTRUMENTS_START });
    const instruments = await axiosService.get('/pieces/instruments');
    dispatch({ type: GET_INSTRUMENTS_SUCCESS, payload: instruments });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_INSTRUMENTS_ERROR });
  }
};

export const getInstrumentByID = (instrumentId) => async (dispatch) => {
  try {
    const instrument = await axiosService.get(`/pieces/instruments/${instrumentId}`);
    return Promise.resolve(instrument);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const createInstrument = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_INSTRUMENT_START });
    const instrument = await axiosService.post('/pieces/instruments', fields);
    toast.success('Successfully created.');
    dispatch({ type: CREATE_INSTRUMENT_SUCCESS, payload: instrument });
    history.push('/instruments');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_INSTRUMENT_ERROR });
  }
};

export const deleteInstrument = (instrument) => async (dispatch) => {
  if (!window.confirm('Are you sure to delete this instrument ?')) return;
  try {
    dispatch({ type: DELETE_INSTRUMENT_START });
    const deletedInstrument = await axiosService.delete(`/pieces/instruments/${instrument.id}`);
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_INSTRUMENT_SUCCESS, payload: deletedInstrument });
  } catch (error) {
    if (error.code == 500) toast.error('Instrument already attached to a piece. Deletion restricted.');
    dispatch({ type: DELETE_INSTRUMENT_ERROR });
  }
};

export const editInstrument = (instrument) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_INSTRUMENT_START });
    const newInstrument = await axiosService.put(`/pieces/instruments/${instrument.id}`, instrument);
    toast.success('Successfully saved.');
    dispatch({ type: EDIT_INSTRUMENT_SUCCESS, payload: newInstrument });
    history.push('/instruments');
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_INSTRUMENT_ERROR });
  }
};
