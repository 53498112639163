import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Member = props => {
  const { deleteMember, member } = props.memberInfo;
  const { mode } = props;
  return (
    <tr>
      <td>{member.name}</td>
      <td>{member.biography}</td>
      <td>{member.photo}</td>
      <td>{moment(member.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        {mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteMember(member)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/member/edit/${member.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/member/view/${member.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Member;
