import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Admin = (props) => {
  const { admin, deleteAdmin } = props;

  const confirmDelete = () => {
    deleteAdmin(admin);
  };

  return (
    <tr>
      <td>{admin.name}</td>
      <td>{admin.email}</td>
      <td>{admin.mobile}</td>
      <td>{moment(admin.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>{admin.role}</td>
      <td>
        <button className="cus-btn-danger mr-15" onClick={confirmDelete}>
          Delete
        </button>
        <Link className="cus-btn-primary" to={`/admins/edit/${admin.id}`}>
          Edit
        </Link>
      </td>
    </tr>
  );
};

export default Admin;
