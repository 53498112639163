import {
  GET_FEATURES_START,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_ERROR,
  CREATE_FEATURE_START,
  CREATE_FEATURE_SUCCESS,
  CREATE_FEATURE_ERROR,
  DELETE_FEATURE_START,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_ERROR,
  EDIT_FEATURE_START,
  EDIT_FEATURE_SUCCESS,
  EDIT_FEATURE_ERROR,
} from '../actions/feature/feature.actiontype';

export default function (state = { list: [], isCreating: false, isFetching: false }, action) {
  switch (action.type) {
    case GET_FEATURES_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_FEATURES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_FEATURES_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_FEATURE_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_FEATURE_SUCCESS:
      return {
        ...state,
        isCreating: false,
      };
    case CREATE_FEATURE_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case EDIT_FEATURE_START:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_FEATURE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: state.list.map((feature) => {
          if (feature.id === action.payload.id) {
            return action.payload;
          }
          return feature;
        }),
      };
    case EDIT_FEATURE_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_FEATURE_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_FEATURE_SUCCESS:
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_FEATURE_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
}
