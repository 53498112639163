import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

import { required } from '../../utils/validation.util';
import { renderInput } from '../../utils/input.util';

import RichTextEditor from 'react-rte';
class CreateArtist extends Component {
  state = {
    photo: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    photoUploaded: false,
    isUploading: false,
    title: '',
    description: RichTextEditor.createEmptyValue(),
    coming_soon: RichTextEditor.createEmptyValue(),
    excerpt: '',
    quote: '',
    quotee: '',
  };

  componentDidMount() {
    if (this.props.artistInfo.mode === 'EDIT') {
      const {
        title,
        description,
        excerpt,
        quote,
        quotee,
        photoUploaded,
        photo,
        id,
        coming_soon,
      } = this.props.artistInfo.initialValues;
      this.setState(prevState => ({
        ...prevState,
        title,
        description: RichTextEditor.createValueFromString(description, 'html'),
        coming_soon: RichTextEditor.createValueFromString(coming_soon, 'html'),
        excerpt,
        quote,
        quotee,
        photoUploaded,
        photo,
        id,
        photoUploaded: true,
      }));
    }
  }

  onChangeSetToState = stateKey => e => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = () => {
    const {
      title,
      description,
      descriptionPost,
      excerpt,
      quote,
      quotee,
      photoUploaded,
      photo,
      id,
      coming_soon,
      comingSoonPost,
    } = this.state;

    if (this.props.artistInfo.mode === 'EDIT') {
      this.props.artistInfo.editArtist({
        title,
        description: descriptionPost,
        coming_soon: comingSoonPost,
        excerpt,
        quote,
        quotee,
        photoUploaded,
        photo,
        id,
      });
      return;
    }

    this.props.artistInfo.createArtist({
      title,
      description: descriptionPost,
      coming_soon: comingSoonPost,
      excerpt,
      quote,
      quotee,
      photoUploaded,
      photo,
    });
  };
  onFileChange = async eve => {
    this.setState(prevState => ({
      ...prevState,
      photoUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.artistInfo.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `artists/images/${fileName}` });
    this.setState(prevState => ({
      ...prevState,
      file,
      photo: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
  };

  uploadPhoto = async eve => {
    eve.preventDefault();
    if (!this.state.awsKeys.action) {
      toast.error('please try again.');
    }
    var form = document.querySelector('form');
    var data = new FormData(form);
    try {
      this.setState(prevState => ({
        ...prevState,
        isUploading: true,
      }));
      await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
      toast.success('Audio uploaded successfully.');
      this.setState(prevState => ({
        ...prevState,
        photoUploaded: true,
        isUploading: false,
      }));
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  onChange = label => e => {
    if (label === 'description') {
      this.setState({ description: e, descriptionPost: e.toString('html') });
    }
    if (label === 'coming_soon') {
      this.setState({ coming_soon: e, comingSoonPost: e.toString('html') });
    }
  };
  getDisableState = () => {
    const { title, description, excerpt, quote, quotee, photoUploaded } = this.state;
    const { isCreating } = this.props.artistInfo;
    return isCreating || !(title && description && excerpt && photoUploaded);
  };

  render() {
    const { isCreating, mode } = this.props.artistInfo;
    const { accessMode } = this.props;
    const { awsKeys, disableUpload, isUploading } = this.state;
    return (
      <section className="well create-artist">
        <div className="row">
          <div className="col-md-3">
            <label>Title</label>
          </div>
          <div className="col-md-9">
            <input
              name="title"
              placeholder="Title"
              type="text"
              value={this.state.title}
              onChange={this.onChangeSetToState('title')}
              validate={[required]}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label>Description</label>
          </div>
          <div className="col-md-9">
            <RichTextEditor
              readOnly={accessMode == 'view'}
              value={this.state.description}
              onChange={this.onChange('description')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label>Coming Soon</label>
          </div>
          <div className="col-md-9">
            <RichTextEditor
              readOnly={accessMode == 'view'}
              value={this.state.coming_soon}
              onChange={this.onChange('coming_soon')}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label>Excerpt</label>
          </div>
          <div className="col-md-9">
            <input
              name="excerpt"
              placeholder="Excerpt"
              type="text"
              value={this.state.excerpt}
              onChange={this.onChangeSetToState('excerpt')}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label>Quote</label>
          </div>
          <div className="col-md-9">
            <input
              name="quote"
              placeholder="Quote"
              type="text"
              value={this.state.quote}
              onChange={this.onChangeSetToState('quote')}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label>Quotee</label>
          </div>
          <div className="col-md-9">
            <input
              name="quotee"
              placeholder="Quotee"
              type="text"
              value={this.state.quotee}
              onChange={this.onChangeSetToState('quotee')}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
            />
          </div>
        </div>
        {mode === 'EDIT' ? (
          <div className="row">
            <div className="col-md-3">
              <label>Photo</label>
            </div>
            <div className="col-md-9">{this.state.photo}</div>
          </div>
        ) : null}

        {accessMode == 'edit' && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <label>Photo</label>
              </div>
              <div className="col-md-9">
                <form method="post" encType="multipart/form-data" onSubmit={this.uploadPhoto}>
                  <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                  <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                  <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                  <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                  <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                  <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                  <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                  <input type="file" name="file" onChange={this.onFileChange} /> <br />
                  <input
                    type="submit"
                    name="submit"
                    value="Upload to Amazon S3"
                    className="btn btn-default btn-upload"
                    disabled={!disableUpload}
                  />
                </form>
              </div>
            </div>

            <div className="create-piece__btn-container">
              <button
                className="cus-btn-primary btn-block btn-lg"
                type="submit"
                onClick={this.onSubmit}
                disabled={this.getDisableState()}
              >
                {isCreating || isUploading ? <span className="spinner" /> : <span>Save</span>}
              </button>
            </div>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createArtist' })(CreateArtist);
