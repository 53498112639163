import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { getPieces } from '../../store/actions/piece/piece.action';
import { getInstruments } from '../../store/actions/instrument/instrument.action';

import { getAwsKey } from '../../store/actions/piece-video/piece-video.action';
import { createPieceInstrument } from '../../store/actions/piece-instrument/piece-instrument.action';

import CreatePieceInstrument from '../../components/PiecesInstrument/CreatePieceInstrument';

import PageLoader from '../../components/Common/PageLoader';

class CreatePiecesContainer extends Component {
  state = {
    startRender: false,
  };
  componentDidMount() {
    this.getPieceAttribute();
  }

  getPieceAttribute = () => {
    const { getPieces, getInstruments } = this.props;
    Promise.all([getPieces(), getInstruments()])
      .then(data => {
        this.setState(prevState => {
          return {
            ...prevState,
            startRender: true,
          };
        });
      })
      .catch(error => {
        history.push('/pieces-instrument');
      });
  };

  render() {
    if (!this.state.startRender) {
      return <PageLoader />;
    }
    const { createPieceInstrument, pieces, isCreating, getAwsKey, instruments } = this.props;
    const pieceInstrumentInfo = { pieces, instruments, isCreating };
    return (
      <section>
        <CreatePieceInstrument
          onCreate={createPieceInstrument}
          pieceInstrumentInfo={pieceInstrumentInfo}
          getAwsKey={getAwsKey}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.pieceVideo.isCreating,
    pieces: state.piece.list,
    instruments: state.instrument.list,
  };
};
const mapDispatchToProps = {
  getPieces,
  createPieceInstrument,
  getAwsKey,
  getInstruments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePiecesContainer);
