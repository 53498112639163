import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Composer = props => {
  const { deleteComposer, composer } = props.composerInfo;
  const { mode } = props;
  return (
    <tr>
      <td>{composer.id}</td>
      <td>{composer.name}</td>
      <td>{composer.description}</td>
      <td>{moment(composer.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        {mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteComposer(composer)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/composer/edit/${composer.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/composer/view/${composer.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Composer;
