import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Instrument = props => {
  const { deleteInstrument, instrument } = props.instrumentInfo;
  const { mode } = props;
  return (
    <tr>
      <td>{instrument.id}</td>
      <td>{instrument.name}</td>
      <td>{instrument.description}</td>
      <td>{instrument.sequence}</td>
      <td>{moment(instrument.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        {mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteInstrument(instrument)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/instrument/edit/${instrument.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/instrument/view/${instrument.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Instrument;
