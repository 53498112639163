import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Piece from './Piece';
import FilterResults from 'react-filter-search';
import moment from 'moment';
import ReactPaginate from 'react-js-pagination';
import SinglePieceRow from './SinglePieceRow';
import SingleDetailRow from './SingleDetailRow';
import { getHomePieceId } from '../../store/actions/piece/piece.action';
import axiosService from '../../inits/axios';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import PageLoader from '../../components/Common/PageLoader';
import TabComponent from '../Common/Tabs';

class ListPieces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.pieceInfo.pieces,
      homePieceId: null,
      value: '',
      sort: {
        column: 'title',
        direction: 'desc',
      },
      currentCategory:this.props.pieces_categories[0],
      initialFilterApplied:false,
      page: {
        count: 1,
        current: 1,
        size: 20,
        dataL: 1,
      },

      composers: [],
      eras: [],
      difficulties: [],
      subscriptions: [],
      instruments: [],
      artists: [],

      filterParams: {
        selectedComposer: '',
        selectedInstrument: '',
        selectedDifficulty: '',
        selectedOrder: '',
        selectedEra: '',
        selectedSubscription: '',
        selectedArtist: '',
      },
    };
  }

  onSort = (column, dir) => {
    var direction = 'asc';
    if (column === this.state.sort.column) {
      direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
    }
    if (dir) {
      direction = dir;
    }
    const sortedData = this.state.data.sort((a, b) => {
      const nameA = eval('a.' + column + '.toUpperCase()'); // ignore upper and lowercase
      const nameB = eval('b.' + column + '.toUpperCase()'); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    if (direction === 'desc') {
      sortedData.reverse();
    }

    this.setState((prev) => ({
      ...prev,
      data: sortedData,
      sort: {
        column,
        direction: direction,
      },
      onSort: this.onSort,
    }));
  };

  componentDidUpdate = (prev) => {
    
    if (JSON.stringify(prev.pieceInfo.pieces) != JSON.stringify(this.props.pieceInfo.pieces)) {
      this.setState(
        (prev) => ({ 
          ...prev, 
          data: this.props.pieceInfo.pieces
        }),
        () => {
          this.onSort('title', 'asc');
        }
      );
    }
  };

  getHomePiece = () => {
    this.props.homePieceMethods.getHomePieceId();
  };

  setHomePiece = (piece_id) => {
    this.props.homePieceMethods.setHomePieceId({ piece_id });
  };

  componentDidMount() {
    this.onSort('title');
    document.querySelector('#pieces .box-body').classList.add('scaleFadeIn');
    this.getHomePiece();

    this.getFilterElements();
  }

  getFilterElements = async () => {
    try {
      const cdiInfo = await Promise.all([
        axiosService.get('/pieces/composers?order_by=name asc'),
        axiosService.get('/pieces/difficulties?order_by=name asc'),
        axiosService.get('/pieces/instruments?order_by=name asc'),
        axiosService.get('/pieces/eras?order_by=name asc'),
        axiosService.get('/subscriptions'),
        axiosService.get('/artists'),
      ]);

      this.setState((prev) => ({
        ...prev,
        composers: cdiInfo[0],
        difficulties: cdiInfo[1].sort((a, b) => {
          if (a.sequence > b.sequence) {
            return 1;
          } else {
            return -1;
          }
        }),
        instruments: cdiInfo[2].sort((a, b) => {
          if (a.sequence > b.sequence) {
            return 1;
          } else {
            return -1;
          }
        }),
        eras: cdiInfo[3].sort((a, b) => {
          if (a.sequence > b.sequence) {
            return 1;
          } else {
            return -1;
          }
        }),
        subscriptions: cdiInfo[4].sort((a, b) => {
          return parseFloat(a.price) < parseFloat(b.price);
        }),
        artists: cdiInfo[5].sort((a, b) => {
          return parseFloat(a.sequence) > parseFloat(b.sequence);
        }),
        isLoading: false,
      }));
    } catch (err) {}
  };

  updatePiece = (e, obj, orig) => {
    let pieces = JSON.parse(JSON.stringify(this.state.data)),
      latestCount = 0,
      featuredCount = 0;

    pieces.forEach(({ featured, latest }) => {
      latestCount += latest;
      featuredCount += featured;
    });

    if (latestCount == 10 && obj['latest']) {
      toast.error(
        'Latest piece count reached 10. Please unselect one of the piece which is marked latest to mark this one.'
      );
      e.target.checked = !e.target.checked;
      return;
    }

    if (featuredCount == 10 && obj['featured']) {
      toast.error(
        'Featured piece count reached 10. Please unselect one of the piece which is marked featured to mark this one.'
      );
      e.target.checked = !e.target.checked;
      return;
    }

    pieces = pieces.map((piece) => {
      if (piece.id == obj.id) {
        if (obj['featured'] != undefined) piece.featured = e.target.checked;
        if (obj['latest'] != undefined) piece.latest = e.target.checked;
      }
      return piece;
    });

    this.props.editPiece(obj, orig);
    this.setState((prev) => ({ ...prev, data: pieces }));
  };

  handlePageChange = (current) => {
    this.setState((prev) => ({
      ...prev,
      page: {
        ...prev.page,
        current,
      },
    }));
  };

  setFilter = (key) => (e) => {
    e.stopPropagation();
    e.preventDefault();

    let value = e.target.value;
    this.setState(
      (prev) => ({
        ...prev,
        filterParams: {
          ...prev.filterParams,
          [key]: value,
        },
      }),
      () => {
        this.filterPieces();
      }
    );
  };

  handleState(k,v){
    this.setState(p=>({
      ...p,
      [k]:v
    }),()=>{
      if(k==="currentCategory") this.filterPieces();
    })
  }

  filterPieces = () => {
    let { filterParams, page } = this.state;

    let data = this.props.pieceInfo.pieces.filter(
      ({ artist_id, composer_id, difficulty_id, era_id, instruments, subscriptions, category,title }) => {
        
        return (
          category === this.state.currentCategory &&
          (!filterParams.selectedArtist.length || artist_id == filterParams.selectedArtist) &&
          (!filterParams.selectedComposer.length || composer_id == filterParams.selectedComposer) &&
          (!filterParams.selectedDifficulty.length || difficulty_id == filterParams.selectedDifficulty) &&
          (!filterParams.selectedEra.length || era_id == filterParams.selectedEra) &&
          (!filterParams.selectedInstrument.length ||
            instruments.map(({ instrument_id }) => instrument_id).includes(filterParams.selectedInstrument)) &&
          (!filterParams.selectedSubscription.length ||
            subscriptions.map(({ id }) => id).includes(filterParams.selectedSubscription))
        );
        
      }
    );

    this.setState((prev) => ({ ...prev, data, page: { ...page, current: 1 } }));
  }

  renderFilters = () => {
    const { eras, difficulties, composers, instruments, artists, subscriptions } = this.state;
    return (
      <React.Fragment>
        <select
          onChange={this.setFilter('selectedComposer')}
          className="composer-select"
          value={this.state.filterParams.selectedComposer}
        >
          <option value={''}>Composer</option>
          {composers.map((data) => (
            <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
        </select>

        <select
          onChange={this.setFilter('selectedArtist')}
          className="artist-select"
          value={this.state.filterParams.selectedArtist}
        >
          <option value={''}>Artist</option>
          {artists.map((data) => (
            <option key={data.id} value={data.id}>
              {data.title}
            </option>
          ))}
        </select>

        <select
          onChange={this.setFilter('selectedDifficulty')}
          className="difficulty-select"
          value={this.state.filterParams.selectedDifficulty}
        >
          <option value={''}>Difficulty</option>
          {difficulties.map((data) => (
            <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
        </select>

        <select
          onChange={this.setFilter('selectedEra')}
          className="era-select"
          value={this.state.filterParams.selectedEra}
        >
          <option value={''}>Era</option>
          {eras.map((data) => (
            <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
        </select>

        <select
          onChange={this.setFilter('selectedInstrument')}
          className="era-select"
          value={this.state.filterParams.selectedInstrument}
        >
          <option value={''}>Instrument</option>
          {instruments.map((data) => (
            <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
        </select>

        <select
          onChange={this.setFilter('selectedSubscription')}
          className="subscription-select"
          value={this.state.filterParams.selectedSubscription}
        >
          <option value={''}>Subscription</option>
          {subscriptions.map((data) => (
            <option key={data.id} value={data.id}>
              {data.name}
            </option>
          ))}
        </select>
      </React.Fragment>
    );
  };


  tabSwitch = (name) => {
    if (name == 'Details') {
      if (!(this.state.data[0].interviews && this.state.data[0].closeups)) {
        this.props.getPiecesAddOns();
      }
    }
  };


  singleTable = () => {
    const { subscriptions, pieceSubscriptionMethods } = this.props;
    const { deletePiece, getPieceByID } = this.props.pieceInfo;
    const { value, page } = this.state;

    
    if(!this.state.initialFilterApplied){
      this.setState(p=>({
        ...p,
        initialFilterApplied:true
      }))
     this.filterPieces();
    }

    return (
      <Tabs defaultActiveKey="Overview" transition={false} id="noanim-tab-example" onSelect={this.tabSwitch}>
        <Tab eventKey="Overview" title="Overview">
          <table className="table table-responsive table-hover">
            <thead>
              <tr>
                <th>S. No.</th>
                <th className="t-15" style={{ cursor: 'pointer' }} onClick={(e) => this.state.onSort('title', e)}>
                  Name {this.state.sort.column === 'title' ? '-' + this.state.sort.direction : ''}
                </th>
                <th className="t-25">Description</th>
                <th className="t-15">Product ID for Google</th>
                <th
                  className="t-15"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => this.state.onSort('composer.name', e)}
                >
                  Composer {this.state.sort.column === 'composer.name' ? '-' + this.state.sort.direction : ''}
                </th>
                <th
                  className="t-15"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => this.state.onSort('artist.title', e)}
                >
                  Artist {this.state.sort.column === 'artist.title' ? '-' + this.state.sort.direction : ''}
                </th>
                <th className="t-15">Subscriptions</th>
                <th className="t-10">Price</th>
                <th className="t-10">Latest ?</th>
                <th className="t-10">Featured ?</th>
                <th className="t-15">Action</th>
              </tr>
            </thead>

            <FilterResults
              value={value}
              data={this.state.data}
              renderResults={(results) => {
                return (
                  <tbody
                    onLoad={() => {
                      document.querySelector('#pieces .box-body').classList.add('scaleFadeIn');
                    }}
                  >
                    {results.slice((page.current - 1) * page.size, page.current * page.size).map((piece, ind) => {
                      return (
                        <SinglePieceRow
                          key={ind}
                          ind={ind}
                          page={page}
                          piece={piece}
                          subscriptions={subscriptions}
                          methods={{ deletePiece, updatePiece: this.updatePiece, getPieceByID }}
                          mode={this.mode}
                          pieceSubscriptionMethods={pieceSubscriptionMethods}
                        />
                      );
                    })}
                  </tbody>
                );
              }}
            />
          </table>
        </Tab>
        <Tab eventKey="Details" title="Details">
          <table className="table table-responsive table-hover">
            <thead>
              <tr>
                <th>S. No.</th>
                <th className="t-15">Name</th>
                <th className="t-25">Instruments</th>
                <th className="t-15">Videos</th>
                <th className="t-15">Interviews</th>
                <th className="t-15">Closeups</th>
                <th className="t-15">Action</th>
              </tr>
            </thead>
            {this.state.data[0] && this.state.data[0].interviews && this.state.data[0].closeups ? (
              <FilterResults
                value={value}
                data={this.state.data}
                renderResults={(results) => {
                  return (
                    <tbody
                      onLoad={() => {
                        document.querySelector('#pieces .box-body').classList.add('scaleFadeIn');
                      }}
                    >
                      {results.slice((page.current - 1) * page.size, page.current * page.size).map((piece, ind) => {
                        return (
                          <SingleDetailRow
                            page={page}
                            piece={piece}
                            ind={ind}
                            methods={{ deletePiece, updatePiece: this.updatePiece, getPieceByID }}
                            mode={this.mode}
                          />
                        );
                      })}
                    </tbody>
                  );
                }}
              />
            ) : (
              <tr>
                <PageLoader />
              </tr>
            )}
          </table>
        </Tab>
      </Tabs>
    )
  }


  mode = this.props.mode;

  render() {
    
    const { pieces_categories } = this.props;
    const { homePieceId } = this.props.homePieceMethods;
    const { pieces } = this.props.pieceInfo;
    const { page } = this.state;

    return (
      <div
        id="pieces"
        className="box"
        onLoad={() => {
          document.querySelector('#pieces').classList.add('scaleFadeIn');
        }}
      >
        <div className="box-header with-border">
          <h3 className="box-title">Pieces</h3>

          {this.mode == 'edit' && (
            <Link className="cus-btn-primary pull-right" to="/piece/create">
              Add Piece
            </Link>
          )}
        </div>
        <div className="search-cont">
          <div>
            <label>Home page piece</label>
            <br />
            {homePieceId && (
              <select value={homePieceId} onChange={(e) => this.setHomePiece(e.target.value)}>
                {pieces.map(({ id, title }) => {
                  return (
                    <option key={id} value={id}>
                      {title}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        </div>

        <div className="piece-filters">{this.renderFilters()}</div>

        <ReactPaginate
          activePage={page.current}
          itemsCountPerPage={page.count}
          totalItemsCount={Math.ceil(this.state.data.length / page.size)}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />

        <div className="box-body">
          <TabComponent
            titles={pieces_categories}
            components={pieces_categories.map((c)=>this.singleTable(c))}
            setCurrentTab={(i)=>this.handleState('currentCategory',pieces_categories[i])}
          />
          
          
        </div>
        <ReactPaginate
          activePage={page.current}
          itemsCountPerPage={page.count}
          totalItemsCount={Math.ceil(this.state.data.length / page.size)}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange.bind(this)}
        />
      </div>
    );
  }
}

export default ListPieces;
