import {
  GET_ADMINS_START,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_ERROR,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  SET_CURRENT_ADMIN,
  DESTROY_CURRENT_ADMIN,
  CREATE_ADMIN_START,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_ERROR,
  EDIT_ADMIN_START,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_ERROR,
  DELETE_ADMIN_START,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_ERROR,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
} from '../actions/admins/admins.actiontype';

export default function(
  state = {
    admins: [],
    users: [],
    isFetching: false,
    isCreating: false,
    isDeleting: false,
    currentAdmin: {},
    currentUser: {},
    categories: [],
  },
  action
) {
  switch (action.type) {
    case GET_ADMINS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        admins: action.payload,
      };

    case GET_ADMINS_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case GET_USERS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action.payload,
      };
    case GET_USERS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_ADMIN_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_ADMIN_SUCCESS:
      return {
        ...state,
        isCreating: false,
        admins: [...state.admins, action.payload],
      };
    case CREATE_ADMIN_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_ADMIN_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_ADMIN_SUCCESS:
      const newList = state.admins.filter(artist => artist.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
        admins: newList,
      };
    case DELETE_ADMIN_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    case EDIT_ADMIN_START:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_ADMIN_SUCCESS:
      return {
        ...state,
        isCreating: false,
        admins: state.admins.map(artist => {
          if (artist.id === action.payload.id) {
            return action.payload;
          }
          return artist;
        }),
      };
    case EDIT_ADMIN_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case SET_CURRENT_ADMIN:
      return {
        ...state,
        currentAdmin: action.payload,
      };
    case DESTROY_CURRENT_ADMIN:
      return {
        ...state,
        currentAdmin: action.payload,
      };

    case GET_CATEGORIES_START:
      return {
        ...state,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };

    case GET_CATEGORIES_ERROR:
      return {
        ...state,
        categories: [],
      };
    default:
      return state;
  }
}
