import {
  toast
} from 'react-toastify';

import {
  setLocalStorage,
  removeLocalStorage
} from '../../../utils/web-storage';
import axiosService from '../../../inits/axios';
import history from '../../../inits/history';
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  SET_CATEGORIES
} from './auth.actiontype';
import logs from '../../../components/Common/HistoryLogs';



export const login = ({
  userCredential
}) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOGIN_START,
    });
    const tokenInfo = await axiosService.post('/login', userCredential);
    setLocalStorage('accessToken', tokenInfo['token']);
    await dispatch(getCurrentUser(tokenInfo['token']));
    history.push('/');
    toast.success('You are successfully logged in.');
  } catch (error) {
    const errorMssg = error.errors ? error.errors.email[0] : error;
    removeLocalStorage('accessToken');
    dispatch({
      type: LOGIN_ERROR,
      payload: error,
    });
    toast.error(errorMssg);
  }
};

export const getCurrentUser = (accessToken) => async (dispatch, getState) => {
  try {
    const userInfo = await axiosService.get('/profile');
    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user: userInfo,
        accessToken,
      },
    });
    if (userInfo.role == 'user') throw 'User login not allowed';

    logs.pieces = {
      ...logs.pieces,
      "Username": userInfo.name,
      "Email": userInfo.email,
    }

    return Promise.resolve(userInfo);
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const removeUser = () => async (dispatch, getState) => {
  removeLocalStorage('accessToken');
  dispatch({
    type: LOGOUT_SUCCESS,
  });
};

export const setCategories = (data) => async (dispatch, getState) => {
  dispatch({
    type: SET_CATEGORIES,
    payload: data,
  });
};
