import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../inits/history';
import axiosService from '../../inits/axios';
import PageLoader from '../../components/Common/PageLoader';
import CreateFaq from '../../components/Faqs/CreateFaq';
import { toast } from 'react-toastify';

class CreateFaqsContainer extends Component {
  state = {
    mode: 'CREATE',
    faqToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };

  componentDidMount() {
    const { params } = this.props.match;

    if (params.id) {
      this.getFaq(params.id);
    }
    this.setAccess();
    this.setState({
      mode: params.mode == 'edit' ? 'EDIT' : 'CREATE',
    });
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'faqs')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  getFaq = async (faqId) => {
    try {
      const faqToBeEdit = await axiosService.get(`/faqs/${faqId}`, {}, { 'Content-Type': 'application/json' });
      this.setState((prevState) => {
        return {
          ...prevState,
          faqToBeEdit,
        };
      });
    } catch (error) {
      history.push('/faqs');
    }
  };
  onEdit = async (faq) => {
    try {
      const faqToBeEdit = await axiosService.put(`/faqs/${faq.id}`, faq);

      this.setState((prevState) => {
        return {
          ...prevState,
          faqToBeEdit,
        };
      });

      toast.success('Successfully updated');
    } catch (error) {
      history.push('/faqs');
    }
  };
  onCreate = async (faqInfo) => {
    try {
      await axiosService.post(`/faqs`, faqInfo);
      history.push('/faqs');
    } catch (error) {
      history.push('/faqs');
    }
  };

  render() {
    if (this.state.mode === 'EDIT' && !this.state.faqToBeEdit) {
      return <PageLoader />;
    }

    const { accessMode, accessDenied } = this.state;

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    return (
      <section>
        <CreateFaq
          accessMode={accessMode}
          onCreate={this.onCreate}
          onEdit={this.onEdit}
          initialValues={this.state.faqToBeEdit}
          mode={this.state.mode}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFaqsContainer);
