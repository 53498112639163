import React, { Component } from 'react';
import { getAwsKey } from '../../store/actions/piece-video/piece-video.action';
import { toast } from 'react-toastify';
import GPassword from 'generate-password';

const initialState = {
  name: '',
  email: '',
  mobile: '',
  password: '',
  retypePassword: '',
  photo: '',
  awsKeys: {},
  photoUpload: true,
  disableUpload: false,
  mode: 'CREATE',
  forgotClick: false,
  assigned_categories: [],
  systemPassword: null,
};

export default class CreateAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
    };
  }

  async componentDidMount() {
    const { params } = this.props.match;
    const mode = params && params.id ? 'EDIT' : 'CREATE';
    this.props.actions.getCategories();
    if (mode == 'EDIT') {
      this.setState({
        ...this.state,
        mode: 'EDIT',
      });
      let data = await this.props.actions.getAdminByID(params.id);
      data.assigned_categories = data.assigned_categories.split(',');

      this.setState({
        ...this.state,
        ...data,
      });
    } else {
      this.setState({ ...this.state, mode });
    }
  }

  onChangeSetToState = (field) => (e) => {
    let updates;
    if (this.state.systemPassword && (field == 'password' || field == 'retypePassword')) {
      updates = {
        password: '',
        retypePassword: '',
        systemPassword: null,
      };
    } else {
      updates = { [field]: e.currentTarget.value };
    }
    this.setState({
      ...updates,
    });
  };

  onFileChange = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      photoUpload: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `audio/${fileName}` });
    this.setState((prevState) => ({
      ...prevState,
      file,
      photo: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
  };

  updateAdmin = async (eve) => {
    eve.preventDefault();

    let payload = {
      email: this.state.email,
      mobile: this.state.mobile,
      name: this.state.name,
      role: 'subadmin',
      id: this.state.id,
      user_id: this.state.id,
      assigned_categories: this.state.assigned_categories.join(','),
    };

    if (this.state.forgotClick) {
      payload = {
        ...payload,
        password: this.state.password,
        password1: this.state.retypePassword,
      };

      if (payload.password == '' || payload.password1 == '') {
        return toast.error('Please fill both the password fields');
      }
    }

    if (payload.password != payload.password1) {
      return toast.error('Passwords do not match');
    }

    this.props.actions.editAdmin(payload);
  };

  uploadAdmin = async (eve) => {
    eve.preventDefault();

    let payload = {
      email: this.state.email,
      mobile: this.state.mobile,
      password: this.state.password,
      password1: this.state.retypePassword,
      name: this.state.name,
      role: 'subadmin',
      assigned_categories: this.state.assigned_categories.join(','),
    };

    if (payload.password != payload.password1) {
      return toast.error('Passwords do not match');
    }

    this.props.actions.createAdmin(payload);
  };

  toggleCategory = (id) => {
    let { assigned_categories } = this.state,
      add = true;

    if (assigned_categories.includes(id + '|edit') || assigned_categories.includes(id + '|view')) {
      add = false;
    }
    assigned_categories = assigned_categories.filter((d) => d != id + '|edit' && d != id + '|view');

    if (add) {
      assigned_categories.push(id + '|view');
    }
    this.setState({ ...this.state, assigned_categories });
  };

  generatePassword = () => {
    let systemPassword = GPassword.generate({ length: 10, numbers: true });
    this.setState({
      ...this.state,
      systemPassword,
      password: systemPassword,
      retypePassword: systemPassword,
    });
  };

  alterAccess = (id, access) => {
    let { assigned_categories } = this.state;
    if (assigned_categories.includes(id + '|edit') || assigned_categories.includes(id + '|view')) {
      assigned_categories = assigned_categories.filter((d) => d != id + '|edit' && d != id + '|view');
    }
    let value = id + '|' + access;
    assigned_categories.push(value);
    this.setState({ ...this.state, assigned_categories });
  };

  renderCategories = () => {
    return this.props.categories.map(({ name, id }, ind) => {
      let edit = this.state.assigned_categories.includes(id + '|edit'),
        view = this.state.assigned_categories.includes(id + '|view'),
        v = 'view';

      if (edit) {
        v = 'edit';
      }

      return (
        <tr key={ind}>
          <td>
            <div>
              <div>
                <input
                  type="checkbox"
                  checked={edit || view}
                  id={id}
                  value={id}
                  className={ind}
                  onChange={() => this.toggleCategory(id)}
                />
                <label htmlFor={id}>{name}</label>
              </div>
            </div>
          </td>
          <td>
            <select
              disabled={!(edit || view)}
              value={v}
              onChange={(e) => {
                this.alterAccess(id, e.target.value);
              }}
            >
              <option value="view">View</option>
              <option value="edit">Edit</option>
            </select>
          </td>
        </tr>
      );
    });
  };

  render() {
    const {
      name,
      email,
      mobile,
      password,
      retypePassword,
      forgotClick,
      photo,
      awsKeys,
      disableUpload,
      mode,
      systemPassword,
      assigned_categories,
    } = this.state;
    const { categories, isCreating } = this.props;

    return (
      <section className="well create-admin">
        <div className="row">
          <div className="col-md-3">
            <label>Name</label>
          </div>
          <div className="col-md-9">
            <input
              name="name"
              placeholder="Name"
              type="text"
              value={name}
              onChange={this.onChangeSetToState('name')}
              className="form-control"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <label>Email</label>
          </div>
          <div className="col-md-9">
            <input
              name="email"
              placeholder="Email"
              type="text"
              value={email}
              onChange={this.onChangeSetToState('email')}
              className="form-control"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Mobile</label>
          </div>
          <div className="col-md-9">
            <input
              name="mobile"
              placeholder="Mobile"
              type="text"
              maxLength="13"
              value={mobile}
              onChange={this.onChangeSetToState('mobile')}
              className="form-control"
            />
          </div>
        </div>
        {mode == 'EDIT' && (
          <div
            className="changePass"
            onClick={() => {
              this.setState({ ...this.state, forgotClick: !this.state.forgotClick });
            }}
          >
            {!forgotClick ? 'Reset Password' : 'Do not reset'}
          </div>
        )}
        {(mode == 'CREATE' || forgotClick) && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <label>Password</label>
              </div>
              <div className="col-md-9">
                <input
                  name="password"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={this.onChangeSetToState('password')}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Re-enter Password</label>
              </div>
              <div className="col-md-9">
                <input
                  name="password"
                  placeholder="Re-enter Password"
                  type="password"
                  value={retypePassword}
                  onChange={this.onChangeSetToState('retypePassword')}
                  className="form-control"
                />
              </div>
            </div>
            <div className="systemPassword">
              <div className="col-md-3"></div>
              <div>
                <div className="label" onClick={this.generatePassword}>
                  Generate System Password
                </div>
                <div>
                  {systemPassword && <label>System Password (shown only once, copy and save instantly) : </label>}
                  <span className="randPass">{systemPassword}</span>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className="row">
          <div className="col-md-3">
            <label>Accessible Categories</label>
          </div>
          <div className="col-md-9 categories">
            <table className="table table-bordered table-responsive table-hover">
              <tbody>
                <tr>
                  <th>Category</th>
                  <th>Access Type</th>
                </tr>
                {this.renderCategories()}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="create-piece__btn-container">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              onClick={(e) => (this.state.mode == 'CREATE' ? this.uploadAdmin(e) : this.updateAdmin(e))}
              disabled={
                isCreating ||
                (mode == 'CREATE' && !(name && email && mobile && password && retypePassword)) ||
                (mode == 'EDIT' && !(name && email && mobile))
              }
            >
              {isCreating || this.state.isUploading ? (
                <span className="spinner" />
              ) : (
                <span>{this.state.mode == 'CREATE' ? 'Create' : 'Update'}</span>
              )}
            </button>
          </div>
        </div>
      </section>
    );
  }
}
