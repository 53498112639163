import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import axiosService from '../../inits/axios';

import {
  createPiece,
  getPieceByID,
  editPiece,
  deleteCurrentPiece,
  createInterview,
  deleteInterview,
  editPieceInterview,
  createCloseups,
  deleteCloseups,
  editPieceCloseups,
  pieceSubscription,
  getPieceClosupByID,
  getPieceInterviewByID,
  getPieceSubscriptionByID,
} from '../../store/actions/piece/piece.action';
import { getComposers } from '../../store/actions/composer/composer.action';
import { getDifficulties } from '../../store/actions/difficulties/difficulties.action';
import { getEras } from '../../store/actions/era/era.action';
import { getInstruments } from '../../store/actions/instrument/instrument.action';
import { getMembers } from '../../store/actions/member/member.action';
import {
  getAwsKey,
  createPieceVideo,
  deletePieceVideo,
  editPieceVideo,
} from '../../store/actions/piece-video/piece-video.action';
import { getArtists, getArtistByID } from '../../store/actions/artist/artist.action';
import {
  createPieceInstrument,
  deletePieceInstrument,
  editPieceInstrument,
} from '../../store/actions/piece-instrument/piece-instrument.action';
import { getSubscriptions } from '../../store/actions/subscription/subscription.action';
import CreatePiece from '../../components/Pieces/CreatePiece';
import CreateInstruments from '../../components/Pieces/CreateInstruments';
import CreateVideos from '../../components/Pieces/CreateVideos';
import CreateInterview from '../../components/Pieces/CreateInterview';
import CreateCloseups from '../../components/Pieces/CreateCloseups';
import CreateSubscriptions from '../../components/Pieces/CreateSubscriptions';

import PageLoader from '../../components/Common/PageLoader';

import '../../styles/piece.css';

class CreatePiecesContainer extends Component {
  state = {
    mode: 'CREATE',
    pieceToBeEdit: null,
    startRender: false,
    field: 'pieces',
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    const mode = params && params.id ? 'EDIT' : 'CREATE';

    this.getPieceAttribute(mode, params.id);
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) !== JSON.stringify(this.props.categories)) {
      this.setAccess();
    }

    if (JSON.stringify(props.currentPiece) !== JSON.stringify(this.props.currentPiece) && this.state.pieceToBeEdit) {
      this.setState((prev) => ({
        ...prev,
        pieceToBeEdit: this.props.currentPiece,
      }));
    }
  }

  setAccess = (props) => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'pieces')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  componentWillUnmount() {
    this.props.deleteCurrentPiece();
  }

  getPieceAttribute = (mode, pieceId) => {
    const {
      getComposers,
      getMembers,
      getDifficulties,
      getEras,
      getInstruments,
      getArtists,
      getSubscriptions,
    } = this.props;
    Promise.all([
      getMembers(),
      getComposers(),
      getDifficulties(),
      getEras(),
      getInstruments(),
      getArtists(),
      getSubscriptions(),
    ])
      .then((data) => {
        if (mode === 'EDIT') {
          return this.getPiece(mode, pieceId);
        }
        this.setState((prevState) => {
          return {
            ...prevState,
            mode,
            startRender: true,
          };
        });
      })
      .catch((error) => {
        history.push('/pieces');
      });
  };

  triggerGetPiece = () => {
    const { params } = this.props.match;
    const mode = params && params.id ? 'EDIT' : 'CREATE';
    this.getPiece(mode, params.id);
  };

  getPiece = async (mode, pieceID) => {
    try {
      const pieceToBeEdit = await this.props.getPieceByID(pieceID);
      const artistInfo = await this.props.getArtistByID(pieceToBeEdit.artist_id);
      let pieceInterviews = [],
        pieceCloseups = [],
        pieceSubscriptions = [];
      try {
        pieceInterviews = await axiosService.get(`/pieces/interviews?piece_id=${pieceID}`);
      } catch (err) {}
      try {
        pieceCloseups = await axiosService.get(`/pieces/closeups?piece_id=${pieceID}`);
      } catch (err) {}
      try {
        pieceSubscriptions = await axiosService.get(`/pieces/subscriptions?piece_id=${pieceID}`);
      } catch (err) {}

      this.setState((prevState) => {
        return {
          ...prevState,
          pieceToBeEdit,
          mode,
          startRender: true,
          artistInfo,
          pieceInterviews,
          pieceCloseups,
          pieceSubscriptions,
        };
      });
    } catch (error) {
      // history.push('/pieces');
    }
  };
  createInterview = async (interviewInfo) => {
    await this.props.createInterview(interviewInfo);
    this.getInterviews();
  };

  deleteInterview = async (interviewInfo) => {
    await this.props.deleteInterview(interviewInfo);
    this.getInterviews();
  };

  getInterviews = async () => {
    const pieceInterviews = await axiosService.get(`/pieces/interviews?piece_id=${this.state.pieceToBeEdit.id}`);
    this.setState((prevState) => {
      return {
        ...prevState,
        pieceInterviews,
      };
    });
  };

  createCloseups = async (closeupsInfo) => {
    await this.props.createCloseups(closeupsInfo);
    this.getCloseups();
  };

  deleteCloseups = async (closeupsInfo) => {
    await this.props.deleteCloseups(closeupsInfo);
    this.getCloseups();
  };

  getCloseups = async () => {
    const pieceCloseups = await axiosService.get(`/pieces/closeups?piece_id=${this.state.pieceToBeEdit.id}`);
    this.setState((prevState) => {
      return {
        ...prevState,
        pieceCloseups,
      };
    });
  };

  createInterview = async (interviewInfo) => {
    await this.props.createInterview(interviewInfo);
    this.getInterviews();
  };

  deleteInterview = async (interviewInfo) => {
    await this.props.deleteInterview(interviewInfo);
    this.getInterviews();
  };

  getInterviews = async () => {
    const pieceInterviews = await axiosService.get(`/pieces/interviews?piece_id=${this.state.pieceToBeEdit.id}`);
    this.setState((prevState) => {
      return {
        ...prevState,
        pieceInterviews,
      };
    });
  };

  createCloseups = async (closeupsInfo) => {
    await this.props.createCloseups(closeupsInfo);
    this.getCloseups();
  };

  deleteCloseups = async (closeupsInfo) => {
    await this.props.deleteCloseups(closeupsInfo);
    this.getCloseups();
  };

  getCloseups = async () => {
    const pieceCloseups = await axiosService.get(`/pieces/closeups?piece_id=${this.state.pieceToBeEdit.id}`);
    this.setState((prevState) => {
      return {
        ...prevState,
        pieceCloseups,
      };
    });
  };
  showField = (fieldName) => {
    this.setState({
      field: fieldName,
    });
  };

  createPieceSubscriptionUtil = async (f1, f2, f3, f4) => {
    try {
      await this.props.pieceSubscription(f1, f2, f3, f4);
      const { params } = this.props.match;
      const mode = params && params.id ? 'EDIT' : 'CREATE';
      this.getPiece(mode, params.id);
      return Promise.resolve();
    } catch (err) {}
  };

  render() {
    if (!this.state.startRender) {
      return <PageLoader />;
    }
    const {
      createPiece,
      editPiece,
      eras,
      difficulties,
      composers,
      piecesVideo,
      instruments,
      currentPiece,
      getAwsKey,
      createPieceInstrument,
      editPieceInstrument,
      createPieceVideo,
      deletePieceInstrument,
      deletePieceVideo,
      isCreating,
      artists,
      editPieceVideo,
      editPieceInterview,
      editPieceCloseups,
      getSubscriptions,
      subscriptions,
      pieceSubscription,
      members,
      pieces_categories
    } = this.props;

    const { artistInfo, pieceInterviews, pieceCloseups, accessDenied, accessMode } = this.state;
    const piecesInfo = { eras, members, difficulties, composers, piecesVideo, instruments, artists };
    const pieceInstrumentInfo = {
      instruments,
      piece: currentPiece,
      getAwsKey,
      getCurrentPiece: this.getPiece,
    };

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    return (
      <section>
        <div className="row">
          <div className="navBarPiece">
            <div
              className={this.state.field === 'pieces' ? 'navBarField navBarFieldActive' : 'navBarField'}
              onClick={() => this.showField('pieces')}
            >
              <div className="navBarHeading">Piece</div>
            </div>
            <div className={this.state.field === 'instruments' ? 'navBarField navBarFieldActive' : 'navBarField'}>
              <div className="navBarHeading" onClick={() => this.showField('instruments')}>
                Instruments
              </div>
            </div>
            <div className={this.state.field === 'videos' ? 'navBarField navBarFieldActive' : 'navBarField'}>
              <div className="navBarHeading" onClick={() => this.showField('videos')}>
                Videos
              </div>
            </div>
            <div className={this.state.field === 'interviews' ? 'navBarField navBarFieldActive' : 'navBarField'}>
              <div className="navBarHeading" onClick={() => this.showField('interviews')}>
                Interviews
              </div>
            </div>
            <div className={this.state.field === 'closeups' ? 'navBarField navBarFieldActive' : 'navBarField'}>
              <div className="navBarHeading" onClick={() => this.showField('closeups')}>
                Closeups
              </div>
            </div>
            <div className={this.state.field === 'subscriptions' ? 'navBarField navBarFieldActive' : 'navBarField'}>
              <div className="navBarHeading" onClick={() => this.showField('subscriptions')}>
                Subscriptions
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            {this.state.field === 'pieces' && (
              <CreatePiece
                accessMode={accessMode}
                onCreate={createPiece}
                onEdit={editPiece}
                initialValues={this.state.pieceToBeEdit}
                mode={this.state.mode}
                piecesInfo={piecesInfo}
                isCreating={isCreating}
                getAwsKey={getAwsKey}
                pieces_categories={pieces_categories}
              />
            )}
          </div>
          {this.state.field === 'instruments' && (
            <div className="col-sm-12">
              {currentPiece ? (
                <CreateInstruments
                  accessMode={accessMode}
                  mode={this.state.mode}
                  piecesInfo={piecesInfo}
                  onCreate={createPieceInstrument}
                  onEdit={editPieceInstrument}
                  pieceInstrumentInfo={pieceInstrumentInfo}
                  onDelete={deletePieceInstrument}
                />
              ) : (
                <h1>Please Add Piece</h1>
              )}
            </div>
          )}
          {this.state.field === 'videos' && (
            <div className="col-sm-12">
              {currentPiece ? (
                <CreateVideos
                  accessMode={accessMode}
                  mode={this.state.mode}
                  piecesInfo={piecesInfo}
                  pieceInstrumentInfo={pieceInstrumentInfo}
                  onCreate={createPieceVideo}
                  onEdit={editPieceVideo}
                  onDelete={deletePieceVideo}
                />
              ) : (
                <h1>Please Add Piece</h1>
              )}
            </div>
          )}
          {this.state.field === 'interviews' && (
            <div className="col-sm-12">
              {currentPiece ? (
                <CreateInterview
                  accessMode={accessMode}
                  currentPiece={currentPiece}
                  artistInfo={artistInfo}
                  piecesInfo={piecesInfo}
                  onCreate={this.createInterview}
                  onDelete={this.deleteInterview}
                  getAwsKey={getAwsKey}
                  onEdit={editPieceInterview}
                  pieceInterviews={pieceInterviews}
                  triggerGetPiece={this.triggerGetPiece}
                />
              ) : (
                <h1>Please Add Piece</h1>
              )}
            </div>
          )}
          {this.state.field === 'closeups' && (
            <div className="col-sm-12">
              {currentPiece ? (
                <CreateCloseups
                  accessMode={accessMode}
                  currentPiece={currentPiece}
                  artistInfo={artistInfo}
                  piecesInfo={piecesInfo}
                  instruments={instruments}
                  onCreate={this.createCloseups}
                  onDelete={this.deleteCloseups}
                  getAwsKey={getAwsKey}
                  pieceCloseups={pieceCloseups}
                  onEdit={editPieceCloseups}
                  triggerGetPiece={this.triggerGetPiece}
                />
              ) : (
                <h1>Please Add Piece</h1>
              )}
            </div>
          )}
          {this.state.field === 'subscriptions' && (
            <div className="col-sm-12">
              {currentPiece ? (
                <CreateSubscriptions
                  accessMode={accessMode}
                  currentPiece={currentPiece}
                  subscriptions={subscriptions}
                  pieceSubscriptions={this.state.pieceSubscriptions}
                  pieceAddDelete={this.createPieceSubscriptionUtil}
                />
              ) : (
                <h1>Please Add Piece</h1>
              )}
            </div>
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCreating: state.piece.isCreating,
    currentPiece: state.piece.currentPiece,
    eras: state.era.list,
    difficulties: state.difficulties.list,
    composers: state.composer.list,
    instruments: state.instrument.list,
    artists: state.artist.list,
    subscriptions: state.subscription.list,
    categories: state.auth.categories,
    members: state.member.list,
    pieces_categories:state.piece.pieces_categories
  };
};
const mapDispatchToProps = {
  createPiece,
  getPieceByID,
  editPiece,
  getComposers,
  getDifficulties,
  getEras,
  getInstruments,
  getAwsKey,
  createPieceInstrument,
  deleteCurrentPiece,
  createPieceVideo,
  deletePieceInstrument,
  deletePieceVideo,
  getArtists,
  getArtistByID,
  getMembers,
  createInterview,
  deleteInterview,
  createCloseups,
  deleteCloseups,
  editPieceInstrument,
  editPieceVideo,
  editPieceInterview,
  editPieceCloseups,
  getSubscriptions,
  pieceSubscription,
  getPieceInterviewByID,
  getPieceSubscriptionByID,
  getPieceClosupByID,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePiecesContainer);
