import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import MultiSelect from 'react-multi-select-component';

import { required } from '../../utils/validation.util';
import { renderInput } from '../../utils/input.util';
import _ from 'lodash';
import cards from '../../assets/data/card-colors.json';
import SubscriptionCard from './SubscriptionCard';

class CreateSubscription extends Component {
  state = {
    photo: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    photoUploaded: false,
    isUploading: false,
    showExistingCard: false,
    name: '',
    price: '',
    months: '',
    icon: '',
    includes: [],
    features: [],
    meta: {
      amount_color: '',
      card_bg_top_color: '',
      card_bg_bottom_color: '',
      card_title_color: '',
      left_button_bg_color: '',
      left_button_title_color: '',
      right_button_bg_color: '',
      right_button_title_color: '',
      bg_circle_top_left_color: '',
      bg_circle_bottom_right_color: '',
      summary_color: '',
    },

    existing_card: {
      title: '',
      meta: {},
    },
  };

  componentDidMount() {
    if (this.props.subscriptionInfo.mode === 'EDIT') {
      let { initialValues } = this.props.subscriptionInfo;
      initialValues = { ...initialValues };
      initialValues.features = initialValues.features.map(({ feature }) => {
        return { label: feature.name, value: feature.id };
      });

      let meta = { ...this.state.meta, ...initialValues.meta };
      delete meta.card_bg_button_color;
      this.setState((prevState) => ({
        ...prevState,
        ...initialValues,
        photoUploaded: true,
        meta,
      }));
    }
  }

  setSelected = async (features) => {
    this.setState((prev) => ({
      ...prev,
      features,
    }));

    const { deleteSubscriptionFeature, addSubscriptionFeature } = this.props.subscriptionInfo;

    let parentFeatures = [
      ...(this.props.subscriptionInfo.initialValues && this.props.subscriptionInfo.initialValues.features
        ? this.props.subscriptionInfo.initialValues.features
        : []),
    ];

    parentFeatures = parentFeatures.map((data) => {
      let feat = { ...data.feature };
      feat.feature_id = feat.id;
      delete feat.id;
      return { ...data, ...feat };
    });

    if (features.length > parentFeatures.length) {
      let feature_id;
      _.filter(features, (feat) => {
        if (!_.find(parentFeatures, { feature_id: feat.value })) {
          feature_id = feat.value;
        }
      });

      await addSubscriptionFeature({ subscription_id: this.state.id, feature_id });
    } else {
      parentFeatures = _.filter(parentFeatures, (pfeat) => {
        return !_.find(features, { value: pfeat.feature_id });
      });

      if (parentFeatures.length) await deleteSubscriptionFeature(parentFeatures[0].id);
    }
  };

  onChangeSetToState = (stateKey, i) => (e) => {
    if (stateKey === 'months' || stateKey === 'price') {
      this.setState({ [stateKey]: Number(e.target.value) });
    } else if (stateKey === 'includes') {
      const newincludes = this.state.includes.map((include, index) => {
        if (i !== index) return include;
        const value = e.target.value;
        return value;
      });
      this.setState({
        includes: newincludes,
      });
    } else if (stateKey === 'meta') {
      const metaState = this.state.meta;
      metaState[i] = e.target.value;
      this.setState({
        meta: metaState,
      });
    } else {
      this.setState({ [stateKey]: e.target.value });
    }
  };

  onSubmit = () => {
    const { name, price, months, includes, meta, icon, photoUploaded, id } = this.state;

    if (this.props.subscriptionInfo.mode === 'EDIT') {
      this.props.subscriptionInfo.editSubscription({
        name,
        price,
        months,
        includes,
        meta,
        icon,
        photoUploaded,
        id,
      });
      return;
    }

    this.props.subscriptionInfo.createSubscription({
      name,
      price,
      months,
      includes,
      meta,
      icon,
      photoUploaded,
    });
  };
  onFileChange = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      photoUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.subscriptionInfo.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `subscriptions/images/${fileName}` });
    this.setState((prevState) => ({
      ...prevState,
      file,
      icon: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
  };

  uploadPhoto = async (eve) => {
    eve.preventDefault();
    if (!this.state.awsKeys.action) {
      toast.error('please try again.');
    }
    var form = document.querySelector('form');
    var data = new FormData(form);
    try {
      this.setState((prevState) => ({
        ...prevState,
        isUploading: true,
      }));
      await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
      toast.success('Photo uploaded successfully.');
      this.setState((prevState) => ({
        ...prevState,
        photoUploaded: true,
        isUploading: false,
      }));
    } catch (error) {
      toast.error('Something went wrong while uploading Photo.');
    }
  };

  removeIncluded = (index) => {
    let { includes } = this.state;
    includes.splice(index, 1);
    this.setState((prev) => ({
      ...prev,
      includes,
    }));
  };

  getDisableState = () => {
    const { name } = this.state;
    const { isCreating } = this.props.subscriptionInfo;
    return isCreating || !name;
  };
  addIncludes = () => {
    this.setState({
      includes: [...this.state.includes.concat('')],
    });
  };

  setExistingCardMeta = (card) => {
    this.setState({
      ...this.state,
      showExistingCard: true,
      existing_card: card,
    });
  };

  hideExistingCard = () => {
    this.setState({
      ...this.state,
      showExistingCard: false,
    });
  };

  copyToCardMeta = () => {
    this.setState({
      ...this.state,
      showExistingCard: false,
      meta: {
        ...this.state.meta,
        ...this.state.existing_card.meta,
      },
    });
  };

  render() {
    const { isCreating, mode } = this.props.subscriptionInfo;
    const {
      awsKeys,
      disableUpload,
      isUploading,
      showExistingCard,
      name,
      months,
      price,
      includes,
      meta,
      existing_card,
    } = this.state;
    const { accessMode, features_list, mode: pagemode, subscriptionInfo } = this.props;

    return (
      <div className="create-subscription">
        <section className="well create-artist col-md-6">
          <div className="row">
            {!this.state.id && (
              <div className="notice">
                * Features addition option will be available after the subscription is created
              </div>
            )}

            <div className="col-md-3">
              <label>Name</label>
            </div>
            <div className="col-md-9">
              <input
                name="name"
                placeholder="Name"
                type="text"
                value={this.state.name}
                onChange={this.onChangeSetToState('name')}
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <label>Months</label>
            </div>
            <div className="col-md-9">
              <input
                name="months"
                placeholder="Months"
                type="number"
                value={this.state.months}
                onChange={this.onChangeSetToState('months')}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <label>Price</label>
            </div>
            <div className="col-md-9">
              <input
                name="price"
                placeholder="Price"
                type="number"
                value={this.state.price}
                onChange={this.onChangeSetToState('price')}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              />
            </div>
          </div>

          {subscriptionInfo.initialValues && (
            <div className="row">
              <div className="col-md-3">
                <label>Features</label>
              </div>
              <div className="col-md-8">
                <MultiSelect
                  options={features_list.map((f) => {
                    return { label: f.name, value: f.id };
                  })}
                  onChange={this.setSelected}
                  value={this.state.features}
                  hasSelectAll={false}
                  labelledBy={'Select features'}
                  disableSearch={true}
                />
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-md-3">
              <label>Description</label>
            </div>
            <div className="col-md-8">
              {this.state.includes.map((include, i) => (
                <div className="include-box" key={i}>
                  <input
                    key={i}
                    name="includes"
                    placeholder="Includes"
                    type="text"
                    value={include}
                    onChange={this.onChangeSetToState('includes', i)}
                    className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                  />
                  <span onClick={() => this.removeIncluded(i)}>&times;</span>
                </div>
              ))}
            </div>

            {accessMode == 'edit' && (
              <div className="col-md-1">
                <div onClick={this.addIncludes}>
                  <i className="fa fa-icon fa-plus" />
                </div>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-3">
              <label>Select Card</label>
            </div>
            <div className="col-md-9">
              <select
                defaultValue={JSON.stringify(cards[0])}
                onChange={(e) => this.setExistingCardMeta(JSON.parse(e.target.value))}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              >
                {cards.map((card, ind) =>
                  !ind ? (
                    <option disabled={true} key={ind} value={JSON.stringify(card)}>
                      {card.title}
                    </option>
                  ) : (
                    <option key={ind} value={JSON.stringify(card)}>
                      {card.title}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <label>Card Colours</label>
            </div>
            <div className="col-md-8 d-flex-col">
              {Object.keys(this.state.meta).map((key, ind) => {
                return (
                  <div key={ind}>
                    <span className="capitalize">{key.split('_').join(' ')}</span>
                    <input
                      key={key}
                      name={key}
                      placeholder={'Add color RGB values'}
                      type="color"
                      value={this.state.meta[key]}
                      onChange={this.onChangeSetToState('meta', key)}
                      className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                    />
                  </div>
                );
              })}
            </div>

            {accessMode == 'edit' && (
              <div className="col-md-1">
                <div onClick={this.addMeta}>
                  <i className="fa fa-icon fa-plus" />
                </div>
              </div>
            )}
          </div>
          {mode === 'EDIT' ? (
            <div className="row">
              <div className="col-md-3">
                <label>Photo</label>
              </div>
              <div className="col-md-9">{this.state.icon}</div>
            </div>
          ) : null}

          {accessMode == 'edit' && (
            <React.Fragment>
              <div className="row">
                <div className="col-md-3">
                  <label>Photo</label>
                </div>
                <div className="col-md-9">
                  <form method="post" encType="multipart/form-data" onSubmit={this.uploadPhoto}>
                    <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                    <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                    <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                    <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                    <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                    <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                    <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                    <input type="file" name="file" onChange={this.onFileChange} /> <br />
                    <input
                      type="submit"
                      name="submit"
                      value="Upload to Amazon S3"
                      className="btn btn-default btn-upload"
                      disabled={!disableUpload}
                    />
                  </form>
                </div>
              </div>
              <div className="create-piece__btn-container">
                <button
                  className="cus-btn-primary btn-block btn-lg"
                  type="submit"
                  onClick={this.onSubmit}
                  disabled={this.getDisableState()}
                >
                  {isCreating || isUploading ? (
                    <span className="spinner" />
                  ) : (
                    <span>{pagemode == 'EDIT' ? 'Update' : 'Create'}</span>
                  )}
                </button>
              </div>
            </React.Fragment>
          )}
        </section>

        <div>
          <div className="preview-card">
            <SubscriptionCard meta={meta} name={name} months={months} price={price} includes={includes} />
            {showExistingCard && (
              <div className="prev">
                <div className="preview-text">
                  <div>{existing_card.title}</div>
                  <i className="fas fa-times" onClick={this.hideExistingCard}></i>
                </div>
                <div className="add-colors" onClick={this.copyToCardMeta}>
                  Add Colours to current card
                </div>
                <SubscriptionCard meta={existing_card.meta} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: 'createSubscription' })(CreateSubscription);
