import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createAdmin,
  editAdmin,
  deleteCurrentAdmin,
  getAdminByID,
  getCategories,
} from '../../store/actions/admins/admins.action';

import PageLoader from '../../components/Common/PageLoader';
import CreateAdmin from '../../components/Admins/CreateAdmin';

import '../../styles/admin.css';

class AdminsContainer extends Component {
  constructor(props) {
    super(props);
  }

  // componentDidMount() {
  //   const { params } = this.props.match;
  //   const mode = params && params.id ? 'EDIT' : 'CREATE';
  //   this.processCreateOREdit(mode, params.id);
  // }

  render() {
    const { isFetching, currentAdmin, isCreating, categories } = this.props;
    const { createAdmin, editAdmin, getAdminByID, getCategories } = this.props;
    if (isFetching) {
      return <PageLoader />;
    }

    return (
      <section className="createAdminContainer">
        <CreateAdmin
          match={this.props.match}
          adminData={currentAdmin}
          actions={{ createAdmin, editAdmin, getAdminByID, getCategories }}
          isCreating={isCreating}
          categories={categories}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.admin.isFetching,
    isCreating: state.admin.isCreating,
    currentAdmin: state.admin.currentAdmin,
    categories: state.admin.categories,
  };
};

const mapDispatchToProps = {
  createAdmin,
  editAdmin,
  deleteCurrentAdmin,
  getAdminByID,
  getCategories,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminsContainer);
