export const USERS_LIST_START = 'USERS_LIST_START';
export const USERS_LIST_SUCCESS = 'USERS_LIST_SUCCESS';
export const USERS_LIST_ERROR = 'USERS_LIST_ERROR';

export const SINGLE_USER_START = 'SINGLE_USER_START';
export const SINGLE_USER_SUCCESS = 'SINGLE_USER_SUCCESS';
export const SINGLE_USER_ERROR = 'SINGLE_USER_ERROR';

export const SINGLE_USER_CREATE_START = 'SINGLE_USER_CREATE_START';
export const SINGLE_USER_CREATE_END = 'SINGLE_USER_CREATE_SUCCESS';
