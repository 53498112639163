import { GET_ORDERS_START, GET_ORDERS_SUCCESS, GET_ORDERS_ERROR } from '../actions/order/order.actiontype';

export default function(state = { list: [] }, action) {
  switch (action.type) {
    case GET_ORDERS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_ORDERS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
}
