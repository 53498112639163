import React, { Component } from 'react';
import { connect } from 'react-redux';

import SingleUser from '../../components/Users/SingleUser';
import { getSingleUser, updatePassword } from '../../store/actions/users/users.action';
import PageLoader from '../../components/Common/PageLoader';

import '../../styles/users.css';

class PiecesInstrumentContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getSingleUser(id);
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'users')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { singleUser, isFetching } = this.props;
    const { accessDenied, mode } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    return (
      <section id="user">
        <SingleUser mode={mode} user={singleUser} updatePassword={this.props.updatePassword}/>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetching: state.users.isFetching,
    singleUser: state.users.singleUser,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  getSingleUser,
  updatePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(PiecesInstrumentContainer);
