import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteCoupon, getCoupons } from '../../store/actions/coupon/coupon.action';
import CouponsList from '../../components/Coupons/Coupons';
import PageLoader from '../../components/Common/PageLoader';

class CouponsContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    this.props.getCoupons();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'coupons')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { deleteCoupon, coupons, isFetching } = this.props;
    if (isFetching) {
      return <PageLoader />;
    }

    const { accessDenied, mode } = this.state;
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }
    const couponInfo = { deleteCoupon, coupons };
    return (
      <section>
        <CouponsList mode={mode} couponInfo={couponInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.coupon.isCreating,
    coupons: state.coupon.list,
    isFetching: state.coupon.isFetching,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  deleteCoupon,
  getCoupons,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponsContainer);
