export const GET_PIECES_START = 'GET_PIECES_START';
export const GET_PIECES_SUCCESS = 'GET_PIECES_SUCCESS';
export const GET_PIECES_ERROR = 'GET_PIECES_ERROR';

export const EDIT_PIECE_START = 'EDIT_PIECE_START';
export const EDIT_PIECE_SUCCESS = 'EDIT_PIECE_SUCCESS';
export const EDIT_PIECE_ERROR = 'EDIT_PIECE_ERROR';

export const CREATE_PIECE_START = 'CREATE_PIECE_START';
export const CREATE_PIECE_SUCCESS = 'CREATE_PIECE_SUCCESS';
export const CREATE_PIECE_ERROR = 'CREATE_PIECE_ERROR';

export const DELETE_PIECE_START = 'DELETE_PIECE_START';
export const DELETE_PIECE_SUCCESS = 'DELETE_PIECE_SUCCESS';
export const DELETE_PIECE_ERROR = 'DELETE_PIECE_ERROR';
export const GET_CURRENTPIECES = 'GET_CURRENTPIECES';
export const DESTROY_CURRENTPIECES = 'DESTROY_CURRENTPIECES';

export const SET_PIECES_CATEGORIES = 'SET_PIECES_CATEGORIES';


export const SET_HOME_PIECE_ID = 'SET_HOME_PIECE_ID';
