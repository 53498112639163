import React, { Component } from 'react';

export default class NewCategoryContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div class="box">
        <div class="box-header with-border">
          <h3 class="box-title">New Category</h3>
        </div>

        <div className="categoryTypes">
          <div></div>
        </div>
      </div>
    );
  }
}
