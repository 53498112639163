import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_SUBSCRIPTIONS_START,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_ERROR,
  CREATE_SUBSCRIPTION_START,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_ERROR,
  DELETE_SUBSCRIPTION_START,
  DELETE_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_ERROR,
  EDIT_SUBSCRIPTION_START,
  EDIT_SUBSCRIPTION_SUCCESS,
  EDIT_SUBSCRIPTION_ERROR,
  DESTROY_CURRENT_SUBSCRIPTION,
  SET_CURRENT_SUBSCRIPTION,
} from './subscription.actiontype';

import _ from 'lodash';

import { getError } from '../../../utils/common.util';

export const getSubscriptions = () => async (dispatch) => {
  try {
    dispatch({ type: GET_SUBSCRIPTIONS_START });
    let subscriptions = _.sortBy(await axiosService.get('/subscriptions'), (d) => Number(-d.price));

    dispatch({ type: GET_SUBSCRIPTIONS_SUCCESS, payload: subscriptions });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_SUBSCRIPTIONS_ERROR });
  }
};

export const createSubscription = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SUBSCRIPTION_START });
    const subscription = await axiosService.post('/subscriptions', fields);
    dispatch({ type: SET_CURRENT_SUBSCRIPTION, payload: subscription });
    toast.success('Successfully created.');
    dispatch({ type: CREATE_SUBSCRIPTION_SUCCESS, payload: subscription });
    history.push('/subscriptions');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_SUBSCRIPTION_ERROR });
  }
};

export const deleteSubscription = (subscription) => async (dispatch) => {
  if (!window.confirm('Are you sure to delete this subscription ?')) return;
  try {
    dispatch({ type: DELETE_SUBSCRIPTION_START });
    const deletedSubscriptions = await axiosService.delete(`/subscriptions/${subscription.id}`);
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_SUBSCRIPTION_SUCCESS, payload: deletedSubscriptions });
  } catch (error) {
    if (error.code == 500) toast.error('Subscription already attached to a piece. Deletion restricted.');
    dispatch({ type: DELETE_SUBSCRIPTION_ERROR });
  }
};

export const editSubscription = (subscription) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_SUBSCRIPTION_START });
    const newSubscription = await axiosService.put(`/subscriptions/${subscription.id}`, subscription);
    dispatch({ type: SET_CURRENT_SUBSCRIPTION, payload: newSubscription });
    toast.success('Successfully saved.');
    dispatch({ type: EDIT_SUBSCRIPTION_SUCCESS, payload: newSubscription });
    history.push('/subscriptions');
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_SUBSCRIPTION_ERROR });
  }
};

export const getSubscriptionByID = (subscriptionId) => async (dispatch) => {
  try {
    let subscription = await axiosService.get(`/subscriptions/${subscriptionId}`);

    dispatch({ type: SET_CURRENT_SUBSCRIPTION, payload: subscription });
    return Promise.resolve(subscription);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const deleteCurrentSubscription = () => async (dispatch) => {
  dispatch({ type: DESTROY_CURRENT_SUBSCRIPTION, payload: null });
};

export const addSubscriptionFeature = (fields) => (dispatch) => {
  axiosService.post('/subscription-features', { ...fields, enabled: true }).then(({ data }) => {
    return Promise.resolve(data);
  });
};

export const deleteSubscriptionFeature = (fields) => (dispatch) => {
  axiosService.delete('/subscription-features/' + fields).then(({ data }) => {
    return Promise.resolve(data);
  });
};
