import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

import { renderInput } from '../../utils/input.util';

class CreatePieceVideo extends Component {
  state = {
    piece_id: '',
    video_file: '',
    video: '',
    quality_level: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    piece: null,
    videoUploaded: false,
    isUploading: false,
  };

  onChangeSetToState = stateKey => e => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = () => {
    const { piece, video_file, quality_level } = this.state;
    const piece_id = piece[0] && piece[0].id;
    this.props.onCreate({ piece_id, video_file, quality_level: parseInt(quality_level, 10) });
  };
  onFileChange = async eve => {
    this.setState(prevState => ({
      ...prevState,
      videoUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `video/${fileName}` });
    this.setState(prevState => ({
      ...prevState,
      file,
      video_file: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
  };

  uploadVideo = async eve => {
    eve.preventDefault();
    if (!this.state.awsKeys.action) {
      toast.error('please try again.');
    }
    var form = document.querySelector('form');
    var data = new FormData(form);
    try {
      this.setState(prevState => ({
        ...prevState,
        isUploading: true,
      }));
      await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
      toast.success('Video uploaded successfully.');
      this.setState(prevState => ({
        ...prevState,
        videoUploaded: true,
        isUploading: false,
      }));
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  render() {
    const { isCreating, pieceVideoInfo } = this.props;
    const { awsKeys, file, disableUpload } = this.state;
    return (
      <section className="well create-piece">
        <div className="row">
          <div className="col-md-3">
            <label>Piece</label>
          </div>
          <div className="col-md-9">
            <Typeahead
              onChange={selected => {
                this.setState({
                  piece: selected,
                });
              }}
              labelKey="title"
              options={pieceVideoInfo.pieces}
              selected={this.state.piece}
            />
          </div>

          <div className="col-md-3">
            <label>quality</label>
          </div>
          <div className="col-md-9">
            <Field
              name="QualityLevel"
              placeholder="Quality Level"
              component={renderInput}
              type="number"
              value={this.state.quality_level}
              onChange={this.onChangeSetToState('quality_level')}
              className="form-control"
              parentClass="form-group"
            />
          </div>

          <div className="col-md-3">
            <label>Media</label>
          </div>
          <div className="col-md-9">
            <form method="post" encType="multipart/form-data" onSubmit={this.uploadVideo}>
              <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
              <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
              <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
              <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
              <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
              <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
              <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
              <input type="file" name="file" onChange={this.onFileChange} /> <br />
              <input
                type="submit"
                name="submit"
                value="Upload to Amazon S3"
                className="btn btn-default"
                disabled={!disableUpload}
              />
            </form>
          </div>
          <div className="create-piece__btn-container">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              onClick={this.onSubmit}
              disabled={isCreating || !(this.state.piece && this.state.quality_level && this.state.videoUploaded)}
            >
              {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Create</span>}
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default reduxForm({ form: 'createPiece' })(CreatePieceVideo);
