import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_PIECESVIDEO_START,
  GET_PIECESVIDEO_SUCCESS,
  GET_PIECESVIDEO_ERROR,
  CREATE_PIECEVIDEO_START,
  CREATE_PIECEVIDEO_SUCCESS,
  CREATE_PIECEVIDEO_ERROR,
  DELETE_PIECEVIDEO_START,
  DELETE_PIECEVIDEO_SUCCESS,
  DELETE_PIECEVIDEO_ERROR,
} from './piece-video.actiontype';

import { getError } from '../../../utils/common.util';

import logs from '../../../components/Common/HistoryLogs';

export const getPiecesVideo = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PIECESVIDEO_START,
    });
    const piecesVideo = await axiosService.get('/pieces/piece-videos');
    dispatch({
      type: GET_PIECESVIDEO_SUCCESS,
      payload: piecesVideo,
    });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({
      type: GET_PIECESVIDEO_ERROR,
    });
  }
};

export const getPieceVideoByID = (pieceId) => async (dispatch) => {
  try {
    const pieceVideo = await axiosService.get(`/pieces/piece-videos/${pieceId}`);
    return Promise.resolve(pieceVideo);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const createPieceVideo = (fields) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PIECEVIDEO_START,
    });

    let updates = [];
    for (let k in fields) {
      if (!k.match(/Name/) && k != 'piece_id') {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    const pieceVideo = await axiosService.post('/pieces/piece-videos', fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Videos',
      Action: 'CREATE',
      Details: updates
        .map(({ field, update }) => `${field.toUpperCase()}\n\n${update}`)
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully created.');
    dispatch({
      type: CREATE_PIECEVIDEO_SUCCESS,
      payload: pieceVideo,
    });

    return Promise.resolve();
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({
      type: CREATE_PIECEVIDEO_ERROR,
    });
    return Promise.resolve();
  }
};

export const editPieceVideo = (fields, orig) => async (dispatch) => {
  try {
    dispatch({
      type: 'EDIT_PIECEVIDEO_START',
    });

    let updates = [];
    console.log(fields, orig);

    for (let k in fields) {
      if (orig[k] && typeof fields[k] !== 'object' && fields[k] !== orig[k]) {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          prev: k.match(/_id/) ? fields[k.split('_id')[0] + 'NamePrev'] : orig[k],
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    await axiosService.put('/pieces/piece-videos/' + fields.id, fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Videos',
      Action: 'UPDATE',
      Details: updates
        .map(
          ({ field, prev, update }) =>
            `${field.toUpperCase()}\n\n------Previous------ \n${prev}\n------Changed------ \n${update}`
        )
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully created.');
    dispatch({
      type: 'EDIT_PIECEVIDEO_SUCCESS',
      payload: fields,
    });
    return Promise.resolve();
  } catch (error) {
    toast.error('Something Went Wrong');
    dispatch({
      type: 'CREATE_PIECEVIDEO_ERROR',
    });
    return Promise.resolve();
  }
};

export const deletePieceVideo = (pieceVideo) => async (dispatch) => {
  if (!window.confirm('Are you sure to delete this piece video ?')) return;
  try {
    dispatch({
      type: DELETE_PIECEVIDEO_START,
    });
    const deletedPieceVideo = await axiosService.delete(`/pieces/piece-videos/${pieceVideo.id}`);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Videos',
      Action: 'DELETE',
      Details: `VIDEO FILE------------\n\n${pieceVideo.video_file}`,
      Link: window.location.href,
    });

    toast.success('Successfully deleted.');
    dispatch({
      type: DELETE_PIECEVIDEO_SUCCESS,
      payload: deletedPieceVideo,
    });
    return Promise.resolve();
  } catch (error) {
    if (error.code == 500) toast.error('Piece video deletion restricted.');
    dispatch({
      type: DELETE_PIECEVIDEO_ERROR,
    });
    return Promise.resolve();
  }
};

export const getAwsKey = (payload) => async (dispatch) => {
  try {
    const awsKeys = await axiosService.post('/s3-file-upload', payload);
    return Promise.resolve(awsKeys);
  } catch (error) {
    toast.error(error.message);
    return Promise.resolve(error);
  }
};
