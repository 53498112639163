import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { createArtist, editArtist, getArtistByID } from '../../store/actions/artist/artist.action';
import { getAwsKey } from '../../store/actions/piece-video/piece-video.action';
import { getInstruments } from '../../store/actions/instrument/instrument.action';
import { createMember, deleteMember, editMember } from '../../store/actions/member/member.action';
import CreateArtist from '../../components/Artist/CreateArtist';
import CreateMember from '../../components/Artist/CreateMember';
import PageLoader from '../../components/Common/PageLoader';

import '../../styles/artist.css';

class CreateArtistContainer extends Component {
  state = {
    mode: 'CREATE',
    startRender: false,
    artistToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    const mode = params && params.id ? 'EDIT' : 'CREATE';
    this.processCreateOREdit(mode, params.id);
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'artists')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  processCreateOREdit = async (mode, artistId) => {
    const { getInstruments } = this.props;
    Promise.all([getInstruments()])
      .then(async data => {
        if (mode === 'EDIT') {
          return this.getArtistByID(mode, artistId);
        }
        this.setState(prevState => {
          return {
            ...prevState,
            mode,
            startRender: true,
          };
        });
      })
      .catch(error => {
        history.push('/members');
      });
  };

  getArtistByID = async (mode, artistId) => {
    let artistToBeEdit;
    try {
      artistToBeEdit = await this.props.getArtistByID(artistId);
      this.setState(prevState => {
        return {
          ...prevState,
          mode,
          startRender: true,
          artistToBeEdit,
        };
      });
    } catch (error) {
      history.push('/artists');
    }
  };

  render() {
    if (!this.state.startRender) {
      return <PageLoader />;
    }
    const {
      createArtist,
      isCreating,
      getAwsKey,
      editArtist,
      instruments,
      createMember,
      deleteMember,
      editMember,
      artists,
      currentArtist,
    } = this.props;

    const { artistToBeEdit, mode, accessDenied, accessMode } = this.state;

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const artistInfo = {
      isCreating,
      createArtist,
      getAwsKey,
      editArtist,
      initialValues: artistToBeEdit,
      mode: mode,
    };
    const memberInfo = {
      instruments,
      getAwsKey,
      createMember,
      deleteMember,
      editMember,
      artists,
      currentArtist,
      getArtistByID,
    };
    return (
      <div className="row artist-create-container">
        <div className="col-md-6">
          <CreateArtist accessMode={accessMode} artistInfo={artistInfo} />
        </div>
        {currentArtist ? (
          <div className="col-md-6">
            <CreateMember accessMode={accessMode} memberInfo={memberInfo} />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.artist.isCreating,
    instruments: state.instrument.list,
    artists: state.artist.list,
    currentArtist: state.artist.currentArtist,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  createArtist,
  getAwsKey,
  editArtist,
  getArtistByID,
  getInstruments,
  createMember,
  editMember,
  deleteMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateArtistContainer);
