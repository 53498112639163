export const GET_FEATURES_START = 'GET_FEATURES_START';
export const GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESS';
export const GET_FEATURES_ERROR = 'GET_FEATURES_ERROR';

export const EDIT_FEATURE_START = 'EDIT_FEATURE_START';
export const EDIT_FEATURE_SUCCESS = 'EDIT_FEATURE_SUCCESS';
export const EDIT_FEATURE_ERROR = 'EDIT_FEATURE_ERROR';

export const CREATE_FEATURE_START = 'CREATE_FEATURE_START';
export const CREATE_FEATURE_SUCCESS = 'CREATE_FEATURE_SUCCESS';
export const CREATE_FEATURE_ERROR = 'CREATE_FEATURE_ERROR';

export const DELETE_FEATURE_START = 'DELETE_FEATURE_START';
export const DELETE_FEATURE_SUCCESS = 'DELETE_FEATURE_SUCCESS';
export const DELETE_FEATURE_ERROR = 'DELETE_FEATURE_ERROR';
