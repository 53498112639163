import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_ARTISTS_START,
  GET_ARTISTS_SUCCESS,
  GET_ARTISTS_ERROR,
  CREATE_ARTIST_START,
  CREATE_ARTIST_SUCCESS,
  CREATE_ARTIST_ERROR,
  DELETE_ARTIST_START,
  DELETE_ARTIST_SUCCESS,
  DELETE_ARTIST_ERROR,
  EDIT_ARTIST_START,
  EDIT_ARTIST_SUCCESS,
  EDIT_ARTIST_ERROR,
  DESTROY_CURRENT_ARTIST,
  SET_CURRENT_ARTIST,
} from './artist.actiontype';

import { getError } from '../../../utils/common.util';

export const getArtists = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ARTISTS_START });
    const artists = await axiosService.get('/artists');
    dispatch({ type: GET_ARTISTS_SUCCESS, payload: artists });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_ARTISTS_ERROR });
  }
};

export const createArtist = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_ARTIST_START });
    const artist = await axiosService.post('/artists', fields);
    dispatch({ type: SET_CURRENT_ARTIST, payload: artist });
    toast.success('Successfully created.');
    dispatch({ type: CREATE_ARTIST_SUCCESS, payload: artist });
    history.push('/artists');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_ARTIST_ERROR });
  }
};

export const deleteArtist = (artist) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this artist ?')) return;

  try {
    dispatch({ type: DELETE_ARTIST_START });
    const deletedArtists = await axiosService.delete(`/artists/${artist.id}`);
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_ARTIST_SUCCESS, payload: deletedArtists });
  } catch (error) {
    if (error.code == 500) toast.error('Artist already attached to a piece. Deletion restricted.');
    dispatch({ type: DELETE_ARTIST_ERROR });
  }
};

export const editArtist = (artist) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_ARTIST_START });
    const newArtist = await axiosService.put(`/artists/${artist.id}`, artist);
    dispatch({ type: SET_CURRENT_ARTIST, payload: newArtist });
    toast.success('Successfully saved.');
    dispatch({ type: EDIT_ARTIST_SUCCESS, payload: newArtist });
    history.push('/artists');
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_ARTIST_ERROR });
  }
};

export const getArtistByID = (artistId) => async (dispatch) => {
  try {
    const artist = await axiosService.get(`/artists/${artistId}`);
    dispatch({ type: SET_CURRENT_ARTIST, payload: artist });
    return Promise.resolve(artist);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const deleteCurrentArtist = () => async (dispatch) => {
  dispatch({ type: DESTROY_CURRENT_ARTIST, payload: null });
};
