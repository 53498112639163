import React from 'react';
import { Link } from 'react-router-dom';

const Order = props => {
  const { order } = props.orderInfo;
  const { mode } = props;
  return (
    <tr>
      <td>{order.id}</td>
      <td>{order.name}</td>
      <td>{order.email}</td>
      <td>{order.phone}</td>
      <td>{order.status}</td>
      <td>{order.paid_at}</td>
      <td>£{parseFloat(order.total).toFixed(2)}</td>
      <td>{order.subscription.months}</td>
      <td>{order.subscription.price}</td>
    </tr>
  );
};

export default Order;
