import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Artist = props => {
  const { deleteArtist, artist } = props.artistInfo;
  return (
    <tr>
      <td>{artist.title}</td>
      <td>{artist.description.toString().replace(/<[^>]*>/g, '')}</td>
      <td>{artist.excerpt}</td>
      <td>{artist.quote}</td>
      <td>{artist.quotee}</td>

      <td>{moment(artist.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        {props.mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteArtist(artist)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/artist/edit/${artist.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/artist/view/${artist.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Artist;
