import {
  GET_PIECESINSTRUMENT_START,
  GET_PIECESINSTRUMENT_SUCCESS,
  GET_PIECESINSTRUMENT_ERROR,
  CREATE_PIECEINSTRUMENT_START,
  CREATE_PIECEINSTRUMENT_SUCCESS,
  CREATE_PIECEINSTRUMENT_ERROR,
  DELETE_PIECEINSTRUMENT_START,
  DELETE_PIECEINSTRUMENT_SUCCESS,
  DELETE_PIECEINSTRUMENT_ERROR,
} from '../actions/piece-instrument/piece-instrument.actiontype';

export default function(state = { list: [] }, action) {
  switch (action.type) {
    case GET_PIECESINSTRUMENT_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PIECESINSTRUMENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_PIECESINSTRUMENT_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_PIECEINSTRUMENT_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_PIECEINSTRUMENT_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: [...state.list, action.payload],
      };
    case CREATE_PIECEINSTRUMENT_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case 'EDIT_PIECEINSTRUMENT_START':
      return {
        ...state,
        isDeleting: true,
      };
    case 'EDIT_PIECEINSTRUMENT_SUCCESS':
      return {
        ...state,
        isDeleting: false,
        list: state.list.map(instrument => {
          if (instrument.id === action.payload.id) {
            return action.payload;
          }
          return instrument;
        }),
      };
    case 'EDIT_PIECEINSTRUMENT_ERROR':
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_PIECEINSTRUMENT_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_PIECEINSTRUMENT_SUCCESS:
      const newList = state.list.filter(pieceIns => pieceIns.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
        list: newList,
      };
    case DELETE_PIECEINSTRUMENT_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
}
