import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_PIECESINSTRUMENT_START,
  GET_PIECESINSTRUMENT_SUCCESS,
  GET_PIECESINSTRUMENT_ERROR,
  CREATE_PIECEINSTRUMENT_START,
  CREATE_PIECEINSTRUMENT_SUCCESS,
  CREATE_PIECEINSTRUMENT_ERROR,
  DELETE_PIECEINSTRUMENT_START,
  DELETE_PIECEINSTRUMENT_SUCCESS,
  DELETE_PIECEINSTRUMENT_ERROR,
} from './piece-instrument.actiontype';

import logs from '../../../components/Common/HistoryLogs';

import { getError } from '../../../utils/common.util';

export const getPiecesInstrument = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_PIECESINSTRUMENT_START,
    });
    const piecesInstrument = await axiosService.get('/pieces/piece-instruments');
    dispatch({
      type: GET_PIECESINSTRUMENT_SUCCESS,
      payload: piecesInstrument,
    });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({
      type: GET_PIECESINSTRUMENT_ERROR,
    });
  }
};

export const createPieceInstrument = (fields) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PIECEINSTRUMENT_START,
    });

    let updates = [];
    for (let k in fields) {
      if (!k.match(/Name/) && k != 'piece_id') {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    const pieceInstrument = await axiosService.post('/pieces/piece-instruments', fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Instruments',
      Action: 'CREATE',
      Details: updates
        .map(({ field, update }) => `${field.toUpperCase()}\n\n${update}`)
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully created.');
    dispatch({
      type: CREATE_PIECEINSTRUMENT_SUCCESS,
      payload: pieceInstrument,
    });
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({
      type: CREATE_PIECEINSTRUMENT_ERROR,
    });
  }
};

export const editPieceInstrument = (fields, orig) => async (dispatch) => {
  try {
    dispatch({
      type: 'EDIT_PIECEINSTRUMENT_START',
    });

    let updates = [];

    for (let k in fields) {
      if (orig[k] && typeof fields[k] !== 'object' && fields[k] !== orig[k]) {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          prev: k.match(/_id/) ? fields[k.split('_id')[0] + 'NamePrev'] : orig[k],
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    await axiosService.put('/pieces/piece-instruments/' + fields.id, fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Instruments',
      Action: 'UPDATE',
      Details: updates
        .map(
          ({ field, prev, update }) =>
            `${field.toUpperCase()}\n\n------Previous------ \n${prev}\n------Changed------ \n${update}`
        )
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully Updated.');
    dispatch({
      type: 'EDIT_PIECEINSTRUMENT_SUCCESS',
      payload: fields,
    });
    return Promise.resolve();
  } catch (error) {
    console.log(error);
    toast.error('Something Went Wrong');
    dispatch({
      type: 'CREATE_PIECEINSTRUMENT_ERROR',
    });
  }
};

export const deletePieceInstrument = (pieceInstrument) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this piece instrument ?')) return;

  try {
    dispatch({
      type: DELETE_PIECEINSTRUMENT_START,
    });
    const deletedPieceInstrument = await axiosService.delete(`/pieces/piece-instruments/${pieceInstrument.id}`);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Instruments',
      Action: 'DELETE',
      Details: `INSTRUMENT TITLE------------\n\n${pieceInstrument.title}`,
      Link: window.location.href,
    });

    toast.success('Successfully deleted.');

    dispatch({
      type: DELETE_PIECEINSTRUMENT_SUCCESS,
      payload: deletedPieceInstrument,
    });
  } catch (error) {
    if (error.code == 500) toast.error('Piece instrument deletion restricted.');
    else toast.error('Something Went Wrong');
    dispatch({
      type: DELETE_PIECEINSTRUMENT_ERROR,
    });
  }
};
