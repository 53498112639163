export const GET_COMPOSERS_START = 'GET_COMPOSERS_START';
export const GET_COMPOSERS_SUCCESS = 'GET_COMPOSERS_SUCCESS';
export const GET_COMPOSERS_ERROR = 'GET_COMPOSERS_ERROR';

export const EDIT_COMPOSER_START = 'EDIT_COMPOSER_START';
export const EDIT_COMPOSER_SUCCESS = 'EDIT_COMPOSER_SUCCESS';
export const EDIT_COMPOSER_ERROR = 'EDIT_COMPOSER_ERROR';

export const CREATE_COMPOSER_START = 'CREATE_COMPOSER_START';
export const CREATE_COMPOSER_SUCCESS = 'CREATE_COMPOSER_SUCCESS';
export const CREATE_COMPOSER_ERROR = 'CREATE_COMPOSER_ERROR';

export const DELETE_COMPOSER_START = 'DELETE_COMPOSER_START';
export const DELETE_COMPOSER_SUCCESS = 'DELETE_COMPOSER_SUCCESS';
export const DELETE_COMPOSER_ERROR = 'DELETE_COMPOSER_ERROR';
