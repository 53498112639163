import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteDifficulty, getDifficulties } from '../../store/actions/difficulties/difficulties.action';
import DifficultiesList from '../../components/Difficulties/Difficulties';

import '../../styles/piece.css';

class DifficultiesContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };
  componentDidMount() {
    this.props.getDifficulties();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'difficulties')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { deleteDifficulty, difficulties, isFetching } = this.props;
    const { accessDenied, mode } = this.state;

    if (isFetching) {
      return (
        <div className="loader">
          <svg viewBox="0 0 32 32" width="32" height="32">
            <circle id="spinner" cx="16" cy="16" r="14" fill="none" />
          </svg>
        </div>
      );
    }

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const difficultyInfo = { deleteDifficulty, difficulties };
    return (
      <section>
        <DifficultiesList mode={mode} difficultyInfo={difficultyInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.difficulties.isCreating,
    difficulties: state.difficulties.list,
    isFetching: state.difficulties.isFetching,
    difficultyToBeEdit: state.difficulties.difficultyToBeEdit,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  deleteDifficulty,
  getDifficulties,
};

export default connect(mapStateToProps, mapDispatchToProps)(DifficultiesContainer);
