import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import {
  createDifficulties,
  getDifficultyByID,
  editDifficulty,
} from '../../store/actions/difficulties/difficulties.action';
import CreateDifficulties from '../../components/Difficulties/CreateDifficulties';

class CreateDifficultiesContainer extends Component {
  state = {
    mode: 'CREATE',
    difficultyToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    this.setState({
      mode: params && params.id ? 'EDIT' : 'CREATE',
    });
    if (params.id) {
      this.getDifficulty(params.id);
    }

    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'difficulties')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  getDifficulty = async difficultyID => {
    try {
      const difficultyToBeEdit = await this.props.getDifficultyByID(difficultyID);
      this.setState(prevState => {
        return {
          ...prevState,
          difficultyToBeEdit,
        };
      });
    } catch (error) {
      history.push('/difficulties');
    }
  };

  render() {
    if (this.state.mode === 'EDIT' && !this.state.difficultyToBeEdit) {
      return (
        <div className="loader">
          <svg viewBox="0 0 32 32" width="32" height="32">
            <circle id="spinner" cx="16" cy="16" r="14" fill="none" />
          </svg>
        </div>
      );
    }

    const { accessDenied, accessMode } = this.state;
    const { createDifficulties, editDifficulty } = this.props;

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    return (
      <section>
        <CreateDifficulties
          accessMode={accessMode}
          onCreate={createDifficulties}
          onEdit={editDifficulty}
          initialValues={this.state.difficultyToBeEdit}
          mode={this.state.mode}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.difficulties.isCreating,
    difficultyToBeEdit: state.difficulties.difficultyToBeEdit,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  createDifficulties,
  getDifficultyByID,
  editDifficulty,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateDifficultiesContainer);
