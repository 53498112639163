import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Admin from './Admin';

const AdminsList = props => {
  const { admins, adminsAction } = props;
  const showAdmins = () => {
    return admins.map((admin, ind) => {
      return <Admin key={ind} admin={admin} deleteAdmin={adminsAction.deleteAdmin} />;
    });
  };

  return (
    <div className="box">
      <div className="box-header with-border">
        <h3 className="box-title">Admins</h3>
        <Link className="cus-btn-primary pull-right" to="/admins/create">
          + New Admin
        </Link>
      </div>
      <div className="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th className="t-10">Name</th>
              <th className="t-25">Email</th>
              <th className="t-15">Mobile</th>
              <th className="t-15">Created At</th>
              <th className="t-10">Role</th>
              <th className="t-10">Action</th>
            </tr>
          </thead>
          <tbody>{showAdmins()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminsList;
