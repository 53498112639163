import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { getPieces } from '../../store/actions/piece/piece.action';
import { createPieceVideo, getAwsKey } from '../../store/actions/piece-video/piece-video.action';

import CreatePieceVideo from '../../components/PiecesVideo/CreatePieceVideo';

import PageLoader from '../../components/Common/PageLoader';

class CreatePiecesContainer extends Component {
  state = {
    startRender: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    this.getPieceAttribute(params.id);
  }

  getPieceAttribute = pieceId => {
    const { getPieces } = this.props;
    Promise.all([getPieces()])
      .then(data => {
        this.setState(prevState => {
          return {
            ...prevState,
            startRender: true,
          };
        });
      })
      .catch(error => {
        history.push('/pieces-video');
      });
  };

  render() {
    if (!this.state.startRender) {
      return <PageLoader />;
    }
    const { createPieceVideo, pieces, isCreating, getAwsKey } = this.props;
    const pieceVideoInfo = { pieces, isCreating };
    return (
      <section>
        <CreatePieceVideo onCreate={createPieceVideo} pieceVideoInfo={pieceVideoInfo} getAwsKey={getAwsKey} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.pieceVideo.isCreating,
    pieces: state.piece.list,
  };
};
const mapDispatchToProps = {
  getPieces,
  createPieceVideo,
  getAwsKey,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePiecesContainer);
