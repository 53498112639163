import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deletePieceVideo, getPiecesVideo } from '../../store/actions/piece-video/piece-video.action';
import PiecesVideoList from '../../components/PiecesVideo/PiecesVideo';
import PageLoader from '../../components/Common/PageLoader';

class PiecesVideoContainer extends Component {
  componentDidMount() {
    this.props.getPiecesVideo();
  }

  render() {
    const { deletePieceVideo, piecesVideo, isFetching } = this.props;
    if (isFetching) {
      return <PageLoader />;
    }
    const pieceVideoInfo = { deletePieceVideo, piecesVideo };
    return (
      <section>
        <PiecesVideoList pieceVideoInfo={pieceVideoInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    piecesVideo: state.pieceVideo.list,
    isFetching: state.pieceVideo.isFetching,
  };
};
const mapDispatchToProps = {
  deletePieceVideo,
  getPiecesVideo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PiecesVideoContainer);
