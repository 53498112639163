import React from 'react';
import { Link } from 'react-router-dom';

import Subscription from './Subscription';

const Subscriptions = (props) => {
  const { subscriptions, deleteSubscription } = props.subscriptionsInfo;
  const { mode } = props;

  const getSubscriptions = () => {
    return subscriptions.map((subscription) => {
      const subscriptionInfo = { deleteSubscription, subscription };
      return <Subscription mode={mode} subscriptionInfo={subscriptionInfo} key={subscription.id} />;
    });
  };
  return (
    <div className="box">
      <div className="box-header with-border">
        <h3 className="box-title">Subscriptions</h3>
        {mode == 'edit' && (
          <Link className="cus-btn-primary pull-right" to="/subscription/create">
            Add Subscription
          </Link>
        )}
      </div>
      <div className="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th className="t-10">Name</th>
              <th className="t-5">Price</th>
              <th className="t-5">Month</th>
              <th className="t-5">Icon</th>
              <th className="t-20">Includes</th>
              <th className="t-15">Features</th>
              <th className="t-10">Created At</th>
              <th className="t-15">Action</th>
            </tr>
          </thead>
          <tbody>{getSubscriptions()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default Subscriptions;
