import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import difficultiesReducer from './difficulties.reducer';
import composerReducer from './composer.reducer';
import eraReducer from './era.reducer';
import instrumentReducer from './instrument.reducer';
import pieceReducer from './piece.reducer';
import pieceVideoReducer from './piece-video.reducer';
import pieceInstrumentReducer from './piece-instrument.reducer';
import authReducer from './auth.reducer';
import artistReducer from './artist.reducer';
import memberReducer from './member.reducer';
import subscriptionReducer from './subscription.reducer';
import couponReducer from './coupon.reducer';
import orderReducer from './order.reducer';
import adminsReducer from './admins.reducer';
import usersReducers from './users.reducer';
import featuresReducer from './feature.reducer';

export default combineReducers({
  form: formReducer,
  auth: authReducer,
  difficulties: difficultiesReducer,
  composer: composerReducer,
  era: eraReducer,
  instrument: instrumentReducer,
  piece: pieceReducer,
  pieceVideo: pieceVideoReducer,
  pieceInstrument: pieceInstrumentReducer,
  artist: artistReducer,
  member: memberReducer,
  subscription: subscriptionReducer,
  coupon: couponReducer,
  order: orderReducer,
  admin: adminsReducer,
  users: usersReducers,
  feature: featuresReducer,
});
