import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteComposer, getComposers } from '../../store/actions/composer/composer.action';
import ComposersList from '../../components/Composers/Composers';
import PageLoader from '../../components/Common/PageLoader';

class ComposersContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    this.props.getComposers();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'composers')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { deleteComposer, composers, isFetching } = this.props;
    const { accessDenied, mode } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const composerInfo = { deleteComposer, composers };
    return (
      <section>
        <ComposersList mode={mode} composerInfo={composerInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.composer.isCreating,
    composers: state.composer.list,
    isFetching: state.composer.isFetching,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  deleteComposer,
  getComposers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposersContainer);
