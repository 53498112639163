import {
  GET_MEMBERS_START,
  GET_MEMBERS_SUCCESS,
  GET_MEMBERS_ERROR,
  CREATE_MEMBER_START,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_ERROR,
  DELETE_MEMBER_START,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_ERROR,
  EDIT_MEMBER_START,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_ERROR,
} from '../actions/member/member.actiontype';

export default function(state = { list: [] }, action) {
  switch (action.type) {
    case GET_MEMBERS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_MEMBERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_MEMBERS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_MEMBER_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_MEMBER_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: [...state.list, action.payload],
      };
    case CREATE_MEMBER_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_MEMBER_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_MEMBER_SUCCESS:
      const newList = state.list.filter(pieceIns => pieceIns.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
        list: newList,
      };
    case DELETE_MEMBER_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    case EDIT_MEMBER_START:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_MEMBER_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: state.list.map(member => {
          if (member.id === action.payload.id) {
            return action.payload;
          }
          return member;
        }),
      };
    case EDIT_MEMBER_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    default:
      return state;
  }
}
