export const GET_PIECESVIDEO_START = 'GET_PIECESVIDEO_START';
export const GET_PIECESVIDEO_SUCCESS = 'GET_PIECESVIDEO_SUCCESS';
export const GET_PIECESVIDEO_ERROR = 'GET_PIECESVIDEO_ERROR';

export const CREATE_PIECEVIDEO_START = 'CREATE_PIECEVIDEO_START';
export const CREATE_PIECEVIDEO_SUCCESS = 'CREATE_PIECEVIDEO_SUCCESS';
export const CREATE_PIECEVIDEO_ERROR = 'CREATE_PIECEVIDEO_ERROR';

export const DELETE_PIECEVIDEO_START = 'DELETE_PIECEVIDEO_START';
export const DELETE_PIECEVIDEO_SUCCESS = 'DELETE_PIECEVIDEO_SUCCESS';
export const DELETE_PIECEVIDEO_ERROR = 'DELETE_PIECEVIDEO_ERROR';
