export const GET_COUPONS_START = 'GET_COUPONS_START';
export const GET_COUPONS_SUCCESS = 'GET_COUPONS_SUCCESS';
export const GET_COUPONS_ERROR = 'GET_COUPONS_ERROR';

export const EDIT_COUPON_START = 'EDIT_COUPON_START';
export const EDIT_COUPON_SUCCESS = 'EDIT_COUPON_SUCCESS';
export const EDIT_COUPON_ERROR = 'EDIT_COUPON_ERROR';

export const CREATE_COUPON_START = 'CREATE_COUPON_START';
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS';
export const CREATE_COUPON_ERROR = 'CREATE_COUPON_ERROR';

export const DELETE_COUPON_START = 'DELETE_COUPON_START';
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS';
export const DELETE_COUPON_ERROR = 'DELETE_COUPON_ERROR';
