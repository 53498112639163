import React from 'react';
import { Link } from 'react-router-dom';

import Instrument from './Instrument';

const ListInstrument = props => {
  const { instruments, deleteInstrument } = props.instrumentInfo;
  const { mode } = props;

  const getInstrument = () => {
    return instruments.map(instrument => {
      const instrumentInfo = { deleteInstrument, instrument };
      return <Instrument mode={mode} instrumentInfo={instrumentInfo} key={instrument.id} />;
    });
  };
  return (
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Instruments</h3>
        {mode == 'edit' && (
          <Link className="cus-btn-primary pull-right" to="/instrument/create">
            Add Instrument
          </Link>
        )}
      </div>
      <div class="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Sequence</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{getInstrument()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListInstrument;
