import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { required } from '../../utils/validation.util';
import { renderInput } from '../../utils/input.util';

class CreateEra extends Component {
  state = {};

  componentDidMount() {
    if (this.props.mode === 'EDIT') {
      this.setState({
        ...this.props.initialValues,
      });
    } else {
      this.setState({
        name: '',
      });
    }
  }

  onChangeSetToState = stateKey => e => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = () => {
    if (this.props.mode === 'EDIT') {
      this.props.onEdit(this.state);
      return;
    }

    this.props.onCreate(this.state);
  };

  render() {
    const { mode, isCreating, isLoading, accessMode } = this.props;
    return (
      <section className="well create-piece">
        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-md-3">
              <label>Name</label>
            </div>
            <div className="col-md-9">
              <Field
                name="name"
                placeholder="Name"
                component={renderInput}
                type="text"
                value={this.state.name}
                onChange={this.onChangeSetToState('name')}
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
              />
            </div>
            <div className="col-md-3">
              <label>Description</label>
            </div>
            <div className="col-md-9">
              <Field
                name="description"
                placeholder="Description"
                component={renderInput}
                type="text"
                value={this.state.description}
                onChange={this.onChangeSetToState('description')}
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
              />
            </div>
            <div className="col-md-3">
              <label>Order</label>
            </div>
            <div className="col-md-9">
              <Field
                name="sequence"
                placeholder="Sequence"
                component={renderInput}
                type="number"
                value={this.state.sequence}
                onChange={e => {
                  this.setState({ sequence: Number(e.target.value) });
                }}
                validate={[required]}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
              />
            </div>

            {accessMode == 'edit' && (
              <div className="create-piece__btn-container">
                <button className="cus-btn-primary btn-block btn-lg" type="submit" disabled={isCreating}>
                  {isCreating ? <span className="spinner" /> : <span>{mode === 'EDIT' ? 'Edit' : 'Create'}</span>}
                </button>
              </div>
            )}
          </div>
        </form>
      </section>
    );
  }
}

export default reduxForm({ form: 'createEra' })(CreateEra);
