import React from 'react';
import moment from 'moment';

const PieceInstrument = props => {
  const { deletePieceInstrument, pieceInstrument } = props.pieceInstrumentInfo;
  return (
    <tr>
      <td>{pieceInstrument.title}</td>
      <td>{pieceInstrument.piece_id}</td>
      <td>{pieceInstrument.instrument_id}</td>
      <td>{pieceInstrument.audio_file}</td>
      <td>{moment(pieceInstrument.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        <button className="btn btn-danger mr-15" onClick={() => deletePieceInstrument(pieceInstrument)}>
          Delete
        </button>
      </td>
    </tr>
  );
};

export default PieceInstrument;
