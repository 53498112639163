import React, { Component } from 'react';
import Placeholder from '../../assets/images/user-placeholder.png';
import Pagination from 'react-js-pagination';

class SingleUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        page: 1,
        total_entries_size: 0,
        itemsCountPP: 50,
      },
      newPassword:'',
      maskP:true
    };
  }

  componentDidMount() {
    try {
      let { pagination } = this.state;
      pagination.total_entries_size = this.props.user.orders.length;
      this.setState((prev) => ({
        ...prev,
        pagination,
      }));
    } catch (e) {}
  }

  handleState = (k,v) => {
    this.setState(p=>({
      ...p,
      [k]:v
    }))
  }

  renderUserProfile = (user) => {
    return (
      <div className="box">
        <div className="box-header with-border">
          <h3 className="box-title">User Information</h3>
        </div>
        <div className="userInfo">
          <div className="userData">
            <table className="table table-responsive table-hover">
              <tbody>
                <tr>
                  <th className="t-10">Name:</th>
                  <td>{user.name}</td>
                </tr>
                <tr>
                  <th className="t-10">Email:</th>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <th className="t-10">Mobile:</th>
                  <td>{user.mobile}</td>
                </tr>
                <tr>
                  <th className="t-10">Added On:</th>
                  <td>{new Date(user.created_at).toUTCString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="reset-password">
            <h4>Reset Password</h4>
            <div className="reset-block">
              <input type={this.state.maskP ? "password":"text"} value={this.state.newPassword} onChange={e=>this.handleState('newPassword',e.target.value)}/>
              <i className={`far ${this.state.maskP ? 'fa-eye':'fa-eye-slash'}`} onClick={()=>this.handleState('maskP',!this.state.maskP)}></i>    
            </div>
            {this.state.newPassword.length<8 ? <div className="comp">Enter atleast 8 characters</div>:''}
            <div className="btns">
              <div 
                className="def-pass"
                onClick={()=>{
                  this.handleState('maskP',false);
                  this.handleState('newPassword','Partplay@12345')
                }}>Use default password</div>
              <button className="cus-btn-primary btn-sm" onClick={this.resetPassword} disabled={this.state.newPassword.length<8}>Update</button>
            </div>
          </div>
          <div
            className="profilePic"
            style={{ backgroundImage: `url(${user.photo == '' ? Placeholder : user.photo})` }}
          ></div>
        </div>
      </div>
    );
  };

  renderCartInfo = (orders) => {
    if (!orders) return;

    let { pagination } = this.state;

    return orders
      .slice((pagination.page - 1) * pagination.itemsCountPP, pagination.page * pagination.itemsCountPP)
      .map((order, ind) => {
        return (
          <tr key={ind}>
            <td>{(pagination.page - 1) * pagination.itemsCountPP + ind + 1}</td>
            <td>{order.name}</td>
            <td>{order.company}</td>
            <td>{order.email}</td>
            <td>{order.phone}</td>
            <td>{order.street1}</td>
            <td>{order.street2}</td>
            <td>
              {order.city + ', '} {order.country}
            </td>
            <td>
              <ul>{order.pieces && order.pieces.map(({ piece }) => <li>{piece.title}</li>)}</ul>
            </td>
            <td>{new Date(order.created_at).toUTCString()}</td>
            <td>£{parseFloat(order.total).toFixed(2)}</td>
            <td>{order.status}</td>
          </tr>
        );
      });
  };

  handlePageChange = (page) => {
    let { pagination } = this.state;
    pagination.page = page;
    this.setState((prev) => ({
      ...prev,
      pagination,
    }));
  };

  resetPassword = () => {
    this.props.updatePassword({id:this.props.user.id,password:this.state.newPassword});
  }

  render() {
    const { user } = this.props;
    const { pagination } = this.state;
    return (
      <div className="singleUser">
        {this.renderUserProfile(user)}
        <div className="box list">
          <div className="box-header with-border">
            <h3 className="box-title">User Orders</h3>
          </div>
          {user.orders && user.orders.length > 0 ? (
            <div className="box-body">
              <Pagination
                activePage={pagination.page}
                itemsCountPerPage={pagination.itemsCountPP}
                totalItemsCount={pagination.total_entries_size}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              />
              <table className="table table-bordered table-responsive table-hover">
                <thead>
                  <tr>
                    <th className="t-10">S.No</th>
                    <th className="t-10">Name</th>
                    <th className="t-10">Company</th>
                    <th className="t-10">Email</th>
                    <th className="t-10">Phone</th>
                    <th className="t-10">Street 1</th>
                    <th className="t-10">Street 2</th>
                    <th className="t-10">City, Country</th>
                    <th className="t-10">Pieces</th>
                    <th className="t-10">Date Created</th>
                    <th className="t-10">Total</th>
                    <th className="t-10">Status</th>
                  </tr>
                </thead>
                <tbody>{this.renderCartInfo(user.orders)}</tbody>
              </table>
              <Pagination
                activePage={pagination.page}
                itemsCountPerPage={pagination.itemsCountPP}
                totalItemsCount={pagination.total_entries_size}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              />
            </div>
          ) : (
            <div className="no-orders">No orders place by the user yet.</div>
          )}
        </div>
      </div>
    );
  }
}

export default SingleUser;
