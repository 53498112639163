import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { createInstrument, getInstrumentByID, editInstrument } from '../../store/actions/instrument/instrument.action';
import CreateInstrument from '../../components/Instruments/CreateInstrument';

import { getAwsKey } from '../../store/actions/piece-video/piece-video.action';

import PageLoader from '../../components/Common/PageLoader';

class CreateInstrumentContainer extends Component {
  state = {
    mode: 'CREATE',
    instrumentToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };

  componentDidMount() {
    const { params } = this.props.match;
    this.setState({
      mode: params && params.id ? 'EDIT' : 'CREATE',
    });
    if (params.id) {
      this.getInstrument(params.id);
    }
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'instruments')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  getInstrument = async instrumentID => {
    try {
      const instrumentToBeEdit = await this.props.getInstrumentByID(instrumentID);
      this.setState(prevState => {
        return {
          ...prevState,
          instrumentToBeEdit,
        };
      });
    } catch (error) {
      history.push('/instruments');
    }
  };

  render() {
    if (this.state.mode === 'EDIT' && !this.state.instrumentToBeEdit) {
      return <PageLoader />;
    }

    const { accessDenied, accessMode } = this.state;

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const { createInstrument, editInstrument, isCreating, getAwsKey } = this.props;
    return (
      <section>
        <CreateInstrument
          accessMode={accessMode}
          onCreate={createInstrument}
          onEdit={editInstrument}
          initialValues={this.state.instrumentToBeEdit}
          mode={this.state.mode}
          isCreating={isCreating}
          getAwsKey={getAwsKey}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.instrument.isCreating,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  createInstrument,
  getInstrumentByID,
  editInstrument,
  getAwsKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateInstrumentContainer);
