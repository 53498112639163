import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_FEATURES_START,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_ERROR,
  CREATE_FEATURE_START,
  CREATE_FEATURE_SUCCESS,
  CREATE_FEATURE_ERROR,
  DELETE_FEATURE_START,
  DELETE_FEATURE_SUCCESS,
  DELETE_FEATURE_ERROR,
  EDIT_FEATURE_START,
  EDIT_FEATURE_SUCCESS,
  EDIT_FEATURE_ERROR,
} from './feature.actiontype';
import _ from 'lodash';

import { getError } from '../../../utils/common.util';

export const getFeatures = () => async (dispatch) => {
  try {
    dispatch({ type: GET_FEATURES_START });
    const features = _.sortBy(await axiosService.get('/features?per_page=100000000'), (f) => f.sequence);
    dispatch({ type: GET_FEATURES_SUCCESS, payload: features });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_FEATURES_ERROR });
  }
};

export const getFeatureByID = (featureId) => async (dispatch) => {
  try {
    const features = await axiosService.get(`/features/${featureId}`);
    return Promise.resolve(features);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const createFeature = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FEATURE_START });
    const sequence = (await axiosService.get('/features?per_page=100000000')).length + 1;
    const feature = await axiosService.post('/features', { ...fields, sequence });
    toast.success('Successfully created.');
    dispatch({ type: CREATE_FEATURE_SUCCESS, payload: feature });
    history.push('/features');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_FEATURE_ERROR });
  }
};

export const deleteFeature = ({ feature, features }) => async (dispatch) => {
  if (!window.confirm('Are you sure to delete this feature ?')) return;
  try {
    dispatch({ type: DELETE_FEATURE_START });
    const deletedfeature = await axiosService.delete(`/features/${feature.id}`);
    await Promise.all(
      features.slice(feature.sequence).map(async ({ id, sequence }) => {
        return await axiosService.put('/features/' + id, { sequence: sequence - 1 });
      })
    );
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_FEATURE_SUCCESS, payload: deletedfeature });
    return Promise.resolve();
  } catch (error) {
    if (error.code == 500) toast.error('This feature is already attached to a subscription. Deletion restricted');
    dispatch({ type: DELETE_FEATURE_ERROR });
    return Promise.resolve();
  }
};

export const editFeature = (feature) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_FEATURE_START });
    const newfeature = await axiosService.put(`/features/${feature.id}`, feature);
    toast.success('Successfully saved.');
    dispatch({ type: EDIT_FEATURE_SUCCESS, payload: newfeature });
    history.push('/features');
    return Promise.resolve();
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_FEATURE_ERROR });
    return Promise.resolve();
  }
};

export const changeOrder = (fields) => async (dispatch) => {
  // id,prevO,nextO,list
  let { id, prevO, nextO, list } = fields;

  list = _.sortBy(list, (l) => l.sequence);

  let adder = 1,
    s = nextO - 1,
    e = prevO - 1;
  if (nextO > prevO) {
    adder = -1;
    s = prevO;
    e = nextO;
  }

  try {
    await Promise.all(
      list.slice(s, e).map(async ({ id, sequence }) => {
        return await axiosService.put(`/features/${id}`, { sequence: sequence + adder });
      })
    );

    await axiosService.put(`/features/${id}`, { sequence: nextO });

    toast.success('Sequence updated');
    return Promise.resolve();
  } catch (err) {
    console.log(err);
    toast.success('Error occurred');
    return Promise.resolve();
  }
};
