import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_COUPONS_START,
  GET_COUPONS_SUCCESS,
  GET_COUPONS_ERROR,
  CREATE_COUPON_START,
  CREATE_COUPON_SUCCESS,
  CREATE_COUPON_ERROR,
  DELETE_COUPON_START,
  DELETE_COUPON_SUCCESS,
  DELETE_COUPON_ERROR,
  EDIT_COUPON_START,
  EDIT_COUPON_SUCCESS,
  EDIT_COUPON_ERROR,
} from './coupon.actiontype';

import { getError } from '../../../utils/common.util';
import { getLocalStorage } from '../../../utils/web-storage';

export const getCoupons = () => async (dispatch) => {
  try {
    const accessToken = getLocalStorage('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    dispatch({ type: GET_COUPONS_START });
    const coupons = await axiosService.get('/promotions');
    dispatch({ type: GET_COUPONS_SUCCESS, payload: coupons });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_COUPONS_ERROR });
  }
};

export const getCouponByID = (promotionId) => async (dispatch) => {
  try {
    const coupon = await axiosService.get(`/promotions/${promotionId}`);
    return Promise.resolve(coupon);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const createCoupon = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_COUPON_START });
    const coupon = await axiosService.post('/promotions', fields);
    toast.success('Successfully created.');
    dispatch({ type: CREATE_COUPON_SUCCESS, payload: coupon });
    history.push('/coupons');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_COUPON_ERROR });
  }
};

export const deleteCoupon = (coupon) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this coupon ?')) return;

  try {
    dispatch({ type: DELETE_COUPON_START });
    const deletedCoupon = await axiosService.delete(`/promotions/${coupon.id}`);
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_COUPON_SUCCESS, payload: deletedCoupon });
  } catch (error) {
    if (error.code == 500) toast.error('Coupon attached to a purchased order. Deletion restricted.');
    dispatch({ type: DELETE_COUPON_ERROR });
  }
};

export const editCoupon = (coupon) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_COUPON_START });
    const newCoupon = await axiosService.put(`/promotions/${coupon.id}`, coupon, {
      'Content-Type': 'application/json',
    });
    toast.success('Successfully saved.');
    dispatch({ type: EDIT_COUPON_SUCCESS, payload: newCoupon });
    history.push('/coupons');
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_COUPON_ERROR });
  }
};
