import React from 'react';
import { Link } from 'react-router-dom';

import Era from './Era';

const ListEras = props => {
  const { eras, deleteEra } = props.eraInfo;
  const { mode } = props;

  const getEras = () => {
    return eras.map(era => {
      const eraInfo = { deleteEra, era };
      return <Era mode={mode} eraInfo={eraInfo} key={era.id} />;
    });
  };
  return (
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Eras</h3>
        {mode == 'edit' && (
          <Link className="cus-btn-primary pull-right" to="/era/create">
            Add Era
          </Link>
        )}
      </div>
      <div class="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{getEras()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListEras;
