import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

class CreateMember extends Component {
  state = {
    name: '',
    biography: '',
    instrument_id: '',
    photo: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    instrument: null,
    photoUpload: false,
    isUploading: false,
  };

  onChangeSetToState = (stateKey) => (e) => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = async () => {
    const { photo, name, instrument, biography } = this.state;
    const instrument_id = instrument[0] && instrument[0].id;
    const artist_id = this.props.memberInfo.currentArtist.id;

    await this.props.memberInfo.createMember({ instrument_id, artist_id, photo, name, biography });
    this.props.memberInfo.getArtistByID('edit', artist_id);

    this.setState((prevState) => ({
      ...prevState,
      name: '',
      photo: '',
      file: {},
      awsKeys: {},
      disableUpload: false,
      instrument: null,
      photoUpload: false,
      isUploading: false,
      biography: '',
      MemberToEdit: {},
    }));
    this.typeahead.getInstance().clear();
  };
  onFileChange = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      photoUpload: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.memberInfo.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `artists/members/images/${fileName}` });
    this.setState(
      (prevState) => ({
        ...prevState,
        file,
        photo: `${awsKeys.action}${awsKeys.key}`,
        awsKeys,
        disableUpload: true,
      }),
      () => {
        if (this.state.MemberToEdit !== {}) {
          var PrintedFile = this.state.MemberToEdit;
          PrintedFile['photo'] = this.state.photo;
          this.setState({
            MemberToEdit: PrintedFile,
          });
        }
      }
    );
  };

  uploadAudio = (label) => async (eve) => {
    eve.preventDefault();
    if (label !== 'editMember' && !this.state.awsKeys.action) {
      toast.error('please try again.');
    }
    var form = document.getElementById('uploadPhoto');
    var data = new FormData(form);
    try {
      this.setState((prevState) => ({
        ...prevState,
        isUploading: true,
      }));
      if (label !== 'editMember') {
        await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
        toast.success('Audio uploaded successfully.');
      }

      this.setState((prevState) => ({
        ...prevState,
        photoUpload: true,
        isUploading: false,
      }));

      if (label === 'editMember') {
        this.editMember();
      }
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  editMember = async () => {
    const { artist_id, biography, instrument, instrument_id, id, photo, sequence, name } = this.state.MemberToEdit;
    // const piece_id = this.props.pieceInstrumentInfo.piece.id;
    await this.props.memberInfo.editMember({
      artist_id,
      biography,
      instrument,
      instrument_id,
      id,
      sequence: Number(sequence),
      name,
    });
    // this.props.pieceInstrumentInfo.getCurrentPiece('edit', piece_id);
    this.setState((prevState) => ({
      ...prevState,
      name: '',
      photo: '',
      file: {},
      awsKeys: {},
      disableUpload: false,
      instrument: null,
      photoUpload: false,
      isUploading: false,
      biography: '',
      MemberToEdit: {},
      showFollowing: null,
    }));
    this.props.change('title', '');
    this.typeahead.getInstance().clear();
  };

  onDelete = async (member) => {
    await this.props.memberInfo.deleteMember(member);
    const artist_id = this.props.memberInfo.currentArtist.id;
    this.props.memberInfo.getArtistByID('edit', artist_id);
  };

  Show = (i) => {
    const MemberToEditArray = this.props.memberInfo.currentArtist.members.filter((instrument, id) => {
      if (id == i) {
        return instrument;
      }
    });
    const MemberToEditObject = MemberToEditArray[0];
    this.setState({
      showFollowing: i,
      MemberToEdit: MemberToEditObject,
    });
  };

  getPieceInstrument = () => {
    return (
      this.props.memberInfo.currentArtist &&
      this.props.memberInfo.currentArtist.members &&
      this.props.memberInfo.currentArtist.members.map((member, i) => {
        return (
          <div key={i}>
            {this.state.showFollowing === i && (
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Name</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      placeholder="Name"
                      type="text"
                      value={this.state.MemberToEdit.name}
                      onChange={(e) => {
                        var instrumentX = this.state.MemberToEdit;
                        instrumentX['name'] = e.target.value;
                        this.setState({
                          MemberToEdit: instrumentX,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Biography</label>
                  </div>
                  <div className="col-md-9 form-group">
                    <input
                      placeholder="Biography"
                      type="text"
                      value={this.state.MemberToEdit.biography}
                      onChange={(e) => {
                        var instrumentX = this.state.MemberToEdit;
                        instrumentX['biography'] = e.target.value;
                        this.setState({
                          MemberToEdit: instrumentX,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Audio File</label>
                  </div>

                  <div className="col-md-9 form-group">
                    <form id="uploadFileEdit" method="post" encType="multipart/form-data">
                      {/* <input type="hidden" name="key" value={printedMusicAwsKeys.key || 'NA'} />
                      <input type="hidden" name="acl" value={printedMusicAwsKeys.acl || 'NA'} />
                      <input
                        type="hidden"
                        name="X-Amz-Credential"
                        value={printedMusicAwsKeys['x-amz-credential'] || 'NA'}
                      />
                      <input
                        type="hidden"
                        name="X-Amz-Algorithm"
                        value={printedMusicAwsKeys['x-amz-algorithm'] || 'NA'}
                      />
                      <input type="hidden" name="X-Amz-Date" value={printedMusicAwsKeys['x-amz-date'] || 'NA'} />
                      <input type="hidden" name="Policy" value={printedMusicAwsKeys.policy || 'NA'} />
                      <input
                        type="hidden"
                        name="X-Amz-Signature"
                        value={printedMusicAwsKeys['x-amz-signature'] || 'NA'}
                      /> */}
                      <input id="uploadPieceInstrumentPrinted" type="file" name="file" onChange={this.onFileChange} />
                    </form>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Order</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      placeholder="Instrument Title"
                      type="number"
                      value={this.state.MemberToEdit.sequence}
                      onChange={(e) => {
                        var instrumentOrder = this.state.MemberToEdit;
                        instrumentOrder['sequence'] = e.target.value;
                        this.setState({
                          MemberToEdit: instrumentOrder,
                        });
                      }}
                      className="form-control"
                    />
                  </div>
                </div>
                <div>
                  <form
                    id="uploadAudioEdit"
                    method="post"
                    encType="multipart/form-data"
                    onSubmit={this.uploadAudio('editMember')}
                  >
                    <button
                      type="submit"
                      name="submit"
                      className="cus-btn-primary btn-sm submitButton"
                      style={{ margin: '10px 10px 10px 10px' }}
                    >
                      {this.state.isUploading ? <span className="spinner" /> : <span>Save</span>}
                    </button>
                    <button
                      type="button"
                      name="button"
                      className="cus-btn-danger btn-sm submitButton"
                      style={{ margin: '10px 10px 10px 10px' }}
                      onClick={() => {
                        this.setState({
                          showFollowing: false,
                        });
                      }}
                    >
                      {this.state.isUploading ? <span className="spinner" /> : <span>Cancel</span>}
                    </button>
                  </form>
                </div>
              </div>
            )}

            {this.state.showFollowing !== i && (
              <div className="member-list" key={member.id}>
                {this.props.accessMode == 'edit' && (
                  <React.Fragment>
                    <span
                      className="glyphicon glyphicon-remove-circle remove"
                      aria-hidden="true"
                      onClick={() => this.onDelete(member)}
                    />

                    <span
                      //style={{ display: 'inline-block', float: 'right', marginRight: '10px', color: 'green' }}
                      className="glyphicon glyphicon-edit"
                      aria-hidden="true"
                      onClick={() => this.Show(i)}
                      style={{
                        fontSize: '19px',
                        display: 'inline-block',
                        color: 'green',
                        top: '-15px',
                        right: '15px',
                        position: 'absolute',
                      }}
                    />
                  </React.Fragment>
                )}

                <div className="row">
                  <div className="col-md-3">
                    <label>Name</label>
                  </div>
                  <div className="col-md-9">{member.name}</div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Biography</label>
                  </div>
                  <div className="col-md-9">{member.biography}</div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Audio File</label>
                  </div>
                  <div className="col-md-9">{member.photo}</div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Order</label>
                  </div>
                  <div className="col-md-9">{member.sequence}</div>
                </div>
                <hr />
              </div>
            )}
          </div>
        );
      })
    );
  };

  render() {
    const { isCreating, instruments } = this.props.memberInfo;
    const { accessMode } = this.props;
    const { awsKeys, disableUpload, name, biography, instrument } = this.state;
    return (
      <section className="well create-member">
        {this.getPieceInstrument()}

        {accessMode == 'edit' && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <label>Name</label>
              </div>
              <div className="col-md-9">
                <input
                  name="name"
                  placeholder="Name"
                  type="text"
                  value={name}
                  onChange={this.onChangeSetToState('name')}
                  className="form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <label>Biography</label>
              </div>
              <div className="col-md-9">
                <input
                  name="biography"
                  placeholder="Biography"
                  type="text"
                  value={biography}
                  onChange={this.onChangeSetToState('biography')}
                  className="form-control"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Instrument</label>
              </div>
              <div className="col-md-9">
                <Typeahead
                  onChange={(selected) => {
                    this.setState({
                      instrument: selected,
                    });
                  }}
                  labelKey="name"
                  options={instruments}
                  selected={instrument}
                  placeholder="Instrument"
                  ref={(typeahead) => (this.typeahead = typeahead)}
                />
              </div>

              <div className="col-md-3">
                <label>Photo</label>
              </div>
              <div className="col-md-9">
                <form id="uploadPhoto" method="post" encType="multipart/form-data" onSubmit={this.uploadAudio}>
                  <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                  <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                  <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                  <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                  <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                  <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                  <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                  <input type="file" name="file" onChange={this.onFileChange} /> <br />
                  <input
                    type="submit"
                    name="submit"
                    value="Upload to Amazon S3"
                    className="btn btn-default btn-upload"
                    disabled={!disableUpload}
                  />
                </form>
              </div>
              <div className="create-piece__btn-container">
                <button
                  className="btn btn-primary btn-block btn-lg"
                  type="submit"
                  onClick={this.onSubmit}
                  disabled={
                    isCreating || !(name && biography && instrument && instrument.length > 0 && this.state.photoUpload)
                  }
                >
                  {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Create</span>}
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createInstrument' })(CreateMember);
