import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';

import { required } from '../../utils/validation.util';
import { renderInput } from '../../utils/input.util';

class CreateInstrument extends Component {
  state = {
    name: '',
    description: '',
    icon: '',
    sequence: 1,
    img: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    imgUploaded: false,
    isUploading: false,
  };

  componentDidMount() {
    if (this.props.mode === 'EDIT') {
      this.setState({
        ...this.props.initialValues,
        imgUploaded: !!this.props.initialValues.icon,
      });
    } else {
      this.setState({
        name: '',
      });
    }
  }

  onChangeSetToState = (stateKey) => (e) => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = () => {
    if (this.props.mode === 'EDIT') {
      this.props.onEdit(this.state);
      return;
    }

    this.props.onCreate(this.state);
  };

  onFileChange = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      imgUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `instruments/images/${fileName}` });
    this.setState((prevState) => ({
      ...prevState,
      file,
      icon: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
  };

  uploadImg = async (eve) => {
    eve.preventDefault();
    if (!this.state.awsKeys.action) {
      toast.error('please try again.');
    }
    var form = document.getElementById('uploadImg');
    var data = new FormData(form);
    try {
      this.setState((prevState) => ({
        ...prevState,
        isUploading: true,
      }));
      await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
      toast.success('Image uploaded successfully.');
      this.setState((prevState) => ({
        ...prevState,
        imgUploaded: true,
        isUploading: false,
      }));
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  render() {
    const { mode, isCreating, accessMode } = this.props;
    const { awsKeys, disableUpload } = this.state;
    return (
      <section className="well create-piece">
        <div className="row">
          <div className="col-md-3">
            <label>Name</label>
          </div>
          <div className="col-md-9">
            <Field
              name="name"
              placeholder="Name"
              component={renderInput}
              type="text"
              value={this.state.name}
              onChange={this.onChangeSetToState('name')}
              validate={[required]}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              parentClass="form-group"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Description</label>
          </div>
          <div className="col-md-9">
            <Field
              name="description"
              placeholder="Description"
              component={renderInput}
              type="text"
              value={this.state.description}
              onChange={this.onChangeSetToState('description')}
              validate={[required]}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              parentClass="form-group"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Order</label>
          </div>
          <div className="col-md-9">
            <Field
              name="sequence"
              placeholder="Sequence"
              component={renderInput}
              type="number"
              value={this.state.sequence}
              onChange={(e) => {
                this.setState({ sequence: Number(e.target.value) });
              }}
              validate={[required]}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              parentClass="form-group"
            />
          </div>
        </div>
        {mode === 'EDIT' && this.state.icon ? (
          <div className="row">
            <div className="col-md-3">
              <label>Icon</label>
            </div>
            <div className="col-md-9">{this.state.icon}</div>
          </div>
        ) : null}

        {accessMode == 'edit' && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <label>Icon</label>
              </div>
              <div className="col-md-9">
                <form id="uploadImg" method="post" encType="multipart/form-data" onSubmit={this.uploadImg}>
                  <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                  <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                  <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                  <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                  <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                  <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                  <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                  <input type="file" name="file" onChange={this.onFileChange} />
                  <input
                    type="submit"
                    name="submit"
                    value="Upload to Amazon S3"
                    className="btn btn-default upload-btn"
                    disabled={!disableUpload}
                  />
                </form>
              </div>
            </div>
            <div className="create-piece__btn-container">
              <button
                className="btn cus-btn-primary btn-block btn-lg"
                type="submit"
                disabled={isCreating || !(this.state.name && this.state.description && this.state.imgUploaded)}
                onClick={this.onSubmit}
              >
                {isCreating || this.state.isUploading ? (
                  <span className="spinner" />
                ) : (
                  <span>{mode === 'EDIT' ? 'Update' : 'Create'}</span>
                )}
              </button>
            </div>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createEra' })(CreateInstrument);
