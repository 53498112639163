import React from 'react';
import { Link } from 'react-router-dom';

import PieceInstrument from './PieceInstrument';

const ListPiecesInstrument = props => {
  const { piecesInstrument, deletePieceInstrument } = props.pieceInstrumentInfo;

  const getPiecesInstrument = () => {
    return piecesInstrument.map(pieceInstrument => {
      const pieceInstrumentInfo = { deletePieceInstrument, pieceInstrument };
      return <PieceInstrument pieceInstrumentInfo={pieceInstrumentInfo} key={pieceInstrument.id} />;
    });
  };
  return (
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title"> Piece Instruments</h3>
        <Link className="btn btn-primary pull-right" to="/piece-instrument/create">
          Add Piece Instrument
        </Link>
      </div>
      <div class="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th>Title</th>
              <th>Piece</th>
              <th>Instrument</th>
              <th>Audio file</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{getPiecesInstrument()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListPiecesInstrument;
