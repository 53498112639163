import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { getArtists } from '../../store/actions/artist/artist.action';
import { getInstruments } from '../../store/actions/instrument/instrument.action';

import { getAwsKey } from '../../store/actions/piece-video/piece-video.action';
import { createMember, getMemberByID, editMember } from '../../store/actions/member/member.action';

import CreateMember from '../../components/Member/CreateMember';

import PageLoader from '../../components/Common/PageLoader';

class CreatePiecesContainer extends Component {
  state = {
    startRender: false,
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    const mode = params && params.id ? 'EDIT' : 'CREATE';
    this.getMembersAttribute(mode, params.id);
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'members')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  getMembersAttribute = (mode, memberId) => {
    const { getArtists, getInstruments } = this.props;
    Promise.all([getArtists(), getInstruments()])
      .then(data => {
        if (mode === 'EDIT') {
          return this.getMember(mode, memberId);
        }
        this.setState(prevState => {
          return {
            ...prevState,
            startRender: true,
          };
        });
      })
      .catch(error => {
        history.push('/members');
      });
  };

  getMember = async (mode, pieceID) => {
    try {
      const memberToBeEdit = await this.props.getMemberByID(pieceID);
      this.setState(prevState => {
        return {
          ...prevState,
          memberToBeEdit,
          mode,
          startRender: true,
        };
      });
    } catch (error) {
      history.push('/members');
    }
  };

  render() {
    if (!this.state.startRender) {
      return <PageLoader />;
    }
    const { accessMode } = this.state;
    const { createMember, artists, isCreating, getAwsKey, instruments, editMember } = this.props;
    const memberInfo = {
      artists,
      instruments,
      isCreating,
      getAwsKey,
      editMember,
      createMember,
      initialValues: this.state.memberToBeEdit,
      mode: this.state.mode,
    };
    return (
      <section>
        <CreateMember accessMode={accessMode} memberInfo={memberInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.pieceVideo.isCreating,
    artists: state.artist.list,
    instruments: state.instrument.list,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  getArtists,
  createMember,
  getAwsKey,
  getInstruments,
  getMemberByID,
  editMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePiecesContainer);
