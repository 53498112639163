import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { createCoupon, getCouponByID, editCoupon } from '../../store/actions/coupon/coupon.action';
import CreateCoupon from '../../components/Coupons/CreateCoupon';

import PageLoader from '../../components/Common/PageLoader';

class CreateCouponsContainer extends Component {
  state = {
    mode: 'CREATE',
    couponToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    this.setState({
      mode: params && params.id ? 'EDIT' : 'CREATE',
    });
    if (params.id) {
      this.getCoupon(params.id);
    }
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'coupons')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  getCoupon = async couponId => {
    try {
      const couponToBeEdit = await this.props.getCouponByID(couponId);
      this.setState(prevState => {
        return {
          ...prevState,
          couponToBeEdit,
        };
      });
    } catch (error) {
      history.push('/coupons');
    }
  };

  render() {
    if (this.state.mode === 'EDIT' && !this.state.couponToBeEdit) {
      return <PageLoader />;
    }

    const { accessDenied, accessMode } = this.state;

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const { createCoupon, editCoupon } = this.props;

    return (
      <section>
        <CreateCoupon
          accessMode={accessMode}
          onCreate={createCoupon}
          onEdit={editCoupon}
          initialValues={this.state.couponToBeEdit}
          mode={this.state.mode}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.coupon.isCreating,
    couponToBeEdit: state.coupon.couponToBeEdit,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  createCoupon,
  getCouponByID,
  editCoupon,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCouponsContainer);
