export const GET_SUBSCRIPTIONS_START = 'GET_SUBSCRIPTIONS_START';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR';

export const CREATE_SUBSCRIPTION_START = 'CREATE_SUBSCRIPTION_START';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR';

export const EDIT_SUBSCRIPTION_START = 'EDIT_SUBSCRIPTION_START';
export const EDIT_SUBSCRIPTION_SUCCESS = 'EDIT_SUBSCRIPTION_SUCCESS';
export const EDIT_SUBSCRIPTION_ERROR = 'EDIT_SUBSCRIPTION_ERROR';

export const DELETE_SUBSCRIPTION_START = 'DELETE_SUBSCRIPTION_START';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR';
export const DESTROY_CURRENT_SUBSCRIPTION = 'DESTROY_CURRENT_SUBSCRIPTION';
export const SET_CURRENT_SUBSCRIPTION = 'SET_CURRENT_SUBSCRIPTION';
