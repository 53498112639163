import React from 'react';
import { Link } from 'react-router-dom';

import Faq from './Faq';

const ListFaqs = props => {
  const { faqs, deleteFaq, mode } = props;
  const getFaq = () => {
    return faqs.map(faq => {
      const faqInfo = { deleteFaq, faq };
      return <Faq mode={mode} faqInfo={faqInfo} key={faqInfo.faq.id} />;
    });
  };
  return (
    <div>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Question</th>
            <th>Answer</th>
            <th>Category</th>
            <th>Created At</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{getFaq()}</tbody>
      </table>
    </div>
  );
};

export default ListFaqs;
