export const GET_INSTRUMENTS_START = 'GET_INSTRUMENTS_START';
export const GET_INSTRUMENTS_SUCCESS = 'GET_INSTRUMENTS_SUCCESS';
export const GET_INSTRUMENTS_ERROR = 'GET_INSTRUMENTS_ERROR';

export const EDIT_INSTRUMENT_START = 'EDIT_INSTRUMENT_START';
export const EDIT_INSTRUMENT_SUCCESS = 'EDIT_INSTRUMENT_SUCCESS';
export const EDIT_INSTRUMENT_ERROR = 'EDIT_INSTRUMENT_ERROR';

export const CREATE_INSTRUMENT_START = 'CREATE_INSTRUMENT_START';
export const CREATE_INSTRUMENT_SUCCESS = 'CREATE_INSTRUMENT_SUCCESS';
export const CREATE_INSTRUMENT_ERROR = 'CREATE_INSTRUMENT_ERROR';

export const DELETE_INSTRUMENT_START = 'DELETE_INSTRUMENT_START';
export const DELETE_INSTRUMENT_SUCCESS = 'DELETE_INSTRUMENT_SUCCESS';
export const DELETE_INSTRUMENT_ERROR = 'DELETE_INSTRUMENT_ERROR';
