import { toast, ToastType } from 'react-toastify';
import history from '../../../inits/history';
import _ from 'lodash';
import axios from 'axios';
import axiosService from '../../../inits/axios';
import {
  USERS_LIST_START,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  SINGLE_USER_START,
  SINGLE_USER_SUCCESS,
  SINGLE_USER_ERROR,
  SINGLE_USER_CREATE_START,
  SINGLE_USER_CREATE_END,
} from './users.actiontype';
import { getLocalStorage } from '../../../utils/web-storage';
import { getError } from '../../../utils/common.util';

const REACT_APP_PARTPLAY_SERVICE_URL = process.env.REACT_APP_PARTPLAY_SERVICE_URL;

export const getUsers = (page, per_page = 50) => async (dispatch) => {
  // per page 50 entries
  try {
    dispatch({
      type: USERS_LIST_START,
    });

    const accessToken = getLocalStorage('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    let users = await axios({
      url: '/accounts',
      baseURL: REACT_APP_PARTPLAY_SERVICE_URL,
      headers: config.headers,
      params: {
        per_page,
        page,
      },
    });

    let pagination = JSON.parse(users.headers['x-pagination']);

    dispatch({
      type: USERS_LIST_SUCCESS,
      payload: {
        list: users.data,
        pagination,
      },
    });
  } catch (error) {
    toast.error(error.message || 'Unable to fetch users');
    dispatch({
      type: USERS_LIST_ERROR,
    });
  }
};

export const getSingleUser = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: SINGLE_USER_START,
    });

    const accessToken = getLocalStorage('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let user = await axiosService.get('/accounts/' + userId);

    let orders = _.sortBy(
      await axiosService.get('/orders', {
        user_id: userId,
        per_page: 10e9,
      }),
      (o) => -new Date(o.created_at).getTime()
    );

    dispatch({
      type: SINGLE_USER_SUCCESS,
      payload: {
        ...user,
        orders,
      },
    });
  } catch (error) {
    toast.error(error.message || 'Unable to fetch user information');
    dispatch({
      type: SINGLE_USER_ERROR,
    });
  }
};

export const registerUser = (fields) => (dispatch) => {
  dispatch({ type: SINGLE_USER_CREATE_START });
  axiosService
    .post('/register', { ...fields, source: 'web' })
    .then((data) => {
      dispatch({ type: SINGLE_USER_CREATE_END });
      toast.success('User Registered successfully');
      history.push('/users');
    })
    .catch(({ data }) => {
      dispatch({ type: SINGLE_USER_CREATE_END });
      toast.error('Error occured');
    });
};

export const updatePassword = (fields) => dispatch=> {
  const {id} = fields;
  delete fields['id'];
  axiosService.put('/accounts/'+id,fields)
  .then(()=>(
    toast.success('Password Reset')
  ))
  .catch(e=>{
    console.log(e);
    toast.error('Error occured while password reset');
  })
}
