import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_DIFFICULTIES_START,
  GET_DIFFICULTIES_SUCCESS,
  GET_DIFFICULTIES_ERROR,
  CREATE_DIFFICULTIES_START,
  CREATE_DIFFICULTIES_SUCCESS,
  CREATE_DIFFICULTIES_ERROR,
  DELETE_DIFFICULTY_START,
  DELETE_DIFFICULTY_SUCCESS,
  DELETE_DIFFICULTY_ERROR,
  EDIT_DIFFICULTY_START,
  EDIT_DIFFICULTY_SUCCESS,
  EDIT_DIFFICULTY_ERROR,
} from './difficulties.actiontype';

import { getError } from '../../../utils/common.util';

export const getDifficulties = () => async (dispatch) => {
  try {
    dispatch({ type: GET_DIFFICULTIES_START });
    const difficulties = await axiosService.get('/pieces/difficulties');
    dispatch({ type: GET_DIFFICULTIES_SUCCESS, payload: difficulties });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_DIFFICULTIES_ERROR });
  }
};

export const getDifficultyByID = (difficultyId) => async (dispatch) => {
  try {
    const difficulties = await axiosService.get(`/pieces/difficulties/${difficultyId}`);
    return Promise.resolve(difficulties);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const createDifficulties = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_DIFFICULTIES_START });
    const difficulty = await axiosService.post('/pieces/difficulties', fields);
    toast.success('Successfully created.');
    dispatch({ type: CREATE_DIFFICULTIES_SUCCESS, payload: difficulty });
    history.push('/difficulties');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_DIFFICULTIES_ERROR });
  }
};

export const deleteDifficulty = (difficulty) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this difficulty ?')) return;

  try {
    dispatch({ type: DELETE_DIFFICULTY_START });
    const deletedDifficulty = await axiosService.delete(`/pieces/difficulties/${difficulty.id}`);
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_DIFFICULTY_SUCCESS, payload: deletedDifficulty });
  } catch (error) {
    if (error.code == 500) toast.error('Difficulty already attached to a piece. Deletion restricted.');
    dispatch({ type: DELETE_DIFFICULTY_ERROR });
  }
};

export const editDifficulty = (difficulty) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_DIFFICULTY_START });
    const newDifficulty = await axiosService.put(`/pieces/difficulties/${difficulty.id}`, difficulty);
    toast.success('Successfully saved.');
    dispatch({ type: EDIT_DIFFICULTY_SUCCESS, payload: newDifficulty });
    history.push('/difficulties');
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_DIFFICULTY_ERROR });
  }
};
