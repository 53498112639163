import React, { Component, useEffect, useState } from 'react';
import _ from 'lodash';

export default function Overview(props) {
  const [state, setState] = useState({ pieceCount: 0, paidPieceCount: 0 });

  useEffect(() => {
    const pieceCount = props.pieces.length,
      paidPieceCount = _.filter(props.pieces, (d) => Number(d.price)).length;

    setState({ pieceCount, paidPieceCount });
  }, []);

  return (
    <div className="data-overview">
      <div className="box-header with-border">
        <h3 className="box-title">Overview</h3>
      </div>
      <div className="items">
        <div className="data-box">
          <div className="count">{state.pieceCount}</div>
          <div className="type">Pieces</div>
        </div>

        <div className="data-box">
          <div className="count">{state.paidPieceCount}</div>
          <div className="type">Priced Pieces</div>
        </div>
      </div>
    </div>
  );
}
