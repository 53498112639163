import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

import { renderInput } from '../../utils/input.util';

class CreateInterview extends Component {
  state = {
    audio_file: '',
    audio: '',
    order: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    audioUploaded: false,
    isUploading: false,
    interviewInputOn: false,
    interviewInputEditOn: false,
  };

  onChangeSetToState = (stateKey) => (e) => {
    let updates = {
      [stateKey]: e.currentTarget.value.trim(),
    };
    if (['audio_file'].includes(stateKey)) {
      updates.disableUpload = true;
    }
    this.setState((prev) => ({ ...prev, ...updates }));
  };

  onSubmit = async () => {
    const { audio_file: audio, title, artist, order } = this.state;
    const piece_id = this.props.currentPiece.id;

    await this.props.onCreate({ audio, title, artist: artist[0].name, order, piece_id });
    this.setState((prevState) => ({
      ...prevState,
      audio_file: '',
      audio: '',
      order: '',
      file: {},
      awsKeys: {},
      disableUpload: false,
      audioUploaded: false,
      isUploading: false,
      title: '',
      artist: [],
      interviewInputOn: false,
      interviewInputEditOn: false,
      InterviewToEdit: null,
    }));
    this.props.change('title', '');
    try {
      document.getElementById('uploadPieceInterview').value = null;
    } catch (e) {}
  };
  onFileChange = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      audioUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `pieces/interviews/videos/${fileName}` });
    this.setState((prevState) => ({
      ...prevState,
      file,
      audio_file: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
    if (this.state.InterviewToEdit) {
      var AudioFile = this.state.InterviewToEdit;
      AudioFile['audio'] = `${awsKeys.action}${awsKeys.key}`;
      this.setState({
        InterviewToEdit: AudioFile,
      });
    }
  };

  uploadAudio = (label) => async (eve) => {
    eve.preventDefault();
    if (label === 'editInterview') {
      if (Object.keys(this.state.awsKeys).length) {
        var form = document.getElementById('uploadInterviewAudioEdit');
        var data = new FormData(form);
      }
    } else {
      if (Object.keys(this.state.awsKeys).length) {
        var form = document.getElementById('uploadInterviewAudio');
        var data = new FormData(form);
      }
    }

    try {
      this.setState((prevState) => ({
        ...prevState,
        isUploading: true,
      }));

      if (Object.keys(this.state.awsKeys).length) {
        await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
        toast.success('Audio uploaded successfully.');
      }
      this.setState((prevState) => ({
        ...prevState,
        audioUploaded: true,
      }));
      if (label === 'editInterview') {
        this.editInterview();
      } else {
        this.onSubmit();
      }
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };
  editInterview = async () => {
    let interview = { ...this.state.InterviewToEdit };
    interview.artist = interview.artist[0].name;

    await this.props.onEdit(interview, this.props.pieceInterviews[this.state.showFollowing]);
    this.setState((prevState) => ({
      ...prevState,
      instrument_id: '',
      audio_file: '',
      audio: '',
      order: 1,
      file: {},
      awsKeys: {},
      disableUpload: false,
      instrument: null,
      audioUploaded: false,
      isUploading: false,
      title: '',
      showFollowing: false,
      interviewInputOn: false,
      interviewInputEditOn: false,
    }));
    this.props.change('title', '');
    this.props.triggerGetPiece();
  };

  onDelete = async (interview) => {
    await this.props.onDelete(interview);
  };
  Show = (i) => {
    const { piecesInfo } = this.props;
    const InterviewToEditArray = this.props.pieceInterviews.filter((interview, id) => {
      if (id == i) {
        return interview;
      }
    });
    const InterviewToEditObject = { ...InterviewToEditArray[0] };
    InterviewToEditObject.artist = piecesInfo.members.filter(({ name }) => name == InterviewToEditObject.artist);

    this.setState({
      showFollowing: i,
      InterviewToEdit: JSON.parse(JSON.stringify(InterviewToEditObject)),
    });
  };

  handleStateChange = (key, val) => (e) => {
    e.stopPropagation();
    let updates = { [key]: val };
    if (['interviewInputEditOn', 'interviewInputOn'].includes(key)) {
      updates['audio_file'] = '';
      updates['awsKeys'] = {};
    }
    this.setState((prev) => ({ ...prev, ...updates }));
  };

  handleEditStateChange = (key, value) => {
    let Interview = this.state.InterviewToEdit;
    Interview[key] = value;
    this.setState((prev) => ({
      ...prev,
      InterviewToEdit: Interview,
    }));
  };

  getPieceInterviews = () => {
    const { isCreating, artistInfo, accessMode, piecesInfo } = this.props;
    const { awsKeys, disableUpload, interviewInputEditOn } = this.state;
    return this.props.pieceInterviews.map((interview, i) => {
      return (
        <div className="instrument-list" key={interview.id}>
          {accessMode == 'edit' && (
            <div className="row">
              <span
                style={{ display: 'inline-block', float: 'right', color: 'red' }}
                className="glyphicon glyphicon-remove-circle"
                aria-hidden="true"
                onClick={() => this.onDelete(interview)}
              />
              <span
                style={{ display: 'inline-block', float: 'right', marginRight: '10px', color: 'green' }}
                className="glyphicon glyphicon-edit"
                aria-hidden="true"
                onClick={() => this.Show(i)}
              />
            </div>
          )}

          {this.state.showFollowing === i && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Title</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="title"
                    placeholder="Title"
                    type="text"
                    value={this.state.InterviewToEdit.title}
                    onChange={(e) => this.handleEditStateChange('title', e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Artist</label>
                </div>
                <div className="col-md-9">
                  <Typeahead
                    onChange={(selected) => this.handleEditStateChange('artist', selected)}
                    labelKey="name"
                    options={piecesInfo.members}
                    selected={this.state.InterviewToEdit.artist}
                    className={`form-group ${accessMode == 'view' ? 'disabled' : ''}`}
                    placeholder="Artist"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <label>Order</label>
                </div>
                <div className="col-md-9">
                  <input
                    name="order"
                    placeholder="Order"
                    type="number"
                    value={this.state.InterviewToEdit.order}
                    onChange={(e) => this.handleEditStateChange('order', Number(e.target.value))}
                    className="form-control"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <label>Video File</label>
                </div>
                <div className="col-md-9 radio-part">
                  <div className="currentFile">{this.state.InterviewToEdit.audio}</div>

                  <div className="radio-selection row">
                    <div className="col-md-4">
                      <input
                        type="radio"
                        id="linkTypeVideoEdit"
                        name="videoTypeEdit"
                        checked={!interviewInputEditOn}
                        onChange={this.handleStateChange('interviewInputEditOn', false)}
                      />
                      &nbsp;
                      <label htmlFor="linkTypeVideoEdit">Link</label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="radio"
                        id="uploadTypeVideoEdit"
                        name="videoTypeEdit"
                        checked={interviewInputEditOn}
                        onChange={this.handleStateChange('interviewInputEditOn', true)}
                      />
                      &nbsp;
                      <label htmlFor="uploadTypeVideoEdit">Upload</label>
                    </div>
                  </div>

                  {interviewInputEditOn ? (
                    <form id="uploadInterviewAudioEdit" method="post" encType="multipart/form-data">
                      <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                      <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                      <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                      <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                      <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                      <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                      <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                      <input id="uploadPieceInterview" type="file" name="file" onChange={this.onFileChange} />
                    </form>
                  ) : (
                    <input
                      name="video"
                      placeholder="Video File"
                      value={this.state.InterviewToEdit.audio}
                      onChange={(e) => this.handleEditStateChange('audio', e.target.value)}
                      className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                      parentClass="form-group"
                    />
                  )}
                </div>
              </div>

              <div className="editButtons">
                <button
                  type="submit"
                  name="submit"
                  className="cus-btn-primary btn-sm submitButton"
                  style={{ margin: '10px 10px 10px 10px' }}
                  onClick={this.uploadAudio('editInterview')}
                >
                  {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Save</span>}
                </button>
                <button
                  type="button"
                  name="button"
                  className="cus-btn-danger btn-sm submitButton"
                  style={{ margin: '10px 10px 10px 10px' }}
                  onClick={() => {
                    this.setState({
                      showFollowing: false,
                      InterviewToEdit: null,
                    });
                  }}
                >
                  {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Cancel</span>}
                </button>
              </div>
              <hr />
            </div>
          )}
          {this.state.showFollowing !== i && (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Title</label>
                </div>
                <div className="col-md-9">{interview.title}</div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Artist</label>
                </div>
                <div className="col-md-9">{interview.artist}</div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Order</label>
                </div>
                <div className="col-md-9">{interview.order}</div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Video File</label>
                </div>
                <div className="col-md-9">{interview.audio}</div>
              </div>
              <hr />
            </div>
          )}
        </div>
      );
    });
  };

  render() {
    const { isCreating, artistInfo, accessMode, piecesInfo } = this.props;
    const { awsKeys, disableUpload, interviewInputOn } = this.state;
    return (
      <section className="well create-instrument">
        {this.getPieceInterviews()}

        {accessMode == 'edit' && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <label>Title</label>
              </div>
              <div className="col-md-9">
                <Field
                  name="title"
                  placeholder="Title"
                  component={renderInput}
                  type="text"
                  value={this.state.title}
                  onChange={this.onChangeSetToState('title')}
                  className="form-control"
                  parentClass="form-group"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <label>Artist</label>
              </div>
              <div className="col-md-9">
                <Typeahead
                  onChange={(selected) => {
                    this.setState({
                      ...this.state,
                      artist: selected,
                    });
                  }}
                  labelKey="name"
                  options={piecesInfo.members}
                  selected={this.state.artist}
                  className={`form-group ${accessMode == 'view' ? 'disabled' : ''}`}
                  placeholder="Artist"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <label>Order</label>
              </div>
              <div className="col-md-9">
                <Field
                  name="order"
                  placeholder="Order"
                  component={renderInput}
                  type="number"
                  value={this.state.order}
                  onChange={(e) => {
                    this.setState({ order: Number(e.target.value) });
                  }}
                  className="form-control"
                  parentClass="form-group"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-3">
                <label>Audio</label>
              </div>
              <div className="col-md-9 radio-part">
                <div className="radio-selection row">
                  <div className="col-md-4">
                    <input
                      type="radio"
                      id="linkTypeVideo"
                      name="videoType"
                      checked={!interviewInputOn}
                      onChange={this.handleStateChange('interviewInputOn', false)}
                    />
                    &nbsp;
                    <label htmlFor="linkTypeVideo">Link</label>
                  </div>
                  <div className="col-md-4">
                    <input
                      type="radio"
                      id="uploadTypeVideo"
                      name="videoType"
                      checked={interviewInputOn}
                      onChange={this.handleStateChange('interviewInputOn', true)}
                    />
                    &nbsp;
                    <label htmlFor="uploadTypeVideo">Upload</label>
                  </div>
                </div>

                {interviewInputOn ? (
                  <form id="uploadInterviewAudio" method="post" encType="multipart/form-data">
                    <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                    <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                    <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                    <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                    <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                    <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                    <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                    <input id="uploadPieceInterview" type="file" name="file" onChange={this.onFileChange} />
                  </form>
                ) : (
                  <input
                    name="video"
                    placeholder="Video File"
                    value={this.state.audio_file}
                    onChange={this.onChangeSetToState('audio_file')}
                    className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                    parentClass="form-group"
                  />
                )}
              </div>
            </div>

            <button
              type="submit"
              name="submit"
              onClick={this.uploadAudio('newInterview')}
              className="cus-btn-primary btn-block btn-lg submitButton"
              disabled={isCreating || !(disableUpload && this.state.artist && this.state.title)}
            >
              {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Add Interview</span>}
            </button>
          </React.Fragment>
        )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createInterview' })(CreateInterview);
