import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Faq = props => {
  const { deleteFaq, faq } = props.faqInfo;
  const { mode } = props;
  return (
    <tr>
      <td>{faq.question}</td>
      <td>{faq.answer.toString().replace(/<[^>]*>/g, '')}</td>
      <td>{faq.category}</td>
      <td>{moment(faq.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        {mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteFaq(faq)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/faq/edit/${faq.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/faq/view/${faq.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Faq;
