import {
  GET_INSTRUMENTS_START,
  GET_INSTRUMENTS_SUCCESS,
  GET_INSTRUMENTS_ERROR,
  CREATE_INSTRUMENT_START,
  CREATE_INSTRUMENT_SUCCESS,
  CREATE_INSTRUMENT_ERROR,
  DELETE_INSTRUMENT_START,
  DELETE_INSTRUMENT_SUCCESS,
  DELETE_INSTRUMENT_ERROR,
  EDIT_INSTRUMENT_START,
  EDIT_INSTRUMENT_SUCCESS,
  EDIT_INSTRUMENT_ERROR,
} from '../actions/instrument/instrument.actiontype';

export default function(state = { list: [] }, action) {
  switch (action.type) {
    case GET_INSTRUMENTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_INSTRUMENTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_INSTRUMENTS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_INSTRUMENT_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_INSTRUMENT_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: [...state.list, action.payload],
      };
    case CREATE_INSTRUMENT_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case EDIT_INSTRUMENT_START:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_INSTRUMENT_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: state.list.map(instrument => {
          if (instrument.id === action.payload.id) {
            return action.payload;
          }
          return instrument;
        }),
      };
    case EDIT_INSTRUMENT_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_INSTRUMENT_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_INSTRUMENT_SUCCESS:
      const newList = state.list.filter(instrument => instrument.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
        list: newList,
      };
    case DELETE_INSTRUMENT_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
}
