export const GET_ARTISTS_START = 'GET_ARTISTS_START';
export const GET_ARTISTS_SUCCESS = 'GET_ARTISTS_SUCCESS';
export const GET_ARTISTS_ERROR = 'GET_ARTISTS_ERROR';

export const CREATE_ARTIST_START = 'CREATE_ARTIST_START';
export const CREATE_ARTIST_SUCCESS = 'CREATE_ARTIST_SUCCESS';
export const CREATE_ARTIST_ERROR = 'CREATE_ARTIST_ERROR';

export const EDIT_ARTIST_START = 'EDIT_ARTIST_START';
export const EDIT_ARTIST_SUCCESS = 'EDIT_ARTIST_SUCCESS';
export const EDIT_ARTIST_ERROR = 'EDIT_ARTIST_ERROR';

export const DELETE_ARTIST_START = 'DELETE_ARTIST_START';
export const DELETE_ARTIST_SUCCESS = 'DELETE_ARTIST_SUCCESS';
export const DELETE_ARTIST_ERROR = 'DELETE_ARTIST_ERROR';
export const DESTROY_CURRENT_ARTIST = 'DESTROY_CURRENT_ARTIST';
export const SET_CURRENT_ARTIST = 'SET_CURRENT_ARTIST';
