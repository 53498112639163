import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { removeUser } from '../../store/actions/auth/auth.action';
import { getCategories } from '../../store/actions/admins/admins.action';
import { setCategories } from '../../store/actions/auth/auth.action';
import _ from 'lodash';

import '../../utils/tree.util.js';
import '../../utils/pushmenu.util.js';

import userPlaceholder from '../../assets/images/user-placeholder.png';
import '../../styles/sidebar.css';

import logo from '../../assets/images/logo.png';
import logoNoText from '../../assets/images/logoNoText.png';

class Sidebar extends Component {
    componentDidMount() {
        this.props.getCategories().then(() => {
            let { user, allCategories } = this.props,
                categories = allCategories;

            if (user.role == 'subadmin') {
                let { assigned_categories } = user;
                assigned_categories = assigned_categories.split(',');
                categories = _.filter(categories, ({ id }) => {
                    return assigned_categories.includes(id + '|edit') || assigned_categories.includes(id + '|view');
                });

                categories = categories.map((category) => {
                    let cat = _.filter(
                        assigned_categories,
                        (cat) => cat == category.id + '|view' || cat == category.id + '|edit'
                    )[0];
                    return {
                        ...category,
                        mode: cat.split('|')[1],
                    };
                });
            } else if (user.role == 'admin') {
                categories = categories.map((category) => {
                    return {
                        ...category,
                        mode: 'edit',
                    };
                });
            }
            categories = _.sortBy(categories, ['sequence']);
            this.props.setCategories(categories);
        });
    }

    componentDidUpdate(props) {}

    render() {
        const { user, sidebarOpen, categories } = this.props;
        return user ? (
            <aside className='main-sidebar'>
                <section className='sidebar'>
                    <div className='user-panel'>
                        <span className='logo-lg'>
                            <img
                                src={sidebarOpen ? logo : logoNoText}
                                style={sidebarOpen ? { width: '150px' } : { width: '30px' }}
                                alt='logo'
                            />
                        </span>
                    </div>
                    <ul className='sidebar-menu' data-widget='tree'>
                        {categories &&
                            categories.map(({ name, link }, ind) => {
                                return (
                                    <li key={ind}>
                                        <NavLink activeClassName='active' to={'/' + link}>
                                            <i className='fa fa-dashboard' /> <span>{name}</span>
                                        </NavLink>
                                    </li>
                                );
                            })}

                        {this.props.user.role == 'admin' && (
                            <React.Fragment>
                                <li>
                                    <NavLink activeClassName='active' to='/admins'>
                                        <i className='fa fa-dashboard' /> <span>Manage Admins</span>
                                    </NavLink>
                                </li>

                                {false && (
                                    <li>
                                        <NavLink activeClassName='active' to='/new-category'>
                                            <i className='fa fa-dashboard' /> <span>New Category</span>
                                        </NavLink>
                                    </li>
                                )}
                            </React.Fragment>
                        )}
                    </ul>

                    <ul className='sidebar-menu logout' data-widget='tree'>
                        <li onClick={(e) => this.props.logout()}>
                            <NavLink activeClassName='active' to=''>
                                <i className='fa fa-dashboard' /> <span>Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </section>
            </aside>
        ) : null;
    }
}

const mapStateToProps = ({ auth, admin }) => {
    return {
        user: auth.user,
        allCategories: admin.categories,
        categories: auth.categories,
    };
};
const mapDispatchToProps = {
    logout: removeUser,
    getCategories,
    setCategories,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sidebar));
