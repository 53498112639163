import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteArtist, getArtists } from '../../store/actions/artist/artist.action';
import Artists from '../../components/Artist/Artists';
import PageLoader from '../../components/Common/PageLoader';

class PiecesInstrumentContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    this.props.getArtists();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'artists')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { deleteArtist, artists, isFetching } = this.props;
    const { accessDenied, mode } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }
    const artistsInfo = { deleteArtist, artists };
    return (
      <section className="artist-list">
        <Artists mode={mode} artistsInfo={artistsInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    artists: state.artist.list,
    isFetching: state.artist.isFetching,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  deleteArtist,
  getArtists,
};

export default connect(mapStateToProps, mapDispatchToProps)(PiecesInstrumentContainer);
