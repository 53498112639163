import React from 'react';
import { Link } from 'react-router-dom';

const Coupon = props => {
  const { deleteCoupon, coupon } = props.couponInfo;
  const { mode } = props;
  return (
    <tr>
      <td>{coupon.code}</td>
      <td>{coupon.type}</td>
      <td>{coupon.amount}</td>
      <td>{coupon.is_valid ? 'Valid' : 'Invalid'}</td>
      <td>
        {mode == 'edit' ? (
          <React.Fragment>
            <button className="cus-btn-danger mr-15" onClick={() => deleteCoupon(coupon)}>
              Delete
            </button>
            <Link className="cus-btn-primary" to={`/coupon/edit/${coupon.id}`}>
              Edit
            </Link>
          </React.Fragment>
        ) : (
          <Link className="cus-btn-primary" to={`/coupon/view/${coupon.id}`}>
            View
          </Link>
        )}
      </td>
    </tr>
  );
};

export default Coupon;
