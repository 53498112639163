export const getSignupError = error => {
  const { email, mobile, password } = error.errors;
  return (
    email[0] || mobile[0] || password[0] || 'Something went wrong while processing your request. Please try again.'
  );
};

export const getError = error => {
  let errorMessage = '';
  Object.keys(error.errors).forEach(errorKey => {
    errorMessage = error.errors[errorKey].join(', ');
    errorMessage += '\n';
  });
  return errorMessage;
};
