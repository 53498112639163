export const GET_ERAS_START = 'GET_ERAS_START';
export const GET_ERAS_SUCCESS = 'GET_ERAS_SUCCESS';
export const GET_ERAS_ERROR = 'GET_ERAS_ERROR';

export const EDIT_ERA_START = 'EDIT_ERA_START';
export const EDIT_ERA_SUCCESS = 'EDIT_ERA_SUCCESS';
export const EDIT_ERA_ERROR = 'EDIT_ERA_ERROR';

export const CREATE_ERA_START = 'CREATE_ERA_START';
export const CREATE_ERA_SUCCESS = 'CREATE_ERA_SUCCESS';
export const CREATE_ERA_ERROR = 'CREATE_ERA_ERROR';

export const DELETE_ERA_START = 'DELETE_ERA_START';
export const DELETE_ERA_SUCCESS = 'DELETE_ERA_SUCCESS';
export const DELETE_ERA_ERROR = 'DELETE_ERA_ERROR';
