import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import DateRangeFilter from '../DateRangeFilter';

function UsersSubscriptionsChart(props) {
  const [state, setState] = useState({
    labels: [],
    data: [],
    startDate: new Date('2018-01-01'),
    loadedData: false,
    mapSubsUsersCount: {},
  });

  const { subscriptions, users } = props;

  useEffect(() => {
    if (!state.loadedData) setUsersSubscriptionsData();
    else applyDateFilter();
  }, [state.startDate]);

  const applyDateFilter = () => {
    let data = [...Array(state.labels.length).keys()].map(() => 0),
      { mapSubsUsersCount } = state;

    users.forEach(({ Subscription }) => {
      if (new Date(Subscription.created_at).getTime() >= state.startDate.getTime())
        data[mapSubsUsersCount[Subscription.subscription_id]]++;
    });

    setState({ ...state, data });
  };

  const setUsersSubscriptionsData = () => {
    let mapSubsUsersCount = {},
      labels = _.map(
        _.sortBy(subscriptions, (s) => -Number(s.price)),
        ({ name, id }, ind) => {
          mapSubsUsersCount[id] = ind;
          return name;
        }
      );

    let data = [...Array(labels.length).keys()].map(() => 0);

    users.forEach(({ Subscription }) => {
      data[mapSubsUsersCount[Subscription.subscription_id]]++;
    });
    setState({ ...state, labels, data, mapSubsUsersCount, loadedData: true });
  };

  const setStartDate = (startDate) => {
    if (!startDate) startDate = new Date('2018-01-01');
    setState({ ...state, startDate });
  };

  const options = {
    title: {
      display: true,
      text: 'Number of users under subscription',
      fontSize: 20,
    },
    scales: {
      xAxes: [
        {
          // Change here
          barPercentage: 0.2,
        },
      ],
    },
  };

  return (
    <div className="piece-subscription-chart chart">
      <DateRangeFilter data={{ startDate: state.startDate, setStartDate }} />
      <Bar
        width={600}
        data={{
          labels: state.labels,
          datasets: [
            {
              label: 'Users',
              backgroundColor: '#5568ab',
              borderColor: '#5568ab',
              borderWidth: 1,
              data: state.data,
            },
          ],
        }}
        options={options}
      />
    </div>
  );
}

export default UsersSubscriptionsChart;
