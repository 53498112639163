import React, { Component } from 'react';
import { getPieces } from '../../store/actions/piece/piece.action';
import { getSubscriptions } from '../../store/actions/subscription/subscription.action';
import { getUsers } from '../../store/actions/users/users.action';
import { connect } from 'react-redux';
import Overview from '../../components/Analytics/Data/Overview';
import PieceSubscriptionsChart from '../../components/Analytics/Data/PieceSubscriptionsChart';
import UsersSubscriptionsChart from '../../components/Analytics/Data/UsersSubscriptionChart';
import PageLoader from '../../components/Common/PageLoader';
import '../../styles/analytics.scss';

class DataAnalyticsContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getPieces();
    this.props.getSubscriptions();
    this.props.getUsers(1, 10e9);
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'analytics')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { accessDenied } = this.state;
    const { pieces_list, isFetching, subscriptions_list, users_list } = this.props;

    if (isFetching) {
      return <PageLoader />;
    }
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    return (
      <div className="data-analytics-container">
        <Overview pieces={pieces_list} />
        <PieceSubscriptionsChart pieces={pieces_list} subscriptions={subscriptions_list} />
        <UsersSubscriptionsChart users={users_list} subscriptions={subscriptions_list} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pieces_list: state.piece.list,
  categories: state.auth.categories,
  isFetching: state.piece.isFetching || state.subscription.isFetching || state.users.isFetching,
  subscriptions_list: state.subscription.list,
  users_list: state.users.list,
});

const mapDispatchToProps = {
  getPieces,
  getSubscriptions,
  getUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataAnalyticsContainer);
