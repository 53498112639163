import {
  GET_PIECES_START,
  GET_PIECES_SUCCESS,
  GET_PIECES_ERROR,
  CREATE_PIECE_START,
  CREATE_PIECE_SUCCESS,
  CREATE_PIECE_ERROR,
  DELETE_PIECE_START,
  DELETE_PIECE_SUCCESS,
  DELETE_PIECE_ERROR,
  EDIT_PIECE_START,
  EDIT_PIECE_SUCCESS,
  EDIT_PIECE_ERROR,
  GET_CURRENTPIECES,
  DESTROY_CURRENTPIECES,
  SET_HOME_PIECE_ID,
  SET_PIECES_CATEGORIES
} from '../actions/piece/piece.actiontype';

export default function (
  state = {
    list: [],
    currentPiece: null,
    homePieceId: null,
    pieces_categories:[
      'original',
      'christmas'
    ]
  },
  action
) {
  switch (action.type) {
    case GET_PIECES_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PIECES_SUCCESS:
      return {
        ...state,
        isFetching: false,
          list: action.payload,
      };
    case GET_PIECES_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case SET_PIECES_CATEGORIES:
      return {
        ...state,
        pieces_categories:action.payload
      }
    case CREATE_PIECE_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_PIECE_SUCCESS:
      return {
        ...state,
        isCreating: false,
          list: [...state.list, action.payload],
          currentPiece: action.payload,
      };
    case CREATE_PIECE_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case EDIT_PIECE_START:
      return {
        ...state,
        isCreating: true,
      };
    case EDIT_PIECE_SUCCESS:
      return {
        ...state,
        isCreating: false,
          list: state.list.map((piece) => {
            if (piece.id === action.payload.id) {
              return action.payload;
            }
            return piece;
          }),
      };
    case EDIT_PIECE_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case DELETE_PIECE_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_PIECE_SUCCESS:
      const newList = state.list.filter((piece) => piece.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
          list: newList,
      };
    case DELETE_PIECE_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    case GET_CURRENTPIECES:
      return {
        ...state,
        currentPiece: action.payload,
      };
    case DESTROY_CURRENTPIECES:
      return {
        ...state,
        currentPiece: action.payload,
      };

    case SET_HOME_PIECE_ID:
      return {
        ...state,
        homePieceId: action.payload,
      };
    default:
      return state;
  }
}
