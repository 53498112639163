import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import logo from '../../assets/images/logo.png';
import logoSmall from '../../assets/images/logo-small.png';

import '../../styles/header.css';
import userPlaceholder from '../../assets/images/user-placeholder.png';
class Header extends Component {
    componentDidUpdate(prevProp) {
        if (prevProp.sidebarOpen != this.props.sidebarOpen) {
            let fixedEl = document.querySelector('.fixedElements').classList,
                mainH = document.querySelector('.main-header').classList,
                mainS = document.querySelector('.main-sidebar').classList;

            if (!this.props.sidebarOpen) {
                fixedEl.add('expandView');
                mainH.add('header-expand');
                mainS.add('sidebar-shrink');
            } else {
                fixedEl.remove('expandView');
                mainH.remove('header-expand');
                mainS.remove('sidebar-shrink');
            }
        }
    }

    render() {
        const { user, toggleSidebar } = this.props;

        return user ? (
            <header className='main-header'>
                <nav className='navbar navbar-static-top'>
                    <div className='search-sidebar-toggle'>
                        <a className='sidebar-toggle' onClick={toggleSidebar} role='button'>
                            <span className='sr-only'>Toggle navigation</span>
                        </a>
                    </div>

                    <div className='navbar-custom-menu'>
                        <ul className='nav navbar-nav'>
                            <li className='dropdown user user-menu'>
                                <a href='#' className='dropdown-toggle' data-toggle='dropdown'>
                                    <img src={userPlaceholder} className='user-image' alt='User Image' />
                                    <span className='hidden-xs user-name'>{user.name || user.email}</span>
                                </a>
                                <ul className='dropdown-menu'>
                                    <li className='user-header'>
                                        <img src='dist/img/user2-160x160.jpg' className='img-circle' alt='User Image' />
                                    </li>
                                    <li className='user-footer'>
                                        <div className='pull-left'>
                                            <a href='#' className='btn btn-default btn-flat'>
                                                Profile
                                            </a>
                                        </div>
                                        <div className='pull-right'>
                                            <a href='#' className='btn btn-default btn-flat'>
                                                Sign out
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        ) : null;
    }
}

const mapStateToProps = ({ auth }) => {
    return {
        user: auth.user,
    };
};

export default withRouter(connect(mapStateToProps)(Header));
