import React, { Component } from 'react';
import { connect } from 'react-redux';

import history from '../../inits/history';
import { createComposer, getComposerByID, editComposer } from '../../store/actions/composer/composer.action';
import CreateComposer from '../../components/Composers/CreateComposer';

import PageLoader from '../../components/Common/PageLoader';

class CreateComposersContainer extends Component {
  state = {
    mode: 'CREATE',
    composerToBeEdit: null,
    accessMode: '',
    accessDenied: false,
  };
  componentDidMount() {
    const { params } = this.props.match;
    this.setState({
      mode: params && params.id ? 'EDIT' : 'CREATE',
    });
    if (params.id) {
      this.getComposer(params.id);
    }
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'composers')[0];
      this.setState({ ...this.state, accessMode: mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  getComposer = async composerID => {
    try {
      const composerToBeEdit = await this.props.getComposerByID(composerID);
      this.setState(prevState => {
        return {
          ...prevState,
          composerToBeEdit,
        };
      });
    } catch (error) {
      history.push('/composers');
    }
  };

  render() {
    if (this.state.mode === 'EDIT' && !this.state.composerToBeEdit) {
      return <PageLoader />;
    }

    const { accessDenied, accessMode } = this.state;
    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const { createComposer, editComposer } = this.props;
    return (
      <section>
        <CreateComposer
          accessMode={accessMode}
          onCreate={createComposer}
          onEdit={editComposer}
          initialValues={this.state.composerToBeEdit}
          mode={this.state.mode}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreating: state.composer.isCreating,
    composerToBeEdit: state.composer.composerToBeEdit,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  createComposer,
  getComposerByID,
  editComposer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateComposersContainer);
