export const GET_DIFFICULTIES_START = 'GET_DIFFICULTIES_START';
export const GET_DIFFICULTIES_SUCCESS = 'GET_DIFFICULTIES_SUCCESS';
export const GET_DIFFICULTIES_ERROR = 'GET_DIFFICULTIES_ERROR';

export const EDIT_DIFFICULTY = 'EDIT_DIFFICULTY';
export const EDIT_DIFFICULTY_START = 'EDIT_DIFFICULTY_START';
export const EDIT_DIFFICULTY_SUCCESS = 'EDIT_DIFFICULTY_SUCCESS';
export const EDIT_DIFFICULTY_ERROR = 'EDIT_DIFFICULTY_ERROR';

export const CREATE_DIFFICULTIES_START = 'CREATE_DIFFICULTIES_START';
export const CREATE_DIFFICULTIES_SUCCESS = 'CREATE_DIFFICULTIES_SUCCESS';
export const CREATE_DIFFICULTIES_ERROR = 'CREATE_DIFFICULTIES_ERROR';

export const DELETE_DIFFICULTY_START = 'DELETE_DIFFICULTY_START';
export const DELETE_DIFFICULTY_SUCCESS = 'DELETE_DIFFICULTY_SUCCESS';
export const DELETE_DIFFICULTY_ERROR = 'DELETE_DIFFICULTY_ERROR';
