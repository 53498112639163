import React from 'react';
import Order from './Order';

const ListOrders = props => {
  const { orders, deleteOrder } = props.orderInfo;
  const { mode } = props;
  const getOrder = () => {
    return orders.map(order => {
      const orderInfo = { deleteOrder, order };
      return <Order mode={mode} orderInfo={orderInfo} key={order.id} />;
    });
  };
  return (
    <div class="box">
      <div class="box-header with-border">
        <h3 class="box-title">Orders</h3>
      </div>
      <div class="box-body">
        <table className="table table-bordered table-responsive table-hover">
          <thead>
            <tr>
              <th>Order Id</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
              <th>Paid At</th>
              <th>Total</th>
              <th>Subscription Month</th>
              <th>Subscription Price</th>
            </tr>
          </thead>
          <tbody>{getOrder()}</tbody>
        </table>
      </div>
    </div>
  );
};

export default ListOrders;
