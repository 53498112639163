import {
  GET_PIECESVIDEO_START,
  GET_PIECESVIDEO_SUCCESS,
  GET_PIECESVIDEO_ERROR,
  CREATE_PIECEVIDEO_START,
  CREATE_PIECEVIDEO_SUCCESS,
  CREATE_PIECEVIDEO_ERROR,
  DELETE_PIECEVIDEO_START,
  DELETE_PIECEVIDEO_SUCCESS,
  DELETE_PIECEVIDEO_ERROR,
} from '../actions/piece-video/piece-video.actiontype';

export default function(state = { list: [] }, action) {
  switch (action.type) {
    case GET_PIECESVIDEO_START:
      return {
        ...state,
        isFetching: true,
      };
    case GET_PIECESVIDEO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.payload,
      };
    case GET_PIECESVIDEO_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case CREATE_PIECEVIDEO_START:
      return {
        ...state,
        isCreating: true,
      };
    case CREATE_PIECEVIDEO_SUCCESS:
      return {
        ...state,
        isCreating: false,
        list: [...state.list, action.payload],
      };
    case CREATE_PIECEVIDEO_ERROR:
      return {
        ...state,
        isCreating: false,
      };
    case 'EDIT_PIECEVIDEO_START':
      return {
        ...state,
        isDeleting: true,
      };
    case 'EDIT_PIECEVIDEO_SUCCESS':
      return {
        ...state,
        isDeleting: false,
        list: state.list.map(video => {
          if (video.id === action.payload.id) {
            return action.payload;
          }
          return video;
        }),
      };
    case 'EDIT_PIECEVIDEO_ERROR':
      return {
        ...state,
        isDeleting: false,
      };
    case DELETE_PIECEVIDEO_START:
      return {
        ...state,
        isDeleting: true,
      };
    case DELETE_PIECEVIDEO_SUCCESS:
      const newList = state.list.filter(piece => piece.id !== action.payload.id);
      return {
        ...state,
        isDeleting: false,
        list: newList,
      };
    case DELETE_PIECEVIDEO_ERROR:
      return {
        ...state,
        isDeleting: false,
      };
    default:
      return state;
  }
}
