import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

import { renderInput } from '../../utils/input.util';

let subscriptionIdNameMap = {};

class CreateSubscription extends Component {
  state = {
    instrument_id: '',
    audio_file: '',
    audio: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    instrument: null,
    audioUploaded: false,
    isUploading: false,
    EditInstrument: true,
    subscriptions: JSON.parse(JSON.stringify(this.props.pieceSubscriptions)),
    subscriptionsToRemove: [],
    isUpdating: false,
  };
  componentDidMount() {
    this.props.subscriptions.forEach(({ id, name }) => (subscriptionIdNameMap[id] = name));
  }
  onChangeSetToState = (stateKey) => (e) => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = async () => {};

  editInstrument = async () => {};
  addSubscription = (subscription) => {
    var obj = this.state.subscriptions.find((o) => (o.subscription_id || o.id) === subscription.id);
    var obj2 = this.state.subscriptionsToRemove.find((o) => (o.subscription_id || o.id) === subscription.id);
    if (obj === undefined) {
      this.setState((prevState) => ({
        subscriptions: [...prevState.subscriptions, subscription],
      }));
    } else {
      var arrToRemove = this.state.subscriptionsToRemove;
      var arr = this.state.subscriptions;
      var index = arr.indexOf(obj);
      var abcd = arr.splice(index, 1);
      this.setState({
        subscriptions: arr,
      });
      if (obj2 === undefined) {
        arrToRemove.push(abcd);
      } else {
        var index2 = arrToRemove.indexOf(obj2);
        arrToRemove.splice(index2, 1);
      }
      this.setState({
        subscriptionsToRemove: [...arrToRemove],
      });
    }
  };
  submit = async () => {
    this.setState((prev) => ({ ...prev, isUpdating: true }));
    await this.props.pieceAddDelete(
      this.state.subscriptions.map((data) => ({
        ...data,
        sname: subscriptionIdNameMap[data.subscription_id ? data.subscription_id : data.id],
      })),
      this.state.subscriptionsToRemove.map((data) => ({
        ...data,
        sname: subscriptionIdNameMap[data.subscription_id ? data.subscription_id : data.id],
      })),
      this.props.currentPiece.id,
      this.props.pieceSubscriptions.map(({ subscription_id }) => ({ sname: subscriptionIdNameMap[subscription_id] }))
    );
    this.setState((prev) => ({ ...prev, isUpdating: false }));
  };
  render() {
    const { isCreating, pieceInstrumentInfo, accessMode } = this.props;
    const { awsKeys, disableUpload, isUpdating } = this.state;
    return (
      <section className="well create-instrument">
        <table className="table table-striped" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              {accessMode == 'edit' && <th></th>}
            </tr>
          </thead>
          <tbody>
            {this.props.subscriptions.map((subscription, i) => (
              <tr key={i}>
                <td>{subscription.name}</td>
                <td>{subscription.price}</td>
                {accessMode == 'edit' && (
                  <td>
                    <input
                      type="checkbox"
                      checked={this.state.subscriptions.find((o) => (o.subscription_id || o.id) === subscription.id)}
                      onChange={() => this.addSubscription(subscription)}
                    />
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {accessMode == 'edit' && (
          <button className="btn-lg cus-btn-success" onClick={this.submit}>
            {isUpdating ? <span className="spinner" /> : 'Save'}
          </button>
        )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createInstrument' })(CreateSubscription);
