import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { toast } from 'react-toastify';

import { renderInput } from '../../utils/input.util';

class CreatePieceVideo extends Component {
  state = {
    video_file: '',
    video: '',
    quality_level: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    videoUploaded: false,
    isUploading: false,
    videoInputOn: false,
    videoInputEditOn: false,
  };

  onChangeSetToState = (stateKey) => (e) => {
    let updates = {
      [stateKey]: e.currentTarget.value.trim(),
    };

    if (['video_file'].includes(stateKey)) {
      updates.disableUpload = true;
    }
    this.setState((prev) => ({ ...prev, ...updates }));
  };

  onSubmit = async () => {
    const { video_file, quality_level } = this.state;
    const piece_id = this.props.pieceInstrumentInfo.piece.id;

    await this.props.onCreate({ piece_id, video_file, quality_level: parseInt(quality_level, 10) });
    this.props.pieceInstrumentInfo.getCurrentPiece('edit', piece_id);
    this.setState((prevState) => ({
      ...prevState,
      video_file: '',
      video: '',
      quality_level: '',
      file: {},
      awsKeys: {},
      disableUpload: false,
      videoUploaded: false,
      isUploading: false,
      videoInputOn: false,
      VideoToEdit: null,
      videoInputEditOn: false,
    }));
    this.props.change('qualityLevel', '');
    try {
      document.getElementById('uploadPieceVideo').value = null;
    } catch (e) {}
  };
  onFileChange = async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      videoUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.pieceInstrumentInfo.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `pieces/videos/${fileName}` });
    this.setState((prevState) => ({
      ...prevState,
      file,
      awsKeys,
      disableUpload: true,
    }));
    if (this.state.VideoToEdit) {
      var VideoFile = this.state.VideoToEdit;
      VideoFile['video_file'] = `${awsKeys.action}${awsKeys.key}`;
      this.setState({
        InstrumentToEdit: VideoFile,
      });
    } else {
      this.setState((prev) => ({
        ...prev,
        video_file: `${awsKeys.action}${awsKeys.key}`,
      }));
    }
  };

  uploadVideo = (label) => async (eve) => {
    eve.preventDefault();
    if ((this.state.videoInputOn || this.state.videoInputEditOn) && !this.state.awsKeys.action) {
      toast.error('please try again.');
    }
    if (label === 'editVideo') {
      if (Object.keys(this.state.awsKeys).length) {
        var form = document.getElementById('videoUploadEdit');
        var data = new FormData(form);
      }
    } else {
      if (Object.keys(this.state.awsKeys).length) {
        var form = document.getElementById('videoUpload');
        var data = new FormData(form);
      }
    }
    try {
      this.setState((prevState) => ({
        ...prevState,
        isUploading: true,
      }));

      if (Object.keys(this.state.awsKeys).length) {
        await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
      }

      toast.success('Video uploaded successfully.');
      this.setState((prevState) => ({
        ...prevState,
        videoUploaded: true,
      }));
      if (label === 'editVideo') {
        this.editVideo();
      } else {
        this.onSubmit();
      }
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  editVideo = async () => {
    const { video_file, quality_level, id } = this.state.VideoToEdit;
    const piece_id = this.props.pieceInstrumentInfo.piece.id;
    await this.props.onEdit(
      this.state.VideoToEdit,
      this.props.pieceInstrumentInfo.piece.videos[this.state.showFollowing]
    );
    this.props.pieceInstrumentInfo.getCurrentPiece('edit', piece_id);
    this.setState((prevState) => ({
      ...prevState,
      instrument_id: '',
      video_file: '',
      audio: '',
      file: {},
      awsKeys: {},
      disableUpload: false,
      instrument: null,
      audioUploaded: false,
      isUploading: false,
      title: '',
      showFollowing: false,
      videoInputOn: false,
      VideoToEdit: null,
      videoInputEditOn: false,
    }));
    this.props.change('title', '');
  };

  onDelete = async (video) => {
    await this.props.onDelete(video);
    const piece_id = this.props.pieceInstrumentInfo.piece.id;
    this.props.pieceInstrumentInfo.getCurrentPiece('edit', piece_id);
  };
  Show = (i) => {
    const VideoToEditArray = this.props.pieceInstrumentInfo.piece.videos.filter((video, id) => {
      if (id == i) {
        return video;
      }
    });
    const VideoToEditObject = VideoToEditArray[0];
    this.setState({
      showFollowing: i,
      VideoToEdit: JSON.parse(JSON.stringify(VideoToEditObject)),
    });
  };

  handleStateChange = (key, val) => (e) => {
    e.stopPropagation();
    let updates = { [key]: val };
    if (['videoInputEditOn', 'videoInputOn'].includes(key)) {
      updates['video_file'] = '';
      updates['awsKeys'] = {};
    }
    this.setState((prev) => ({ ...prev, ...updates }));
  };

  handleEditStateChange = (key, value) => {
    var VideoQuality = this.state.VideoToEdit;
    VideoQuality[key] = value;
    this.setState((prev) => ({
      ...prev,
      VideoToEdit: VideoQuality,
    }));
  };

  getPieceVideo = () => {
    const { isCreating, pieceInstrumentInfo, accessMode } = this.props;
    const { awsKeys, disableUpload, videoInputEditOn } = this.state;
    return (
      this.props.pieceInstrumentInfo.piece &&
      this.props.pieceInstrumentInfo.piece.videos &&
      this.props.pieceInstrumentInfo.piece.videos.map((video, i) => {
        return (
          <div className="instrument-list" key={video.id}>
            {accessMode == 'edit' && (
              <div className="row">
                <span
                  style={{ display: 'inline-block', float: 'right', color: 'red' }}
                  className="glyphicon glyphicon-remove-circle"
                  aria-hidden="true"
                  onClick={() => this.onDelete(video)}
                />
                <span
                  style={{ display: 'inline-block', float: 'right', marginRight: '10px', color: 'green' }}
                  className="glyphicon glyphicon-edit"
                  aria-hidden="true"
                  onClick={() => this.Show(i)}
                />
              </div>
            )}
            {this.state.showFollowing !== i && (
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Quality</label>
                  </div>
                  <div className="col-md-9">{video.quality_level}</div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Video File</label>
                  </div>
                  <div className="col-md-9">{video.video_file}</div>
                </div>
                <hr />
              </div>
            )}
            {this.state.showFollowing === i && (
              <div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Quality</label>
                  </div>
                  <div className="col-md-9">
                    <input
                      name="qualityLevel"
                      placeholder="Quality Level"
                      type="number"
                      min={1}
                      max={2}
                      value={this.state.VideoToEdit.quality_level}
                      onChange={(e) => this.handleEditStateChange('quality_level', Number(e.target.value))}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3">
                    <label>Video File</label>
                  </div>
                  <div className="col-md-9 radio-part">
                    <div className="currentFile">{this.state.VideoToEdit.video_file}</div>

                    <div className="radio-selection row">
                      <div className="col-md-4">
                        <input
                          type="radio"
                          id="linkTypeVideoEdit"
                          name="videoTypeEdit"
                          checked={!videoInputEditOn}
                          onChange={this.handleStateChange('videoInputEditOn', false)}
                        />
                        &nbsp;
                        <label htmlFor="linkTypeVideoEdit">Link</label>
                      </div>
                      <div className="col-md-4">
                        <input
                          type="radio"
                          id="uploadTypeVideoEdit"
                          name="videoTypeEdit"
                          checked={videoInputEditOn}
                          onChange={this.handleStateChange('videoInputEditOn', true)}
                        />
                        &nbsp;
                        <label htmlFor="uploadTypeVideoEdit">Upload</label>
                      </div>
                    </div>
                    {videoInputEditOn ? (
                      <form id="videoUploadEdit" method="post" encType="multipart/form-data">
                        <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                        <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                        <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                        <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                        <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                        <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                        <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                        <input id="uploadPieceVideo" type="file" name="file" onChange={this.onFileChange} />
                      </form>
                    ) : (
                      <input
                        name="video"
                        placeholder="Video File"
                        value={this.state.VideoToEdit.video_file}
                        onChange={(e) => this.handleEditStateChange('video_file', e.target.value)}
                        className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                        parentClass="form-group"
                      />
                    )}
                  </div>
                </div>
                <div className="editButtons">
                  <button
                    type="submit"
                    name="submit"
                    className="cus-btn-primary btn-sm submitButton"
                    style={{ margin: '10px 10px 10px 10px' }}
                    onClick={this.uploadVideo('editVideo')}
                  >
                    {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Save</span>}
                  </button>
                  <button
                    type="button"
                    name="button"
                    className="cus-btn-danger btn-sm submitButton"
                    style={{ margin: '10px 10px 10px 10px' }}
                    onClick={() => {
                      this.setState((prev) => ({
                        ...prev,
                        showFollowing: false,
                        VideoToEdit: null,
                      }));
                    }}
                  >
                    {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Cancel</span>}
                  </button>
                </div>
                <hr />
              </div>
            )}
          </div>
        );
      })
    );
  };

  render() {
    const { isCreating, pieceInstrumentInfo, accessMode } = this.props;
    const { awsKeys, disableUpload, videoInputOn } = this.state;
    return (
      <section className="well create-instrument">
        {this.getPieceVideo()}

        {accessMode == 'edit' &&
          this.props.pieceInstrumentInfo.piece &&
          ((this.props.pieceInstrumentInfo.piece.videos && this.props.pieceInstrumentInfo.piece.videos.length) < 2 ||
            !this.props.pieceInstrumentInfo.piece.videos) && (
            <React.Fragment>
              <div className="row">
                <div className="col-md-3">
                  <label>Quality</label>
                </div>
                <div className="col-md-9">
                  <Field
                    name="qualityLevel"
                    placeholder="Enter 1 or 2"
                    component={renderInput}
                    type="number"
                    min={1}
                    max={2}
                    value={this.state.quality_level}
                    onChange={this.onChangeSetToState('quality_level')}
                    className="form-control"
                    parentClass="form-group"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <label>Video File</label>
                </div>
                <div className="col-md-9 radio-part">
                  <div className="radio-selection row">
                    <div className="col-md-4">
                      <input
                        type="radio"
                        id="linkTypeVideo"
                        name="videoType"
                        checked={!videoInputOn}
                        onChange={this.handleStateChange('videoInputOn', false)}
                      />
                      &nbsp;
                      <label htmlFor="linkTypeVideo">Link</label>
                    </div>
                    <div className="col-md-4">
                      <input
                        type="radio"
                        id="uploadTypeVideo"
                        name="videoType"
                        checked={videoInputOn}
                        onChange={this.handleStateChange('videoInputOn', true)}
                      />
                      &nbsp;
                      <label htmlFor="uploadTypeVideo">Upload</label>
                    </div>
                  </div>
                  {videoInputOn ? (
                    <form id="videoUpload" method="post" encType="multipart/form-data">
                      <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                      <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                      <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                      <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                      <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                      <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                      <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                      <input id="uploadPieceVideo" type="file" name="file" onChange={this.onFileChange} />
                    </form>
                  ) : (
                    <input
                      name="video"
                      placeholder="Video File"
                      value={this.state.video_file}
                      onChange={this.onChangeSetToState('video_file')}
                      className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                      parentClass="form-group"
                    />
                  )}
                </div>
              </div>

              <button
                type="submit"
                name="submit"
                value="Upload to Amazon S3"
                className="cus-btn-primary btn-block btn-lg submitButton"
                disabled={isCreating || !(pieceInstrumentInfo.piece && this.state.quality_level)}
                onClick={this.uploadVideo('newVideo')}
              >
                {isCreating || this.state.isUploading ? (
                  <span className="spinner" />
                ) : (
                  <span>
                    {this.state.quality_level && this.state.quality_level == 2 ? 'Add Sheet Video' : 'Add Piece Video'}
                  </span>
                )}
              </button>
            </React.Fragment>
          )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createVideos' })(CreatePieceVideo);
