import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteFeature, getFeatures, editFeature, changeOrder } from '../../store/actions/feature/feature.action';
import PageLoader from '../../components/Common/PageLoader';
import FeaturesList from '../../components/Features/Features';

class featuresContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    this.props.getFeatures();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter((category) => category.link == 'features')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { deleteFeature, features, isFetching, getFeatures, editFeature, changeOrder } = this.props;
    const { accessDenied, mode } = this.state;
    if (isFetching) {
      return <PageLoader />;
    }

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const featureInfo = { deleteFeature, features };
    return (
      <section className="features-list">
        <FeaturesList
          mode={mode}
          featureInfo={featureInfo}
          editFeature={editFeature}
          changeOrder={changeOrder}
          getFeatures={getFeatures}
        />
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCreating: state.feature.isCreating,
    features: state.feature.list,
    isFetching: state.feature.isFetching,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  deleteFeature,
  getFeatures,
  editFeature,
  changeOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(featuresContainer);
