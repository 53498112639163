export const GET_PIECESINSTRUMENT_START = 'GET_PIECESINSTRUMENT_START';
export const GET_PIECESINSTRUMENT_SUCCESS = 'GET_PIECESINSTRUMENT_SUCCESS';
export const GET_PIECESINSTRUMENT_ERROR = 'GET_PIECESINSTRUMENT_ERROR';

export const CREATE_PIECEINSTRUMENT_START = 'CREATE_PIECEINSTRUMENT_START';
export const CREATE_PIECEINSTRUMENT_SUCCESS = 'CREATE_PIECEINSTRUMENT_SUCCESS';
export const CREATE_PIECEINSTRUMENT_ERROR = 'CREATE_PIECEINSTRUMENT_ERROR';

export const DELETE_PIECEINSTRUMENT_START = 'DELETE_PIECEINSTRUMENT_START';
export const DELETE_PIECEINSTRUMENT_SUCCESS = 'DELETE_PIECEINSTRUMENT_SUCCESS';
export const DELETE_PIECEINSTRUMENT_ERROR = 'DELETE_PIECEINSTRUMENT_ERROR';
