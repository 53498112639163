import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../../store/actions/auth/auth.action';
import Login from '../../components/Login/Login';
import '../../styles/login.css';
class LoginContainer extends Component {
  onSubmit = userCredential => {
    this.props.onLoginClick({ userCredential });
  };

  render() {
    return (
      <div className="d-flex fmc-center screen-height login-container">
        <Login onSubmit={this.onSubmit} isLoading={this.props.isLoading} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.auth.isLoading,
  };
};
const mapDispatchToProps = {
  onLoginClick: login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
