import React, { Component } from 'react';
import { connect } from 'react-redux';

import { deleteSubscription, getSubscriptions } from '../../store/actions/subscription/subscription.action';
import Subscriptions from '../../components/Subscription/Subscriptions';
import PageLoader from '../../components/Common/PageLoader';

class PiecesInstrumentContainer extends Component {
  state = {
    mode: '',
    accessDenied: false,
  };

  componentDidMount() {
    this.props.getSubscriptions();
    this.setAccess();
  }

  componentDidUpdate(props) {
    if (JSON.stringify(props.categories) != JSON.stringify(this.props.categories)) {
      this.setAccess();
    }
  }

  setAccess = () => {
    try {
      let { categories } = this.props;
      let { mode } = categories.filter(category => category.link == 'subscriptions')[0];
      this.setState({ ...this.state, mode, accessDenied: false });
    } catch (err) {
      this.setState({ ...this.state, accessDenied: true });
    }
  };

  render() {
    const { deleteSubscription, subscriptions, isFetching } = this.props;
    if (isFetching) {
      return <PageLoader />;
    }

    const { mode, accessDenied } = this.state;

    if (accessDenied) {
      return <div>This category is not accessible to the logged in user.</div>;
    }

    const subscriptionsInfo = { deleteSubscription, subscriptions };
    return (
      <section className="subscription-list">
        <Subscriptions mode={mode} subscriptionsInfo={subscriptionsInfo} />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    subscriptions: state.subscription.list,
    isFetching: state.subscription.isFetching,
    categories: state.auth.categories,
  };
};
const mapDispatchToProps = {
  deleteSubscription,
  getSubscriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PiecesInstrumentContainer);
