import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';

import { renderInput } from '../../utils/input.util';

class CreatePieceInstrument extends Component {
  state = {
    piece_id: '',
    instrument_id: '',
    audio_file: '',
    audio: '',
    quality_level: '',
    file: {},
    awsKeys: {},
    disableUpload: false,
    piece: null,
    instrument: null,
    audioUploaded: false,
    isUploading: false,
  };

  onChangeSetToState = stateKey => e => {
    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  onSubmit = () => {
    const { piece, audio_file, title, instrument } = this.state;
    const piece_id = piece[0] && piece[0].id;
    const instrument_id = instrument[0] && instrument[0].id;
    this.props.onCreate({ piece_id, instrument_id, audio_file, title });
  };
  onFileChange = async eve => {
    this.setState(prevState => ({
      ...prevState,
      audioUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ key: `audio/${fileName}` });
    this.setState(prevState => ({
      ...prevState,
      file,
      audio_file: `${awsKeys.action}${awsKeys.key}`,
      awsKeys,
      disableUpload: true,
    }));
  };

  uploadAudio = async eve => {
    eve.preventDefault();
    if (!this.state.awsKeys.action) {
      toast.error('please try again.');
    }
    var form = document.querySelector('form');
    var data = new FormData(form);
    try {
      this.setState(prevState => ({
        ...prevState,
        isUploading: true,
      }));
      await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
      toast.success('Audio uploaded successfully.');
      this.setState(prevState => ({
        ...prevState,
        audioUploaded: true,
        isUploading: false,
      }));
    } catch (error) {
      toast.error('Something went wrong while uploading video.');
    }
  };

  render() {
    const { isCreating, pieceInstrumentInfo } = this.props;
    const { awsKeys, disableUpload } = this.state;
    return (
      <section className="well create-piece">
        <div className="row">
          <div className="col-md-3">
            <label>Piece</label>
          </div>
          <div className="col-md-9">
            <Typeahead
              onChange={selected => {
                this.setState({
                  piece: selected,
                });
              }}
              labelKey="title"
              options={pieceInstrumentInfo.pieces}
              selected={this.state.piece}
            />
          </div>

          <div className="col-md-3">
            <label>Instrument</label>
          </div>
          <div className="col-md-9">
            <Typeahead
              onChange={selected => {
                this.setState({
                  instrument: selected,
                });
              }}
              labelKey="name"
              options={pieceInstrumentInfo.instruments}
              selected={this.state.instrument}
            />
          </div>

          <div className="col-md-3">
            <label>title</label>
          </div>
          <div className="col-md-9">
            <Field
              name="title"
              placeholder="Title"
              component={renderInput}
              type="text"
              value={this.state.title}
              onChange={this.onChangeSetToState('title')}
              className="form-control"
              parentClass="form-group"
            />
          </div>

          <div className="col-md-3">
            <label>Media</label>
          </div>
          <div className="col-md-9">
            <form method="post" encType="multipart/form-data" onSubmit={this.uploadAudio}>
              <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
              <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
              <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
              <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
              <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
              <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
              <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
              <input type="file" name="file" onChange={this.onFileChange} /> <br />
              <input
                type="submit"
                name="submit"
                value="Upload to Amazon S3"
                className="btn btn-default"
                disabled={!disableUpload}
              />
            </form>
          </div>
          <div className="create-piece__btn-container">
            <button
              className="btn btn-primary btn-block btn-lg"
              type="submit"
              onClick={this.onSubmit}
              disabled={
                isCreating ||
                !(this.state.piece && this.state.instrument && this.state.title && this.state.audioUploaded)
              }
            >
              {isCreating || this.state.isUploading ? <span className="spinner" /> : <span>Create</span>}
            </button>
          </div>
        </div>
      </section>
    );
  }
}

export default reduxForm({ form: 'createPiece' })(CreatePieceInstrument);
