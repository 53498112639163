import { toast } from 'react-toastify';
import history from '../../../inits/history';

import axiosService from '../../../inits/axios';
import {
  GET_COMPOSERS_START,
  GET_COMPOSERS_SUCCESS,
  GET_COMPOSERS_ERROR,
  CREATE_COMPOSER_START,
  CREATE_COMPOSER_SUCCESS,
  CREATE_COMPOSER_ERROR,
  DELETE_COMPOSER_START,
  DELETE_COMPOSER_SUCCESS,
  DELETE_COMPOSER_ERROR,
  EDIT_COMPOSER_START,
  EDIT_COMPOSER_SUCCESS,
  EDIT_COMPOSER_ERROR,
} from './composer.actiontype';

import { getError } from '../../../utils/common.util';

export const getComposers = () => async (dispatch) => {
  try {
    dispatch({ type: GET_COMPOSERS_START });
    const composers = await axiosService.get('/pieces/composers');
    dispatch({ type: GET_COMPOSERS_SUCCESS, payload: composers });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({ type: GET_COMPOSERS_ERROR });
  }
};

export const getComposerByID = (composerId) => async (dispatch) => {
  try {
    const composers = await axiosService.get(`/pieces/composers/${composerId}`);
    return Promise.resolve(composers);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const createComposer = (fields) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_COMPOSER_START });
    const composer = await axiosService.post('/pieces/composers', fields);
    toast.success('Successfully created.');
    dispatch({ type: CREATE_COMPOSER_SUCCESS, payload: composer });
    history.push('/composers');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({ type: CREATE_COMPOSER_ERROR });
  }
};

export const deleteComposer = (composer) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this composer ?')) return;
  try {
    dispatch({ type: DELETE_COMPOSER_START });
    const deletedComposer = await axiosService.delete(`/pieces/composers/${composer.id}`);
    toast.success('Successfully deleted.');
    dispatch({ type: DELETE_COMPOSER_SUCCESS, payload: deletedComposer });
  } catch (error) {
    if (error.code == 500) toast.error('Composer already attached to a piece. Deletion not allowed');
    dispatch({ type: DELETE_COMPOSER_ERROR });
  }
};

export const editComposer = (composer) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_COMPOSER_START });
    const newComposer = await axiosService.put(`/pieces/composers/${composer.id}`, composer);
    toast.success('Successfully saved.');
    dispatch({ type: EDIT_COMPOSER_SUCCESS, payload: newComposer });
    history.push('/composers');
  } catch (error) {
    toast.error(error.message);
    dispatch({ type: EDIT_COMPOSER_ERROR });
  }
};
