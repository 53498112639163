import {
  toast
} from 'react-toastify';
import history from '../../../inits/history';
import axios from 'axios';

import axiosService from '../../../inits/axios';
import {
  GET_ADMINS_START,
  GET_ADMINS_SUCCESS,
  GET_ADMINS_ERROR,
  GET_USERS_START,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR,
  SET_CURRENT_ADMIN,
  DESTROY_CURRENT_ADMIN,
  CREATE_ADMIN_START,
  CREATE_ADMIN_SUCCESS,
  CREATE_ADMIN_ERROR,
  EDIT_ADMIN_START,
  EDIT_ADMIN_SUCCESS,
  EDIT_ADMIN_ERROR,
  DELETE_ADMIN_START,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_ERROR,
  GET_CATEGORIES_ERROR,
  GET_CATEGORIES_START,
  GET_CATEGORIES_SUCCESS,
} from './admins.actiontype';

import {
  getLocalStorage
} from '../../../utils/web-storage';
import {
  getError
} from '../../../utils/common.util';
import _ from 'lodash';

const REACT_APP_PARTPLAY_SERVICE_URL = process.env.REACT_APP_PARTPLAY_SERVICE_URL;

export const getAdmins = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADMINS_START
    });

    const accessToken = getLocalStorage('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    // let admins = await axiosService.get('/accounts');

    let {
      data,
      headers
    } = await axios({
      url: '/accounts',
      baseURL: REACT_APP_PARTPLAY_SERVICE_URL,
      headers: config.headers,
    });

    headers = JSON.parse(headers['x-pagination']);

    data = (
      await axios({
        url: '/accounts',
        baseURL: REACT_APP_PARTPLAY_SERVICE_URL,
        headers: config.headers,
        params: {
          per_page: headers['total_entries_size'],
        },
      })
    ).data;

    data = data.filter(({
      role
    }) => role == 'subadmin');

    dispatch({
      type: GET_ADMINS_SUCCESS,
      payload: data
    });
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({
      type: GET_ADMINS_ERROR
    });
  }
};

export const createAdmin = (fields) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_ADMIN_START
    });
    const artist = await axiosService.post('/accounts', fields);
    toast.success('Successfully created.');
    dispatch({
      type: CREATE_ADMIN_SUCCESS,
      payload: artist
    });
    history.push('/admins');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
    dispatch({
      type: CREATE_ADMIN_ERROR
    });
  }
};

export const deleteAdmin = (admin) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this admin ?')) return;

  try {
    dispatch({
      type: DELETE_ADMIN_START
    });
    const deletedAdmins = await axiosService.delete(`/accounts/${admin.id}`);
    toast.success('Successfully deleted.');
    dispatch({
      type: DELETE_ADMIN_SUCCESS,
      payload: deletedAdmins
    });
  } catch (error) {
    if (error.code == 500) toast.error(error.message);
    dispatch({
      type: DELETE_ADMIN_ERROR
    });
  }
};

export const editAdmin = (admin) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_ADMIN_START
    });
    const newAdmin = await axiosService.put(`/accounts/${admin.id}`, admin);
    dispatch({
      type: SET_CURRENT_ADMIN,
      payload: newAdmin
    });
    toast.success('Successfully saved.');
    dispatch({
      type: EDIT_ADMIN_SUCCESS,
      payload: newAdmin
    });
    history.push('/admins');
  } catch (error) {
    try {
      toast.error(error.errors.password[0]);
    } catch (e) {}
    console.log(error);
    dispatch({
      type: EDIT_ADMIN_ERROR
    });
  }
};

export const getAdminByID = (adminId) => async (dispatch) => {
  try {
    const admin = await axiosService.get(`/accounts/${adminId}`);
    dispatch({
      type: SET_CURRENT_ADMIN,
      payload: admin
    });
    return Promise.resolve(admin);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.reject(error);
  }
};

export const getCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORIES_START
    });

    const accessToken = getLocalStorage('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let payload = await axiosService.get('/categorytypes', {}, config.headers);
    dispatch({
      type: GET_CATEGORIES_SUCCESS,
      payload
    });
    return Promise.resolve();
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({
      type: GET_CATEGORIES_ERROR
    });
    return Promise.reject();
  }
};

export const deleteCurrentAdmin = () => async (dispatch) => {
  dispatch({
    type: DESTROY_CURRENT_ADMIN,
    payload: null
  });
};

const markInintialSubscriptions = async () => {
  // free, thomastic, premium
  let subs_ids = ['c8f0dccd-06b9-4158-9e0d-ca4805f40182'];
  axiosService
    .get('/pieces/pieces?per_page=10000')
    .then(async (data) => {
      data = _.sortBy(data, (d) => d.title);

      let first = data;
      subs_ids = subs_ids;

      // first update
      await Promise.all(
        first.map(async (piece) => {
          // add required subscriptions
          await Promise.all(
            subs_ids.map(async (id) => {
              if (!_.find(piece.subscriptions, {
                  id
                })) {
                return await axiosService.post('/pieces/subscriptions', {
                  piece_id: piece.id,
                  subscription_id: id
                });
              }
              return Promise.resolve();
            })
          );

          // remove unecessary subscriptions
          let piece_subscriptions = await axiosService.get('/pieces/subscriptions?piece_id=', piece.id);
          return await Promise.all(
            piece_subscriptions.map(async ({
              id,
              subscription_id
            }) => {
              if (!subs_ids.includes(subscription_id)) {
                return await axiosService.delete('/pieces/subscriptions/' + id);
              }
              return Promise.resolve();
            })
          );
        })
      );

      return Promise.resolve();
    })

    .then(() => window.alert('Updated!'))
    .catch((err) => {
      console.log(err);
    });
};
