import { toast } from 'react-toastify';
import history from '../../../inits/history';
import axios from 'axios';
import axiosService from '../../../inits/axios';
import {
  GET_PIECES_START,
  GET_PIECES_SUCCESS,
  GET_PIECES_ERROR,
  CREATE_PIECE_START,
  CREATE_PIECE_SUCCESS,
  CREATE_PIECE_ERROR,
  DELETE_PIECE_START,
  DELETE_PIECE_SUCCESS,
  DELETE_PIECE_ERROR,
  EDIT_PIECE_START,
  EDIT_PIECE_SUCCESS,
  EDIT_PIECE_ERROR,
  GET_CURRENTPIECES,
  DESTROY_CURRENTPIECES,
  SET_HOME_PIECE_ID,
  SET_PIECES_CATEGORIES
} from './piece.actiontype';
import { getLocalStorage } from '../../../utils/web-storage';
import { getError } from '../../../utils/common.util';
import store from '../../index';
import logs from '../../../components/Common/HistoryLogs';
import _ from 'lodash';

const serverUrl = process.env.REACT_APP_PARTPLAY_SERVICE_URL;

export const getPieces = (page_no = 1) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PIECES_START,
    });
    const accessToken = getLocalStorage('accessToken');
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    let pieces = await axios.get(serverUrl + '/pieces/pieces', config);
    const headers = JSON.parse(pieces.headers['x-pagination']),
      { total_entries_size } = headers;
    pieces = await axiosService.get('/pieces/pieces', {
      per_page: total_entries_size,
    });

    // const piece_categories = _.uniq(_.map(pieces,({category})=>category));

    dispatch({
      type: GET_PIECES_SUCCESS,
      payload: pieces,
    });
    // dispatch({
    //   type:SET_PIECES_CATEGORIES,
    //   payload:piece_categories
    // })
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    dispatch({
      type: GET_PIECES_ERROR,
    });
  }
};

export const getPiecesAddOns = () => async (dispatch) => {
  let pieces = store.getState().piece.list;
  pieces = await Promise.all(
    pieces.map(async (piece) => {
      let interviews = [],
        closeups = [];
      try {
        interviews = await axiosService.get(`/pieces/interviews?piece_id=${piece.id}`);
      } catch (e) {}

      try {
        closeups = await axiosService.get(`/pieces/closeups?piece_id=${piece.id}`);
      } catch (e) {}

      return Promise.resolve({
        ...piece,
        interviews,
        closeups,
      });
    })
  );
  dispatch({
    type: GET_PIECES_SUCCESS,
    payload: pieces,
  });
  // get closeups and interviews
};

export const getPieceByID = (pieceId) => async (dispatch) => {
  try {
    const piece = await axiosService.get(`/pieces/pieces/${pieceId}`);
    dispatch({
      type: GET_CURRENTPIECES,
      payload: piece,
    });
    return Promise.resolve(piece);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.resolve({});
  }
};

export const getPieceInterviewByID = (pieceId) => async (dispatch) => {
  try {
    const piece = await axiosService.get(`/pieces/interviews?piece_id=${pieceId}`);
    return Promise.resolve(piece);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.resolve({});
    return Promise.reject(error);
  }
};

export const getPieceClosupByID = async (pieceId) => {
  try {
    const piece = await axiosService.get(`/pieces/closeups?piece_id=${pieceId}`);
    return Promise.resolve(piece);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.resolve({});
    return Promise.reject(error);
  }
};

export const getPieceSubscriptionByID = (pieceId) => async (dispatch) => {
  try {
    const piece = await axiosService.get(`/pieces/subscriptions?piece_id=${pieceId}`);
    return Promise.resolve(piece);
  } catch (error) {
    toast.error(error.message || 'something went wrong.');
    return Promise.resolve({});
  }
};

export const createPiece = (fields) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_PIECE_START,
    });

    let updates = [];
    for (let k in fields) {
      if (!k.match(/Name/)) {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    const piece = await axiosService.post('/pieces/pieces', fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Piece',
      Action: 'CREATE',
      Details: updates
        .map(({ field, update }) => `${field.toUpperCase()}\n\n${update}`)
        .join('\n\n==================\n\n'),
      Link: 'Available on Piece update',
    });

    toast.success('Successfully created.');
    await dispatch({
      type: CREATE_PIECE_SUCCESS,
      payload: piece,
    });
    
    dispatch(editPiece({...piece,pieceproductid_google:piece.id.split("-").join("_")},piece,()=>{
      window.location.pathname = '/piece/edit/' + piece.id;
    }));
  } catch (error) {
    console.log(error);
    try {
      const errorMessage = getError(error);
      toast.error(errorMessage);
    } catch (e) {}
    dispatch({
      type: CREATE_PIECE_ERROR,
    });
  }
};

export const deletePiece = (piece) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this piece ?')) return;
  try {
    dispatch({
      type: DELETE_PIECE_START,
    });
    const deletedPiece = await axiosService.delete(`/pieces/pieces/${piece.id}`);
    toast.success('Successfully deleted.');

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Piece',
      Action: 'DELETE',
      Details: `PIECE NAME------------\n\n${piece.title}`,
      Link: window.location.origin + '/piece/edit/' + piece.id,
    });

    await dispatch({
      type: DELETE_PIECE_SUCCESS,
      payload: deletedPiece,
    });
  } catch (error) {
    if (error.code == 500) toast.error('Piece deletion restricted.');
    dispatch({
      type: DELETE_PIECE_ERROR,
    });
  }
};

export const editPiece = (piece, origPiece,cb) => async (dispatch) => {
  let updates = [];
  for (let k in piece) {
    if (origPiece[k] && typeof piece[k] !== 'object' && piece[k] !== origPiece[k]) {
      updates.push({
        field: k.match(/_id/) ? k.split('_id')[0] : k,
        prev: k.match(/_id/) ? piece[k.split('_id')[0] + 'NamePrev'] : origPiece[k],
        update: k.match(/_id/) ? piece[k.split('_id')[0] + 'Name'] : piece[k],
      });
    }
  }

  try {
    dispatch({
      type: EDIT_PIECE_START,
    });
    const newPiece = await axiosService.put(`/pieces/pieces/${piece.id}`, piece);
    dispatch({
      type: GET_CURRENTPIECES,
      payload: newPiece,
    });

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Piece',
      Action: 'UPDATE',
      Details: updates
        .map(
          ({ field, prev, update }) =>
            `${field.toUpperCase()}\n\n------Previous------ \n${prev}\n------Changed------ \n${update}`
        )
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    dispatch({
      type: EDIT_PIECE_SUCCESS,
      payload: newPiece,
    });

    toast.success('Successfully saved.');
    dispatch(getPieceByID(piece.id));

    if(cb) cb();
  } catch (error) {
    console.log(error);
    toast.error(error.message);
    dispatch({
      type: EDIT_PIECE_ERROR,
    });
  }
};

export const deleteCurrentPiece = () => async (dispatch) => {
  dispatch({
    type: DESTROY_CURRENTPIECES,
    payload: null,
  });
};

// interview
export const createInterview = (fields) => async (dispatch) => {
  try {
    let updates = [];
    for (let k in fields) {
      if (!k.match(/Name/) && k != 'piece_id') {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    await axiosService.post('/pieces/interviews', fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Interviews',
      Action: 'CREATE',
      Details: updates
        .map(({ field, update }) => `${field.toUpperCase()}\n\n${update}`)
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully created.');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
  }
};

export const editPieceInterview = (fields, orig) => async (dispatch) => {
  try {
    let updates = [];

    for (let k in fields) {
      if (orig[k] && typeof fields[k] !== 'object' && fields[k] !== orig[k]) {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          prev: k.match(/_id/) ? fields[k.split('_id')[0] + 'NamePrev'] : orig[k],
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    await axiosService.put('/pieces/interviews/' + fields.id, fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Instruments',
      Action: 'UPDATE',
      Details: updates
        .map(
          ({ field, prev, update }) =>
            `${field.toUpperCase()}\n\n------Previous------ \n${prev}\n------Changed------ \n${update}`
        )
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully updated.');
  } catch (error) {
    const errorMessage = getError(error);
    toast.error(errorMessage);
  }
};

export const editPieceCloseups = (fields, orig) => async (dispatch) => {
  try {
    let updates = [];

    for (let k in fields) {
      if (orig[k] && typeof fields[k] !== 'object' && fields[k] !== orig[k]) {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          prev: k.match(/_id/) ? fields[k.split('_id')[0] + 'NamePrev'] : orig[k],
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    await axiosService.put('/pieces/closeups/' + fields.id, fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Interviews',
      Action: 'UPDATE',
      Details: updates
        .map(
          ({ field, prev, update }) =>
            `${field.toUpperCase()}\n\n------Previous------ \n${prev}\n------Changed------ \n${update}`
        )
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully updated.');
  } catch (error) {
    console.log(error);
    const errorMessage = getError(error);
    toast.error(errorMessage);
  }
};

export const deleteInterview = (fields) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this piece interview ?')) return;
  try {
    await axiosService.delete(`/pieces/interviews/${fields.id}`);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Instruments',
      Action: 'DELETE',
      Details: `INSTRUMENT TITLE------------\n\n${fields.title}`,
      Link: window.location.href,
    });

    toast.success('Successfully deleted.');
  } catch (error) {
    if (error.code == 500) toast.error('Piece interview deletion restricted');
  }
};

// closeups
export const createCloseups = (fields) => async (dispatch) => {
  try {
    let updates = [];

    for (let k in fields) {
      if (fields[k] && !k.match(/Name/) && k != 'piece_id') {
        updates.push({
          field: k.match(/_id/) ? k.split('_id')[0] : k,
          update: k.match(/_id/) ? fields[k.split('_id')[0] + 'Name'] : fields[k],
        });
      }
    }

    await axiosService.post('/pieces/closeups', fields);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Closeups',
      Action: 'CREATE',
      Details: updates
        .map(({ field, update }) => `${field.toUpperCase()}\n\n${update}`)
        .join('\n\n==================\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully created.');
  } catch (error) {
    console.log(error);
    const errorMessage = getError(error);
    toast.error(errorMessage);
  }
};

export const deleteCloseups = (fields) => async (dispatch) => {
  if (!window.confirm('Are you sure you want to delete this piece closeup ?')) return;

  try {
    await axiosService.delete(`/pieces/closeups/${fields.id}`);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Instruments',
      Action: 'DELETE',
      Details: `VIDEO FILE------------\n\n${fields.video}`,
      Link: window.location.href,
    });

    toast.success('Successfully deleted.');
  } catch (error) {
    if (error.code == 500) toast.error('Piece closeup deletion restricted');
  }
};

//subscriptions

export const pieceSubscription = (fields, fieldsRemove, pieceId, orig) => async (dispatch) => {
  try {
    for (var i = 0; i < fields.length; i++) {
      if (!fields[i].subscription_id) {
        await axiosService.post('/pieces/subscriptions', {
          piece_id: pieceId,
          subscription_id: fields[i].id,
        });
      }
    }
    for (var j = 0; j < fieldsRemove.length; j++) {
      axiosService.delete('/pieces/subscriptions/' + fieldsRemove[j][0].id);
    }

    let updated = fields.map(({ sname }) => sname),
      previous = orig.map(({ sname }) => sname);

    await logs.insert({
      ...logs.pieces,
      'Sub Category': 'Subscriptions',
      Action: 'UPDATE',
      Details: [previous, updated]
        .map((data, i) => `------${!i ? 'Previous' : 'Changed'}------\n` + data.map((name) => name).join('\n'))
        .join('\n\n'),
      Link: window.location.href,
    });

    toast.success('Successfully saved.');
    return Promise.resolve();
  } catch (error) {
    toast.error(error.message);
    return Promise.reject();
  }
};

export const addPieceSubscription = (fields, orig) => async (dispatch) => {
  const previous = orig.map(({ name }) => name),
    updated = [...orig, fields].map(({ name }) => name);

  axiosService
    .post('/pieces/subscriptions', fields)
    .then(async () => {
      await logs.insert({
        ...logs.pieces,
        'Sub Category': 'Subscriptions',
        Action: 'UPDATE',
        Details: [previous, updated]
          .map((data, i) => `------${!i ? 'Previous' : 'Changed'}------\n` + data.map((name) => name).join('\n'))
          .join('\n\n'),
        Link: window.location.href,
      });

      toast.success('Subscription added to piece');
    })
    .catch((err) => {
      console.log(err);
      toast.error('Server failure');
    });
};

export const deletePieceSubscription = (fields, orig) => async (dispatch) => {
  const previous = orig.map(({ name }) => name),
    updated = orig.filter((f) => f.id != fields.subscription_id).map(({ name }) => name);

  axiosService
    .delete('/pieces/subscriptions/' + fields.id)
    .then(async () => {
      await logs.insert({
        ...logs.pieces,
        'Sub Category': 'Subscriptions',
        Action: 'UPDATE',
        Details: [previous, updated]
          .map((data, i) => `------${!i ? 'Previous' : 'Changed'}------\n` + data.map((name) => name).join('\n'))
          .join('\n\n'),
        Link: window.location.href,
      });

      toast.success('Subscription removed from piece');
    })
    .catch((err) => {
      console.log(err);
      toast.error('Server failure');
    });
};

export const getHomePieceId = () => (dispatch) => {
  return axios
    .get(serverUrl + '/links')
    .then(({ data }) => {
      dispatch({
        type: SET_HOME_PIECE_ID,
        payload: data.piece_id,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const setHomePieceId = (fields) => (dispatch) => {
  return axiosService.post('/links', fields).then((data) => {
    toast.success('Home piece updated');
    dispatch({
      type: SET_HOME_PIECE_ID,
      payload: data.piece_id,
    });
  });
};
