import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'font-awesome/css/font-awesome.css';

import './styles/theme.css';
import './styles/main.css';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import $ from 'jquery/dist/jquery.js';
import * as Sentry from '@sentry/browser';

window.jQuery = $;

Sentry.init({ dsn: 'https://d53c6cdad774442690ac6a822fecb233@o402604.ingest.sentry.io/5264059' });

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
