import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import RichTextEditor from 'react-rte';
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import { required } from '../../utils/validation.util';
import { renderInput, renderBSelect } from '../../utils/input.util';


class CreatePiece extends Component {
  state = {
    instrumentsField: [{}],
    instruments: [],
    videosField: [{}],
    videos: [],
    preview_image: '',
    img: '',
    file: {},
    complete_score:"",
    awsKeys: {},
    awsKeysPreview: {},
    preview_image_upload: '',
    disableUpload: false,
    imgUploaded: false,
    isUploading: false,
    lengthSec: 0,
    lengthMin: 0,
    description: RichTextEditor.createEmptyValue(),
    preview: '',
    previewInputOn: false,
    awsKeysCompleteScore:{},
    completeScoreInputOn:false,
    category:"",
    pieceproductid_google:""
  };

  componentDidMount() {
    if (this.props.mode === 'EDIT') {
      const {
        composer,
        era,
        difficulty,
        title,
        id,
        description,
        videos,
        instruments,
        preview_image,
        length,
        artist,
        price,
        preview,
        category,
        complete_score,
        pieceproductid_google
      } = this.props.initialValues;
      const lengthMin = Number(Math.floor(length / 60));
      const lengthSec = Number(length % 60);
      const instrumentsField = [];
      const videosField = [];
      const modifiedInstruments = instruments.map((instruments) => {
        instrumentsField.push({});
        return [instruments];
      });
      const modifiedVideos = videos.map((video) => {
        videosField.push({});
        return [video];
      });
      this.setState(p=>({
        ...p,
        title,
        composer: [composer],
        era: [era],
        difficulty: [difficulty],
        id,
        description: RichTextEditor.createValueFromString(this.props.initialValues.description, 'html'),
        descriptionPost: this.props.initialValues.description,
        instrumentsField,
        videosField,
        instruments: modifiedInstruments,
        videos: modifiedVideos,
        preview_image,
        imgUploaded: !!preview_image,
        artist: [artist],
        price,
        lengthMin,
        lengthSec,
        preview,
        category,
        complete_score,
        pieceproductid_google
      }));
    }
  }

  onFileChange = (type) => async (eve) => {
    this.setState((prevState) => ({
      ...prevState,
      imgUploaded: false,
      disableUpload: false,
    }));
    const file = eve.target.files[0];
    const getAwsKey = this.props.getAwsKey;
    const fileName = file.name.replace(/ /g, '_');
    const awsKeys = await getAwsKey({ 
      key: `pieces/${(()=>{
        switch(type){
          case 'preview':
            return 'Preview/images'
          case 'complete_score':
            return 'Complete_Score/media';
          default:
            return 'images'
        }
      })()}/${fileName}`
    });

    let updates = { awsKeys, preview_image: `${awsKeys.action}${awsKeys.key}` };
    if (type === 'preview') {
      updates.awsKeysPreview = JSON.parse(JSON.stringify(awsKeys));
      updates.preview = JSON.parse(JSON.stringify(updates.preview_image));
      delete updates['awsKeys'];
      delete updates['preview_image'];
    }else if(type === "complete_score"){
      updates.awsKeysCompleteScore = JSON.parse(JSON.stringify(awsKeys));
      updates.complete_score = JSON.parse(JSON.stringify(updates.preview_image));
      delete updates['awsKeys'];
      delete updates['preview_image'];
    }

    this.setState((prevState) => ({
      ...prevState,
      file,
      ...updates,
      disableUpload: true,
    }));
  };

  uploadImg = async (eve) => {
    eve.preventDefault();

    if (Object.keys(this.state.awsKeys).length) {
      var form = document.getElementById('uploadImg');
      var data = new FormData(form);
    }

    if (Object.keys(this.state.awsKeysPreview).length) {
      var form2 = document.getElementById('uploadImgPrev');
      var data2 = new FormData(form2);
    }

    if (Object.keys(this.state.awsKeysCompleteScore).length) {
      var form3 = document.getElementById('uploadMediaCompleteScore');
      var data3 = new FormData(form3);
    }

    try {
      this.setState((prevState) => ({
        ...prevState,
        isUploading: true,
      }));

      if (Object.keys(this.state.awsKeys).length) {
        await fetch(this.state.awsKeys.action, { method: 'POST', body: data });
      }

      if (Object.keys(this.state.awsKeysPreview).length) {
        await fetch(this.state.awsKeysPreview.action, { method: 'POST', body: data2 });
      }

      if (Object.keys(this.state.awsKeysCompleteScore).length) {
        await fetch(this.state.awsKeysCompleteScore.action, { method: 'POST', body: data3 });
      }

      if (document.getElementById('uploadPieceImage').value.length !== 0) {
        toast.success('Piece Image uploaded successfully.');
      }

      this.setState((prevState) => ({
        ...prevState,
        imgUploaded: true,
      }));
      this.onSubmit();
    } catch (error) {
      console.log(error);
      try {
        if (document.getElementById('uploadPreviewImage').value.length !== 0) {
          toast.error('Something went wrong while uploading image.');
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  onChangeSetToState = (stateKey) => (e) => {
    let updates = {
      [stateKey]: e.currentTarget.value.trim(),
    };
    if (['video_file'].includes(stateKey)) {
      updates.disableUpload = true;
    }

    this.setState({ [stateKey]: e.currentTarget.value.trim() });
  };

  handleStateChange = (key, val) => (e) => {
    e.stopPropagation();
    let updates = { [key]: val };
    if (['previewInputOn'].includes(key)) {
      updates['awsKeysPreview'] = {};
    }

    this.setState((prev) => ({ ...prev, ...updates }));
  };

  onChange = (description) => {
    this.setState({ description, descriptionPost: description.toString('html') });
  };

  filterObjectName = (collection, id, field) => {
    return collection.filter((data) => data.id == id && data)[0][field];
  };

  onSubmit = async () => {
    const {
      title,
      composer,
      era,
      difficulty,
      id,
      descriptionPost,
      preview_image,
      lengthSec,
      lengthMin,
      artist,
      price,
      preview,
      complete_score,
      category,
      pieceproductid_google
    } = this.state;
    const composer_id = composer && composer[0].id;
    const era_id = era && era[0].id;
    const difficulty_id = difficulty && difficulty[0].id;
    const artist_id = artist && artist[0].id;
    const length = Number(lengthSec) + Number(lengthMin * 60);
    if (this.props.mode === 'EDIT' && descriptionPost.length) {
      const artistName = this.filterObjectName(this.props.piecesInfo.artists, artist_id, 'title'),
        artistNamePrev = this.filterObjectName(
          this.props.piecesInfo.artists,
          this.props.initialValues.artist_id,
          'title'
        ),
        composerName = this.filterObjectName(this.props.piecesInfo.composers, composer_id, 'name'),
        composerNamePrev = this.filterObjectName(
          this.props.piecesInfo.composers,
          this.props.initialValues.composer_id,
          'name'
        ),
        difficultyName = this.filterObjectName(this.props.piecesInfo.difficulties, difficulty_id, 'name'),
        difficultyNamePrev = this.filterObjectName(
          this.props.piecesInfo.difficulties,
          this.props.initialValues.difficulty_id,
          'name'
        ),
        eraName = this.filterObjectName(this.props.piecesInfo.eras, era_id, 'name'),
        eraNamePrev = this.filterObjectName(this.props.piecesInfo.eras, this.props.initialValues.era_id, 'name');

      await this.props.onEdit(
        {
          title,
          composer_id,
          era_id,
          difficulty_id,
          id,
          description: descriptionPost,
          preview_image,
          length: Number(length),
          artist_id,
          price,
          preview,
          artistName,
          artistNamePrev,
          composerName,
          composerNamePrev,
          difficultyName,
          difficultyNamePrev,
          eraName,
          eraNamePrev,
          category,
          complete_score,
          pieceproductid_google
        },
        this.props.initialValues
      );
    } else {
      const artistName = this.filterObjectName(this.props.piecesInfo.artists, artist_id, 'title'),
        composerName = this.filterObjectName(this.props.piecesInfo.composers, composer_id, 'name'),
        difficultyName = this.filterObjectName(this.props.piecesInfo.difficulties, difficulty_id, 'name'),
        eraName = this.filterObjectName(this.props.piecesInfo.eras, era_id, 'name');

      await this.props.onCreate({
        title,
        composer_id,
        era_id,
        difficulty_id,
        description: descriptionPost,
        preview_image,
        length: Number(length),
        artist_id,
        price,
        preview,
        artistName,
        composerName,
        difficultyName,
        eraName,
        complete_score,
        category,
        pieceproductid_google
      });
    }
    this.setState((prevState) => ({
      ...prevState,
      img: '',
      file: {},
      awsKeys: {},
      disableUpload: false,
      imgUploaded: false,
      isUploading: false,
    }));
  };

  validate = () => {
    return (
      this.state.title &&
      this.state.description &&
      Number(this.state.lengthMin) * 60 + Number(this.state.lengthSec) > 0 &&
      this.state.composer &&
      this.state.composer.length > 0 &&
      this.state.artist &&
      this.state.artist.length > 0 &&
      this.state.era &&
      this.state.era.length > 0 &&
      this.state.difficulty &&
      this.state.difficulty.length > 0 &&
      this.state.price >= 0 &&
      this.state.preview_image
    );
  };

  render() {
    const { mode, isCreating, piecesInfo, accessMode, pieces_categories } = this.props;
    const { awsKeys, disableUpload, isUploading, previewInputOn, awsKeysPreview, completeScoreInputOn,
      awsKeysCompleteScore } = this.state;

    return (
      <section className="well">
        <div className="row">
          <div className="col-md-3">
            <label>Name</label>
          </div>
          <div className="col-md-9">
            <Field
              name="title"
              placeholder="Title"
              component={renderInput}
              type="text"
              value={this.state.title}
              onChange={this.onChangeSetToState('title')}
              validate={[required]}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              parentClass="form-group"
            />
          </div>
        </div>
        {
          this.props.mode === 'EDIT' ? (
            <div className="row">
              <div className="col-md-3">
                <label>Product ID for Google</label>
              </div>
              <div className="col-md-9">
                <Field
                  name="pieceproductid_google"
                  placeholder="Piece product ID for Google"
                  component={renderInput}
                  disaabled={true}
                  type="text"
                  value={this.state.pieceproductid_google}
                  onChange={this.onChangeSetToState('pieceproductid_google')}
                  validate={[required]}
                  className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                  parentClass="form-group"
                />
              </div>
            </div>
          ):('')
        }
        <div className="row">
          <div className="col-md-3">
            <label>Description</label>
          </div>
          <div className="col-md-9">
            <RichTextEditor readOnly={accessMode == 'view'} value={this.state.description} onChange={this.onChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Video Length</label>
          </div>
          <div className="col-md-9">
            <div className="col-md-4 form-group" style={{ paddingLeft: '0px' }}>
              <input
                placeholder="Video Length In Minutes"
                type="number"
                min={0}
                value={this.state.lengthMin}
                onChange={this.onChangeSetToState('lengthMin')}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              />
            </div>
            <div className="col-md-2">Min</div>
            <div className="col-md-4 form-group" style={{ paddingLeft: '0px' }}>
              <input
                placeholder="Video Length in Sec"
                type="number"
                min={0}
                max={59}
                value={this.state.lengthSec}
                onChange={this.onChangeSetToState('lengthSec')}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              />
            </div>
            <div className="col-md-2">Sec</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Price</label>
          </div>
          <div className="col-md-9">
            <Field
              name="price"
              placeholder="Price"
              component={renderInput}
              type="number"
              value={this.state.price}
              onChange={this.onChangeSetToState('price')}
              validate={[required]}
              className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
              parentClass="form-group"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Complete Score</label>
          </div>
          <div className="col-md-9 radio-part">
            <div className="currentFile">{this.state.complete_score}</div>
            <div className="radio-selection row">
              <div className="col-md-4">
                <input
                  type="radio"
                  id="completeScoreInputType"
                  name="completeScoreType"
                  checked={!completeScoreInputOn}
                  onChange={this.handleStateChange('completeScoreInputOn', false)}
                />
                &nbsp;
                <label htmlFor="completeScoreInputType">Link</label>
              </div>
              <div className="col-md-4">
                <input
                  type="radio"
                  id="completeScoreUploadType"
                  name="completeScoreType"
                  checked={completeScoreInputOn}
                  onChange={this.handleStateChange('completeScoreInputOn', true)}
                />
                &nbsp;
                <label htmlFor="completeScoreUploadType">Upload</label>
              </div>
            </div>

            {completeScoreInputOn ? (
              <form id="uploadMediaCompleteScore" method="post" encType="multipart/form-data">
                <input type="hidden" name="key" value={awsKeysCompleteScore.key || 'NA'} />
                <input type="hidden" name="acl" value={awsKeysCompleteScore.acl || 'NA'} />
                <input type="hidden" name="X-Amz-Credential" value={awsKeysCompleteScore['x-amz-credential'] || 'NA'} />
                <input type="hidden" name="X-Amz-Algorithm" value={awsKeysCompleteScore['x-amz-algorithm'] || 'NA'} />
                <input type="hidden" name="X-Amz-Date" value={awsKeysCompleteScore['x-amz-date'] || 'NA'} />
                <input type="hidden" name="Policy" value={awsKeysCompleteScore.policy || 'NA'} />
                <input type="hidden" name="X-Amz-Signature" value={awsKeysCompleteScore['x-amz-signature'] || 'NA'} />
                <input id="uploadCompleteScoreImage" type="file" name="file" onChange={this.onFileChange('complete_score')} />
              </form>
            ) : (
              <Field
                name="complete_score"
                placeholder="Complete Score"
                component={renderInput}
                type="text"
                value={this.state.complete_score}
                onChange={this.onChangeSetToState('complete_score')}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
              />
            )}
          </div>
        </div>
        <div className="row category">
          <div className="col-md-3">
            <label>Category</label>
          </div>
          <div className="col-md-9">
            <select 
              onChange={e=>{
                const category = e.target.value;
                this.setState(p=>({
                  ...p,
                  category
                }))
              }} 
              value={this.state.category}
            >
              <option value="">---Select Category---</option>
            {
              pieces_categories.map((d,i)=>(
                <option key={i} value={d}>{d}</option>
              ))
            }
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Composer</label>
          </div>
          <div className="col-md-9">
            <Typeahead
              onChange={(selected) => {
                this.setState({
                  composer: selected,
                });
              }}
              id="cpreoicroe"
              labelKey="name"
              options={piecesInfo.composers}
              selected={this.state.composer}
              className={`form-group ${accessMode == 'view' ? 'disabled' : ''}`}
              placeholder="Composer"
            />
          </div>
          <div className="col-md-3">
            <label>Era</label>
          </div>
          <div className="col-md-9">
            <Typeahead          
              id="cprmereoicroe"
              onChange={(selected) => {
                this.setState({
                  era: selected,
                });
              }}
              labelKey="name"
              options={piecesInfo.eras}
              selected={this.state.era}
              className={`form-group ${accessMode == 'view' ? 'disabled' : ''}`}
              placeholder="Era"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Difficulty</label>
          </div>
          <div className="col-md-9">
            <Typeahead
              id="cpreoicroecrek"
              onChange={(selected) => {
                this.setState({
                  difficulty: selected,
                });
              }}
              labelKey="name"
              options={piecesInfo.difficulties}
              selected={this.state.difficulty}
              className={`form-group ${accessMode == 'view' ? 'disabled' : ''}`}
              placeholder="Difficulty"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Artist</label>
          </div>
          <div className="col-md-9">
            <Typeahead
              id="cpreoimncroe"
              onChange={(selected) => {
                this.setState({
                  artist: selected,
                });
              }}
              labelKey="title"
              options={piecesInfo.artists}
              selected={this.state.artist}
              className={`form-group ${accessMode == 'view' ? 'disabled' : ''}`}
              placeholder="Artist"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label>Preview</label>
          </div>
          <div className="col-md-9 radio-part">
            <div className="currentFile">{this.state.preview}</div>
            <div className="radio-selection row">
              <div className="col-md-4">
                <input
                  type="radio"
                  id="linkType"
                  name="previewType"
                  checked={!previewInputOn}
                  onChange={this.handleStateChange('previewInputOn', false)}
                />
                &nbsp;
                <label htmlFor="linkType">Link</label>
              </div>
              <div className="col-md-4">
                <input
                  type="radio"
                  id="uploadType"
                  name="previewType"
                  checked={previewInputOn}
                  onChange={this.handleStateChange('previewInputOn', true)}
                />
                &nbsp;
                <label htmlFor="uploadType">Upload</label>
              </div>
            </div>

            {previewInputOn ? (
              <form id="uploadImgPrev" method="post" encType="multipart/form-data">
                <input type="hidden" name="key" value={awsKeysPreview.key || 'NA'} />
                <input type="hidden" name="acl" value={awsKeysPreview.acl || 'NA'} />
                <input type="hidden" name="X-Amz-Credential" value={awsKeysPreview['x-amz-credential'] || 'NA'} />
                <input type="hidden" name="X-Amz-Algorithm" value={awsKeysPreview['x-amz-algorithm'] || 'NA'} />
                <input type="hidden" name="X-Amz-Date" value={awsKeysPreview['x-amz-date'] || 'NA'} />
                <input type="hidden" name="Policy" value={awsKeysPreview.policy || 'NA'} />
                <input type="hidden" name="X-Amz-Signature" value={awsKeysPreview['x-amz-signature'] || 'NA'} />
                <input id="uploadPreviewImage" type="file" name="file" onChange={this.onFileChange('preview')} />
              </form>
            ) : (
              <Field
                name="preview"
                placeholder="Preview"
                component={renderInput}
                type="text"
                value={this.state.preview}
                onChange={this.onChangeSetToState('preview')}
                className={`form-control ${accessMode == 'view' ? 'disabled' : ''}`}
                parentClass="form-group"
              />
            )}
          </div>
        </div>
        {mode === 'EDIT' && this.state.preview_image ? (
          <div className="row">
            <div className="col-md-3">
              <label>Current Image</label>
            </div>
            <div className="col-md-9">{this.state.preview_image}</div>
          </div>
        ) : null}

        {accessMode == 'edit' && (
          <div className="row">
            <div className="col-md-3">
              <label>Upload Image</label>
            </div>
            <div className="col-md-9">
              <form id="uploadImg" method="post" encType="multipart/form-data" onSubmit={this.uploadImg}>
                <input type="hidden" name="key" value={awsKeys.key || 'NA'} />
                <input type="hidden" name="acl" value={awsKeys.acl || 'NA'} />
                <input type="hidden" name="X-Amz-Credential" value={awsKeys['x-amz-credential'] || 'NA'} />
                <input type="hidden" name="X-Amz-Algorithm" value={awsKeys['x-amz-algorithm'] || 'NA'} />
                <input type="hidden" name="X-Amz-Date" value={awsKeys['x-amz-date'] || 'NA'} />
                <input type="hidden" name="Policy" value={awsKeys.policy || 'NA'} />
                <input type="hidden" name="X-Amz-Signature" value={awsKeys['x-amz-signature'] || 'NA'} />
                <input id="uploadPieceImage" type="file" name="file" onChange={this.onFileChange('image')} />
                <button
                  type="submit"
                  name="submit"
                  value="Upload to Amazon S3"
                  className="cus-btn-primary btn-block btn-lg submitButton"
                  disabled={!this.validate()}
                >
                  {/* {console.log(Number(this.state.lengthMin) * 60 + Number(this.state.lengthSec) > 0)} */}
                  {isCreating || isUploading ? (
                    <span className="spinner" />
                  ) : (
                    <span>{mode === 'EDIT' ? 'Update' : 'Create'}</span>
                  )}
                </button>
              </form>
            </div>
          </div>
        )}
      </section>
    );
  }
}

export default reduxForm({ form: 'createPiece' })(CreatePiece);
