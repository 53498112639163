import React, { Component } from 'react';
import { registerUser } from '../../store/actions/users/users.action';
import CreateUser from '../../components/Users/CreateUser';
import { connect } from 'react-redux';

class CreateUserContainer extends Component {
  render() {
    return (
      <div id="create-user">
        <CreateUser registerUser={this.props.registerUser} isCreating={this.props.isCreating} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isCreating: state.users.isCreating,
});

const mapDispatchToProps = {
  registerUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserContainer);
