import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Composer = (props) => {
  const { deletePiece, piece } = props.pieceInfo;

  return (
    <tr>
      <td>{piece.title}</td>
      <td>{piece.description.toString().replace(/<[^>]*>/g, '')}</td>
      <td>{moment(piece.created_at).format('MMMM Do YYYY, h:mm:ss a')}</td>
      <td>
        <button className="cus-btn-danger mr-15" onClick={() => deletePiece(piece)}>
          Delete
        </button>
        <Link className="cus-btn-primary" to={`/piece/edit/${piece.id}`}>
          Edit
        </Link>
      </td>
    </tr>
  );
};

export default Composer;
