import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getAdmins,
  getAdminByID,
  createAdmin,
  editAdmin,
  deleteAdmin,
  deleteCurrentAdmin,
} from '../../store/actions/admins/admins.action';

import PageLoader from '../../components/Common/PageLoader';
import AdminsList from '../../components/Admins/AdminsList';

class AdminsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.getAdmins();
  }

  render() {
    const { isFetching, admins } = this.props;
    const { getAdminByID, createAdmin, editAdmin, deleteAdmin, deleteCurrentAdmin } = this.props;
    if (isFetching) {
      return <PageLoader />;
    }

    return (
      <section className="admins-list">
        <AdminsList
          admins={admins}
          adminsAction={{ getAdminByID, createAdmin, editAdmin, deleteAdmin, deleteCurrentAdmin }}
        />
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    admins: state.admin.admins,
    isFetching: state.admin.isFetching,
    isCreating: state.admin.isCreating,
    currentAdmin: state.admin.currentAdmin,
  };
};

const mapDispatchToProps = {
  getAdmins,
  getAdminByID,
  createAdmin,
  editAdmin,
  deleteAdmin,
  deleteCurrentAdmin,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminsContainer);
