import React, { useState } from 'react';
import { renderInput } from '../../utils/input.util';
import { required } from '../../utils/validation.util';
import { Field, reduxForm } from 'redux-form';

function CreateUser(props) {
  const [state, setState] = useState({
    name: '',
    email: '',
    mobile: '',
    password: '',
    retypePassword: '',
    match: {
      touched: false,
      matched: false,
    },
  });

  const onChangeSetToState = (key, value) => {
    let { match, password, retypePassword } = state;

    if (key == 'password') password = value;
    if (key == 'retypePassword') retypePassword = value;

    if (key == 'password' || key == 'retypePassword') {
      if (password.length && retypePassword.length && password === retypePassword) {
        match.matched = true;
      } else {
        match.matched = false;
      }
    }

    let updates = {
      match,
      [key]: value,
    };

    setState({
      ...state,
      ...updates,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let query = { ...state, password1: state.retypePassword };
    delete query.match;
    props.registerUser(query);
  };

  const { isCreating } = props;

  return (
    <div className="create-user">
      <section className="well create-piece">
        <form onSubmit={onSubmit}>
          <div className="col-md-3">
            <label>Name</label>
          </div>
          <div className="col-md-9">
            <Field
              name="name"
              placeholder="Name"
              component={renderInput}
              type="text"
              value={state.name}
              onChange={(e) => onChangeSetToState('name', e.target.value)}
              validate={[required]}
              className="form-control"
              parentClass="form-group"
            />
          </div>

          <div className="col-md-3">
            <label>Email</label>
          </div>
          <div className="col-md-9">
            <Field
              name="email"
              placeholder="Email"
              component={renderInput}
              type="text"
              value={state.email}
              onChange={(e) => onChangeSetToState('email', e.target.value)}
              validate={[required]}
              className="form-control"
              parentClass="form-group"
            />
          </div>

          <div className="col-md-3">
            <label>Mobile</label>
          </div>
          <div className="col-md-9">
            <Field
              name="mobile"
              placeholder="Mobile"
              component={renderInput}
              type="number"
              value={state.mobile}
              onChange={(e) => onChangeSetToState('mobile', e.target.value)}
              validate={[required]}
              className="form-control"
              parentClass="form-group"
            />
          </div>

          <div className="col-md-3">
            <label>Password</label>
          </div>
          <div className="col-md-9">
            <Field
              name="password"
              placeholder="Password"
              component={renderInput}
              type="password"
              value={state.password}
              onChange={(e) => onChangeSetToState('password', e.target.value)}
              validate={[required]}
              className="form-control"
              parentClass="form-group"
              onBlur={() => onChangeSetToState('match', { ...state.match, touched: true })}
            />
          </div>

          <div className="col-md-3">
            <label>Retype Password</label>
          </div>
          <div className="col-md-9">
            <Field
              name="retypePassword"
              placeholder="Retype Password"
              component={renderInput}
              type="password"
              value={state.retypePassword}
              onChange={(e) => onChangeSetToState('retypePassword', e.target.value)}
              validate={[required]}
              className="form-control"
              parentClass="form-group"
            />
            {state.match.touched &&
              (state.match.matched ? (
                <div className="match-valid">Entered passwords matched</div>
              ) : (
                <div className="match-invalid">Entered passwords do not match</div>
              ))}
          </div>

          <div className="create-piece__btn-container">
            <button className="cus-btn-primary btn-block btn-lg" type="submit" disabled={isCreating}>
              {isCreating ? <span className="spinner" /> : <span>Create</span>}
            </button>
          </div>
        </form>
      </section>
    </div>
  );
}

export default reduxForm({ form: 'createUser' })(CreateUser);
